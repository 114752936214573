<template>
  <div class="container">

    
    <br>


    <el-form ref="form" :model="myGameInfo" label-width="300px" style="margin-top: 20px ;width:500px">
        <el-form-item label="损益表 Profit & Loss" style="background-color:lightGray">
          <span></span>
        </el-form-item>
        <el-form-item class="competency_cell" label="营业收入 Revenues">
          <span>{{loadData['revenues']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="收入增长率 Revenue growth rate">
          <span>{{loadData['revenue_growth_rate']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="售货成本Cost of Good Sold (COGS)">
          <span>{{loadData['cogs']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="售货成本占比 COGS %">
          <span>{{loadData['cogs_percent']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="毛利 Gross Margin">
          <span>{{loadData['revenues'] - loadData['cogs']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="销售费用 Sales & Marketing (S&M) Cost">
          <span>{{loadData['s&m_cost']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="销售费用占比 S&M Cost %">
          <span>{{loadData['s&m_cost_percent']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="管理费用 Management Cost">
          <span>{{loadData['management_cost']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="管理费用占比 Management Cost %">
          <span>{{loadData['management_cost_percent']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="研发费用 Research & Development (R&D) Cost">
          <span>{{loadData['r&d_cost']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="研发费用占比 R&D Cost %">
          <span>{{loadData['r&d_cost_percent']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="扣除息税折旧摊销前利润 EBITDA">
         <!--  -->

          <span>{{loadData['revenues'] - loadData['cogs'] - loadData['s&m_cost'] - loadData['management_cost'] - loadData['r&d_cost']}}</span>
          <!-- <span>{{loadData['ebitda']}}</span> -->
        </el-form-item>
        <el-form-item class="competency_cell" label="折旧与摊销 Depreciation & Amortization">
          
          <span>{{loadData['d&a']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="息税前利润 EBIT">
        <span>{{loadData['revenues'] - loadData['cogs'] - loadData['s&m_cost'] - loadData['management_cost'] - loadData['r&d_cost'] - loadData['d&a']}}</span>
          <!-- <span>{{loadData['ebit']}}</span> -->
        </el-form-item>
        <el-form-item class="competency_cell" label="财务成本 Financial Cost">
          <span>{{loadData['financial_cost']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="税前利润 EBT">
        <span>{{loadData['revenues'] - loadData['cogs'] - loadData['s&m_cost'] - loadData['management_cost'] - loadData['r&d_cost'] - loadData['d&a'] - loadData['financial_cost']}}</span>
          <!-- <span>{{loadData['ebt']}}</span> -->
        </el-form-item>
        <el-form-item class="competency_cell" label="税 Tax">
        <span>{{(loadData['revenues'] - loadData['cogs'] - loadData['s&m_cost'] - loadData['management_cost'] - loadData['r&d_cost'] - loadData['d&a'] - loadData['financial_cost']) *loadData['tax_rate']/100 }}</span>
          <!-- <span>{{loadData['tax']}}</span> -->
        </el-form-item>
        <el-form-item class="competency_cell" label="税率 Tax rate %">
          <span>{{loadData['tax_rate']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="净利润 Net Income">
        <span>{{(loadData['revenues'] - loadData['cogs'] - loadData['s&m_cost'] - loadData['management_cost'] - loadData['r&d_cost'] - loadData['d&a'] - loadData['financial_cost']) *(100-loadData['tax_rate'])/100 }}</span>
          <!-- <span>{{loadData['net_income']}}</span> -->
        </el-form-item>
        <!-- Cash Flow -->
        <el-form-item label="现金流量表 Cash Flow" style="background-color:lightGray">
          <span></span>
        </el-form-item>
        <el-form-item class="competency_cell" label="经营现金流 Operating Cash Flow">
          <span>{{loadData['operating_cash_flow']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="投资现金流 Investing Cash Flow">
          <span>{{loadData['investing_cash_flow']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="融资现金流 Financing Cash Flow">
          <span>{{loadData['financing_cash_flow']}}</span>
        </el-form-item>

        <!-- Balance Sheet -->
        <el-form-item label="损益表 Balance Sheet" style="background-color:lightGray">
          <span></span>
        </el-form-item>

        <el-form-item class="competency_cell" label="流动资产 Current Assets">
          <span>{{loadData['liquid_assets']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="固定资产 Fixed Assets">
          <span>{{loadData['fixed_assets']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="流动负债 Current Liabilities">
          <span>{{loadData['short-term_debt']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="长期负债 Long-term Liabilities">
          <span>{{loadData['long-term_debt']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="股东权益 Equity">
          <span>{{loadData['liquid_assets'] + loadData['fixed_assets'] - loadData['short-term_debt'] - loadData['long-term_debt']}}</span>
          <!-- <span>{{loadData['equity']}}</span> -->
        </el-form-item>

      </el-form>
    
  </div>
</template>

<script>
import {getOtherTeamData} from '../api/VC_Arena'

export default {
    name: "FinancialInfo",
    data() {
      return {
        tableData: [{
            contentInfo: '333',
            name: '444',
          }, {
            contentInfo: '333',
            name: '333',
          }, {
            contentInfo: '333',
            name: '222',
          }, {
            contentInfo: '333',
            name: '111',
          }]
      };
    },
    props: {
      loadData:{},
      tableTitle:String,
      dataType:Number,
      gameId:String,
      teamId:String,
      currentRound:Number
    },
    mounted() {
      // const vm = this
    },
    components: {
    
    },
    methods:{
      doChoseCard(){
        this.$emit('callback', 'doChoseCard')
      },
      showOtherTeamsInfo(other_game_id){
        getOtherTeamData(other_game_id,this.currentRound).then((res)=>{

        })
      },
      getCardsInvestment(id){
          return this.myTeamCards['cards_investment'][id]
      },
    },
    watch: {
      myTeamCards (newValue, oldValue) {
          var cards = newValue
        if(null == cards){

        }
      },
      loadData (newValue, oldValue) {
          // console.log(1233123)
          // alert('111')
      }
    }
  };
</script>


<style lang="scss" scoped>
.competency_cell{

  /*background-color:lightGray;*/
  margin:10px;
  margin-left:20px;
}
</style>
