import Vue from 'vue'
import ElementUI from 'element-ui'
import VueTagsInput from '@johmun/vue-tags-input'
import echarts from 'echarts'


import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import App from './App.vue'
import router from './router'
import store from './utils/store'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.filter('timeFormat', function(timestamp) {    // 将时间戳转换为时间
  let time = new Date(timestamp);
  let y = time.getFullYear();
  let m = time.getMonth()+1;
  let d = time.getDate();
  let h = time.getHours();
  let mm = time.getMinutes();
  let s = time.getSeconds();
return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
})
function add0(m){return m<10?'0'+m:m }
Vue.use(ElementUI)
Vue.use(VueTagsInput)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
