<template>
    <div style="width:100%">
        <div style="padding:30px">
            <p style="color:#606266">TA的信息列表 His/Her Posts</p>
            <el-table :data="postList">
                <el-table-column prop="title" label="信息提示 Information Hint"></el-table-column>
                <el-table-column label="操作 Actions">
                    <template slot-scope="scope">
                        <el-button @click="sendUserPostRequest(scope.row._id.$id)" size="small" :type="scope.row.request_status=='pending'?'warning':'primary'" :disabled="scope.row.request_status=='pending'">{{scope.row.request_status=='pending'?'待交换 Pending':'请求 Request'}}</el-button>
                    </template>                
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getFriendPosts,sendUserPostRequest } from '../../api/yousha'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            friend_id:'',
            postList:[]
        }
    },
    mounted(){
        this.friend_id = this.$route.query.friend_id
        this.fetchData()
    },
    methods:{
        fetchData(){
            let user_id = getUserId()
            getFriendPosts(user_id,this.friend_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.postList = res.data.data
                }
            })
        },
        sendUserPostRequest(id){
            let user_id = getUserId()
            sendUserPostRequest(id,user_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.$message({
                        message:'申请成功!',
                        type:'success'
                    })
                    this.fetchData()
                }
            })
        }
    }
}
</script>

<style>

</style>
