<template>
  <article class="squareArticle">
    
    <h4 style="float:left">社交提示 Social Tips</h4>
    <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
    <br>
    <br>
    <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
      <h4 style="color:red">社区公告：</h4>

      <p style="font-size:12px">组委已关注到社区内关于“客观考试作答是否需要填写SAVE”的讨论帖和相关情况反映，现统一解释如下：试卷成功提交以点击SUBMIT按钮为准，与是否点击SAVE按钮无关。“SAVE”按钮为第三方平台为“断点续传功能“开发的按钮，防止考试中若意外关闭窗口导致答题数据丢失的情况。如果你中途点击SAVE，窗口即使关闭再次打开浏览器还可以找回已答数据。但SAVE与SUBMIT无关，没有点过SAVE，只要最后点击SUBMIT成功提交即上传成功。请勿不必要的担忧，也请勿信谣传谣。</p>


      <h4 style="color:red">Community Service：</h4>

      <p style="font-size:12px">The Organizing Committee has noticed the discussions and feedbacks in the community regarding "whether you have to click SAVE button in objective test" and hereby explaining as follows: the success of submission is dependent on your click on SUBMIT button, not on your click on SAVE button. The SAVE button is provided by the platform for its "Continue to Answer" function, incase that you accidently closed the browser window. If you clicked the SAVE button during your test, then you can find your answers back even you closed your browser window. In all, the SAVE button is not relevant to the SUBMIT button. Your submission is successfuly even if you did not have clicked the SAVE button. Please do not have unnecessary worries or spread disfactul rumors.</p>
    </div>
    <br>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="最新 Latest" name="first">
                <el-row>
        <el-col v-for = "data in postListNew" :key="data" >
          <div class="grid-content">
            <div style="padding: 30px">
              <span style="color:#1C6AC5;">{{data.title}}</span>

              <div class="bottom clearfix">
                <div  style="font-size:10px">
                  {{data.student_name?(data.student_name +', ' + data.account_name) :"" }} 
                  <span style="color:gray;"><i> updated on {{ (parseInt(data.create_time) * 1000) | timeFormat }}</i></span>
                </div>
              </div>
              <div class="tworightM">
                <img :src="require('../../../public/change.png')" style="width:20px ;height:20px;margin-top: 8px
  ;margin-left: 10px">
                <div style="color:#1C6AC5;margin-top: -22px;margin-left: 40px">{{data.request_count}}</div>
              </div>
              <div class="rightM">
                <el-button
                @click="sendUserPostRequest(data._id.$id)"
                size="small"
                type="primary"
                v-if="data.request_status == 'null' && user_type == 'student'"
                style="width: 120px"
                >请求 Request</el-button
              >
              <el-button
                size="small"
                v-if="data.request_status == 'pending'"
                type="primary"
                disabled
                style="width: 120px"
                >待交换 Pending</el-button
              >
              <el-button
                @click="view(data._id.$id)"
                size="small"
                type="primary"
                v-if="data.request_status == 'OK'"
                style="width: 120px"
                >查看 View</el-button
              >
              </div>
              


            </div>
          </div>
        </el-col>
        </el-row>
        <!-- <el-table :data="postListNew">
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="create_time" label="发布时间">
            <template slot-scope="scope">
              <div>
                {{ (parseInt(scope.row.create_time) * 1000) | timeFormat }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="student_name" label="发布者">
            <template slot-scope="scope">
              <div>
                {{scope.row.student_name?(scope.row.student_name + scope.row.account_name) :"" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="request_count" label="Exchanges">
          </el-table-column>
          <el-table-column prop="request_count" label="request OK">
               <template slot-scope="scope">
              <div>
                {{scope.row.request_OK?"是" :"否" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="sendUserPostRequest(scope.row._id.$id)"
                size="small"
                type="primary"
                v-if="scope.row.request_status == 'null'"
                >请求 Request</el-button
              >
              <el-button
                size="small"
                v-if="scope.row.request_status == 'pending'"
                type="primary"
                disabled
                >待通过 Pending</el-button
              >
              <el-button
                @click="view(scope.row._id.$id)"
                size="small"
                type="primary"
                v-if="scope.row.request_status == 'OK'"
                >查看 View</el-button
              >
            </template>
          </el-table-column>
        </el-table> -->

        <div
          @click="showMore"
          style="
            font-size: 14px;
            color: #606266;
            text-align: center;
            cursor: pointer;
            margin-top: 40px;
          "
        >
          查看更多 Load More
        </div>
      </el-tab-pane>
      <el-tab-pane label="最火 Hottest" name="second">
                <el-row>
        <el-col v-for = "data in postListHot" :key="data" >
          <div class="grid-content">
            <div style="padding: 30px">
              <span style="color:#1C6AC5;margin-bottom:50px">{{data.title}}</span>

              <div class="bottom clearfix">
                <div style="font-size:10px">
                  {{data.student_name?(data.student_name +', '+ data.account_name) :"" }} 
                  <span style="color:gray"><i> updated on {{ (parseInt(data.create_time) * 1000) | timeFormat }}</i></span>
                </div>
              </div>
              <div class="tworightM">
                <img :src="require('../../../public/change.png')" style="width:20px ;height:20px;margin-top: 8px
  ;margin-left: 10px">
                <div style="color:#1C6AC5;margin-top: -22px;margin-left: 40px">{{data.request_count}}</div>
              </div>
              <div class="rightM">
                <el-button
                @click="sendUserPostRequest(data._id.$id)"
                size="small"
                type="primary"
                v-if="data.request_status == 'null' && user_id != data.user_id  && user_type == 'student'"
                style="width: 120px"
                >请求 Request</el-button
              >
              <el-button
                size="small"
                v-if="data.request_status == 'pending' && user_id != data.user_id"
                type="primary"
                disabled
                style="width: 120px"
                >待交换 Pending</el-button
              >
              <el-button
                @click="view(data._id.$id)"
                size="small"
                type="primary"
                v-if="data.request_status == 'OK' && user_id != data.user_id"
                style="width: 120px"
                >查看 </el-button
              >
              </div>
              


            </div>
          </div>
        </el-col>
        </el-row>
          <!-- <el-table :data="postListHot">
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="create_time" label="发布时间">
            <template slot-scope="scope">
              <div>
                {{ (parseInt(scope.row.create_time) * 1000) | timeFormat }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="student_name" label="发布者">
            <template slot-scope="scope">
              <div>
                {{scope.row.student_name?(scope.row.student_name + scope.row.account_name) :"" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="request_count" label="Exchanges">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="sendUserPostRequest(scope.row._id.$id)"
                size="small"
                type="primary"
                v-if="scope.row.request_status == 'null'"
                >申请查看</el-button
              >
              <el-button
                size="small"
                v-if="scope.row.request_status == 'pending'"
                type="primary"
                disabled
                >申请中</el-button
              >
              <el-button
                @click="view(scope.row._id.$id)"
                size="small"
                type="primary"
                v-if="scope.row.request_status == 'OK'"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table> -->
        <!--<div
          @click="showMoreHot"
          style="
            font-size: 14px;
            color: #606266;
            text-align: center;
            cursor: pointer;
            margin-top: 40px;
          "
        >
          查看更多
        </div>-->
      </el-tab-pane>
    </el-tabs>

    <el-dialog :visible.sync="showAnswer" title="信息 Information">
      <div>
        {{ answer }}
      </div>
    </el-dialog>
  </article>
</template>

<script>
import {
  getPosts,
  getStudent,
  sendUserPostRequest,
  getHottestPosts,
  getPost,
} from "../../api/yousha";
import { getUserId,getUserType } from "../../utils/store";
export default {
  data() {
    return {
      user_id:'',
      user_type:'',
      postList: [],
      activeName: "first",
      showAnswer: false,
      squareForm: {
        squareType: "",
      },
      postListNew: [],
      postListHot: [],
      answer: "",
      time: 100000000000000,
      showSocialTip:true,
      showSocialTipTitle:'收起 Fold'

    };
  },
  mounted() {
    this.fetchData();
    this.getHottestPosts();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    fetchData() {
      let user_id = getUserId();
      this.user_type = getUserType();
      this.user_id = user_id;
      let that=this;
      getPosts(user_id, this.time).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.postListNew = res.data.data;
        }
      });
    },
    getHottestPosts() {
      let user_id = getUserId();
      let that=this;
      getHottestPosts({user_id:user_id}).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.postListHot = res.data;
        }
      });
    },
    
    sendUserPostRequest(id) {
      let user_id = getUserId();
      sendUserPostRequest(id, user_id).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.$message({
            message: "申请成功!",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    view(id) {
      this.showAnswer = true;
      getPost(id).then((res) => {
        if (res.data.code === 0) {
          this.answer = res.data.data.answer;
        }
      });
    },
    showMore() {
      let user_id = getUserId();
      let list = this.postListNew;
      getPosts(user_id, list[list.length - 1]['create_time']).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          let array = res.data.data;
          if (array.length === 0) {
            this.$message("没有更多了");
          } else {
            this.postListNew = [...list, ...array];
          }
        }
      });
    },
    showMoreHot() {
      let user_id = getUserId();
      let list = this.postList;
      getHottestPosts(user_id, list[list.length - 1]).then((res) => {
       
        if (res.code === 0) {
         let array = res.data;
          if (array.length === 0) {
            this.$message("没有更多了");
          } else {
            this.postListHot = [...this.postListHot, ...array];
          }
        }
      });
    },
    showSocialTipClick(){
      if(this.showSocialTip == true){
         this.showSocialTip = false;
         this.showSocialTipTitle = '展开 Unfold';
      }else{
         this.showSocialTip = true;
         this.showSocialTipTitle = '收起 Fold';
      }
    }
  },
};
</script>

<style scoped lang="scss">
.squareArticle {
  padding: 20px;
  width: 100%;
  position: relative;
}
/deep/ .el-form.el-form--inline {
  background-color: #f8f8f8;
  margin-bottom: 10px;
  padding: 8px;
  overflow: hidden;
}
/deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
  margin-right: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.searchHandle {
  margin-left: 30px;
}
.grid-content {
  height: 101px;
  background: #EFF3FB;
  opacity: 1;
  border-radius: 20px;
  margin-top:10px;
  width: 100%;
}
.title-content{
    margin-top:10px;
}
.bottom {
    margin-top: 13px;
    line-height: 12px;
    width: 60%;

}
.rightM{
  margin-right:25px;
  margin-top: -35px;
  margin-left: 90%;
  margin-bottom: 0px;
  height: 40px;
}

.tworightM{
  margin-right:25px;
  margin-top: -35px;
  margin-left: 80%;
  margin-bottom: 0px;
  height: 35px;
  width: 80px;
  background: #F7F7F7;
  border: 1px solid #EBEBEB;
  opacity: 1;
  border-radius: 4px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #4D4D4D;
}
.el-icon-arrow-down {
  font-size: 12px;
}

</style>
