<template>
    <div style="width:100%">
    
            <div style="padding:20px">

                <h4 style="float:left">社交提示 Social Tips</h4>
                <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
                <br>
                <br>
                <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
                <p>同样你会收到别人向你发来的交换请求。你发布的信息质量越高、数量越多，收到的请求也会越多</p>
                <p>每一个请求，你都可以看到对方的信息列表，来判断是否有你想要的信息，作为交换。你的同一条信息，可以和不同人的信息进行交换，没有数量限制</p>
                <p>如果你暂时在别人的信息列表里没有找到感兴趣的，可以暂且搁置稍后再来看看，因为别人也会更新他的信息列表</p>

                <p>Similarly you will receive exchange requests from others. The more and the better information you post, the more requests you will receive.</p>
                <p>For every request, you can view others' list of information and see if any information you want. You can exchange your same information with different others for different information, and with no number limits</p>
                <p>If you tentatively find few interesting information from others, you can leave the request in a pendng status and revisit it later as new information posts will be added to their list later.</p>
            </div>


            <p>请求我的 Requests to Me</p>
            <el-row>
            <el-col v-for = "data in request_list" :key="data" >
            <div class="grid-content">
                <div style="padding: 30px">
                <span style="color:#1C6AC5">{{data.post.title}}</span>

                <div class="bottom clearfix">
                    <div  style="font-size:10px">
                    <!-- {{data.student_name?(data.student_name + data.account_name) :"" }}  -->
                    {{data.user.student_lastName+data.user.student_givenName}}
                    <!-- {{ (parseInt(data.create_time) * 1000) | timeFormat }} -->
                    </div>
                </div>
                <div class="tworightM">
                    <el-button type="primary" @click="viewFriendList(data.user_id,data._id.$id)" v-if="data.status!='OK'" size="small" style="width:140px">处理 Process</el-button>
                    <el-button type="primary" @click="view(data.post_id)" v-if="data.status=='OK'" disabled size="small" style="width:140px">已交换 Exchanged</el-button>
                </div>
                <div class="rightM">
                    <el-button
                    @click="viewMessage(data._id.$id,data.user_id,data.post_id)"
                    size="small"
                    type="warning"
                    style="width: 120px"
                    >消息 Messages</el-button
                >
                </div>
                


                </div>
            </div>
            </el-col>
            </el-row>


            <!-- <el-table :data="request_list">
                <el-table-column prop="post.title" label="标题"></el-table-column>
                <el-table-column label="申请者">
                    <template slot-scope="scope">
                        {{scope.row.user.student_lastName+scope.row.user.student_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        {{scope.row.status=='pending'?'申请中':'已通过'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="viewFriendList(scope.row.user_id,scope.row._id.$id)" v-if="scope.row.status=='pending'" size="small">处理</el-button>
                        <el-button type="primary" disabled v-if="scope.row.status=='OK'" size="small">已通过</el-button>
                        <el-button type="warning" @click="viewMessage(scope.row._id.$id,scope.row.user_id,scope.row.post_id)" size="small">查看消息</el-button>
                    </template>
                </el-table-column>
            </el-table> -->
        </div>
        <el-dialog :visible.sync="showAnswer" title="信息 Information">
            <div>
                {{answer}}
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showFriendPost" title="查看TA的信息 See his/her Posts">
            <p style="color:#606266">注：只有U你确定交换TA的某条信息后，TA才可以查看你的这条信息 Attention: he/she can only view your post after you agree to exchange for his/her certain post</p>
            <el-table :data="postList">
                <el-table-column prop="title" label="提示 Hint"></el-table-column>
                <el-table-column label="操作 Actions">
                    <template slot-scope="scope">
                        <el-button @click="answerUserPostRequest(scope.row._id.$id)" size="small" type="primary">交换 Exchange</el-button>
                    </template>                
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :visible.sync="showMessage" title="消息列表 Message Llist">
            <el-button @click="initMessage" size="small" type="primary">发送消息 Send a message</el-button>
            <div style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:14px;" v-if="messageList.length==0">{{'暂无消息 No messages'}}</div>

            <el-row>
                <el-col v-for = "data in messageList" :key="data"  >
                    <div class="content">
                    <!-- <div style="padding: 15px"> -->
                      <div v-if="data.sender._id.$id != user_id" style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                      <div v-if="data.sender._id.$id == user_id" style="text-align:right;color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                      <div v-if="data.sender._id.$id != user_id"style="color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">{{data.sender.student_lastName+data.sender.student_givenName + ', ' + data.sender_account_name}} {{(parseInt(data.create_time) * 1000) | timeFormat}} </div>
                      <div v-if="data.sender._id.$id == user_id" style="text-align:right;color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">我 {{(parseInt(data.create_time) * 1000) | timeFormat}} </div>
                    </div>
                <!-- </div> -->
                </el-col>
            </el-row>

            

            <!-- <el-table :data="messageList">
                <el-table-column prop="content" label="内容"></el-table-column>
                <el-table-column label="发送人">
                    <template slot-scope="scope">
                        {{scope.row.sender.student_lastName+scope.row.sender.student_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="接收人">
                    <template slot-scope="scope">
                        {{scope.row.receiver.student_lastName+scope.row.receiver.student_givenName}}
                    </template>
                </el-table-column>
            </el-table> -->
        </el-dialog>
        <el-dialog :visible.sync="showinput" title="发送消息 Send a message">
            <div>
                <el-input v-model="message" style="width:80%"></el-input>
                <el-button @click="sendPostRequestMessage" type="primary" >发送 Send</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getFriendPosts,getMyReceiveRequests,getPost,answerUserPostRequest,sendPostRequestMessage,getPostMessages} from '../../api/yousha'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            user_id:'',
            request_list:[],
            showAnswer:false,
            answer:'',
            postList:[],
            showFriendPost:false,
            showMessage:false,
            request_id:'',
            post_id:'',
            user_id:'',
            messageList:[],
            showinput:false,
            message:'',
            showSocialTip:true,
            showSocialTipTitle:'收起 Fold'
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            let user_id = getUserId()
            this.user_id = user_id
            getMyReceiveRequests(user_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.request_list=res.data.data
                }
            })
        },
        view(id){
            this.showAnswer=true
             getPost(id).then(res=>{
                if(res.data.code===0){
                    this.answer = res.data.data.answer
                }
            })
        },
        viewFriendList(id,request_id){
            this.showFriendPost=true
            this.request_id = request_id
            let user_id = getUserId()
            getFriendPosts(user_id,id).then(res=>{
                if(res.data.code===0){
                    this.postList = res.data.data.filter(f=>f.request_status!='OK');
                }
            })
        },
        answerUserPostRequest(id){
            answerUserPostRequest(id,this.request_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    getPost(id).then(res=>{
                        if(res.data.code===0){
                            this.answer = res.data.data.answer
                            this.showAnswer=true
                        }
                    })

                }
            })
        },
        viewMessage(id,user_id,post_id){
            this.showMessage=true
            this.request_id = id
            this.post_id=post_id
            this.user_id = user_id
            getPostMessages({request_id:this.request_id}).then(res=>{
                if(res.data.code===0){
                    this.messageList = res.data.data
                }
            })
        },
        initMessage(){
            this.showinput=true
            this.message=''
        },
        sendPostRequestMessage(){
            let user_id=getUserId()
            sendPostRequestMessage(user_id,this.post_id,this.request_id,this.user_id,this.message).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'发送成功',
                        type:'success'
                    })
                    this.showinput=false
                    getPostMessages({request_id:this.request_id}).then(res=>{
                        if(res.data.code===0){
                            this.messageList = res.data.data
                        }
                    })
                }
            })
        },
        showSocialTipClick(){
            if(this.showSocialTip == true){
                this.showSocialTip = false;
                this.showSocialTipTitle = '展开 Unfold';
            }else{
                this.showSocialTip = true;
                this.showSocialTipTitle = '收起 Fold';
            }
        }
    }
}
</script>

<style>
.content {
  background: #EFF3FB;
  opacity: 1;
  border-radius: 20px;
  margin-top:10px;
  overflow: hidden;
  padding:15px;
}
</style>
