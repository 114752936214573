<template>
  <div class="CardManage">
    <div style="display: flex;flex-direction: row-reverse;margin-bottom: 10px">
      <div>
        <el-button type="primary" @click="createNewEffect">新建Effect</el-button>
      </div>
    </div>
    <span >卡片详情</span>
    <div style="display: flex;flex-direction: row;margin-top: 25px;align-items: center">
      <h4>行业名称：</h4>
    <el-input v-model="cardInfo.sector" placeholder="请输入行业名称" style="width: 300px"/>
    </div>
    <div>
    <h4>所属部门</h4>
      <el-select v-model="cardInfo.department"placeholder="请选择部门" >
        <el-option
          v-for="item in departments"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>卡片名称：</h4>
      <el-input v-model="cardInfo.name"  placeholder="请输入卡片名称"  style="width: 300px"/>
    </div>
    <div style="margin-top: 15px">
      <h4 >卡片简介：</h4>
      <el-input
        type="textarea"
        :rows="5"
        ref="input"
        placeholder="请输入卡片简介"
        :autofocus="false"
        v-model="cardInfo.intro">
      </el-input>
    </div>

    <div style="margin-top: 15px">
      <h4 >卡片图片：</h4>
      <el-upload
        class="avatar-uploader"
        :before-upload="beforeUpload"
        :show-file-list="false"
        action="#">
        <img v-if="cardInfo.card_cover_address" :src="'http://' + cardInfo.card_cover_address" class="avatar">
        <i v-else class="el-icon-plus car_default"></i>
      </el-upload>
    </div>

    <div class="flex-right" style="margin-top: 40px">
      <el-button type="primary" @click="updateCardInfo">更新卡片信息</el-button>
    </div>
    <div style="margin-top: 15px">
      <span >效果列表</span>
      <el-table :data="effect_array" border style="margin-top: 10px">
        <el-table-column prop="name" label="效果名称" align="center" width="200px"/>
        <el-table-column prop="" label="详情" align="center" width="200px">
          <template slot-scope="scope">
            <div>
              <el-button type="primary" @click="showEffect(scope.row._id.$id)">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import {updateCard, getCard,getCardsBySector,uploadCardPic} from '../../api/VC_Arena'
export default {
    data(){
      return {
        file: undefined,
       cardInfo:{},
       cardId:'',
       effect_array:[],
       departments:['HR','Finance','Marketing','RnD','Operation']
      }
    },
    mounted(){
      this.cardId = this.$route.params.id
      this.fetchData()
    },
    methods: {
      showEffect(id){
        Cookies.set("effect_id",id)
        Cookies.set("sector",this.cardInfo.sector)
        this.$router.push('/game/card/effect/'+this.cardId)
      },
      createNewEffect(){
        Cookies.set("sector",this.cardInfo.sector)
        Cookies.set("effect_id","")
        this.$router.push('/game/card/effect/'+this.cardId)
      },
      fetchData() {
        getCard(this.cardId).then((res) =>{
          this.cardInfo= res.data.data
          this.effect_array = this.cardInfo.effect_array
        })
      },
      updateCardInfo() {
        updateCard(this.cardId, this.cardInfo.sector, this.cardInfo.name, this.cardInfo.intro,this.cardInfo.department).then((res) => {
          this.$message('更新成功')
          this.$router.go(-1)
        })
      },
      beforeUpload(file) {
        this.file = file
        this.upload()
        return false
      },
      upload() {
        if (!this.file) {
          this.$message.warning('请先导入图片文件')
          return
        }
        uploadCardPic(this.cardId, this.file).then((res) => {
          console.log('上传结果：' + JSON.stringify(res.data))
          this.fetchData()
        })
      },
    }
  }
</script>

<style scoped>
  .CardManage {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
  .car_default {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .card_image {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
