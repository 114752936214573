<template>
  <div class="container">


    <br>


    <h4 style="font-size:24px">{{card_info.name}}</h4>

    <span>效果列表 Effects List</span>
    <ul>
    <li v-for="(effect) in card_info.effect_array" :key="effect" style="margin: 20px">
      <div><b>{{effect.name+':'}}</b>{{' improve ' + effect.parameter + ' based on ' + effect.benchmark_parameter + ' in the next ' + effect.effect_round + ' round; effective on ' + effect.investment_weight + '% of your investment; '}}{{effect.dependents.length >0? 'Impact can be amplified by ' :''}}<span v-if="effect.dependents.length > 0" v-for="(dependent,index) in effect.dependents">{{dependent + ', '}}</span>{{effect.dependents.length >0? ' etc.':''}}; Optimal investment is {{effect.benchmark_percent}}% of your current {{effect.benchmark_parameter}} </div>
    </li>
    </ul>
    <!-- <el-row style="width = 300px">
        <el-col :span="6" v-for="(item, index) in picImgs" :offset="index > 0 ? 0 : 0">
            <el-card :body-style="{ padding: '10px' }">
            <div style="width:100%;text-align:center"><img :src="'http://' + item.card_cover_address" style="width:250px;border-radius:20px"></div>
            <div style="text-align:center;margin:20px">投入 Investment<br>{{cards_investment[item._id.$id]}}</div>
            </el-card>
        </el-col>
    </el-row> -->
    
  </div>
</template>

<script>
import {getOtherTeamData} from '../api/VC_Arena'

export default {
    name: "StandardCard",
    data() {
      return {

      };
    },
    props: {
      card_info:{}
    },
    mounted() {
      // const vm = this
    },
    components: {
    
    },
    methods:{
      doChoseCard(){
        this.$emit('callback', 'doChoseCard')
      },
      showOtherTeamsInfo(other_game_id){
        getOtherTeamData(other_game_id,this.currentRound).then((res)=>{

        })
      },
      getCardsInvestment(id){
          return this.myTeamCards['cards_investment'][id]
      },
    },
    watch: {
      
    }
  };
</script>


<style lang="scss" scoped>
.image {
    width: 100%;
    display: block;
  }
</style>
