import request from '../utils/request'
export function getMyStats (user_id) {
  return request.post('./index.php', {
    function: 'getMyStats',
    user_id
  })
}
export function getMyPosts (user_id) {
  return request.post('./index.php', {
    function: 'getMyPosts',
    user_id
  })
}

export function updatePost(id,user_id,title,answer) {
  return request.post('./index.php', {
    function: 'updatePost',
    id,user_id,title,answer
  })
}
export function getStudent(paramInfo) {
  let param = Object.assign({},paramInfo,{function: 'getStudent'})
  return request.post('./index.php', param)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
  }
export function getPostMessages(paramInfo) {
  let param = Object.assign({},paramInfo,{function: 'getPostMessages'})
  return request.post('./index.php', param)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
  }
  export function getMessagesToMe(paramInfo) {
    let param = Object.assign({},paramInfo,{function: 'getMessagesToMe'})
    return request.post('./index.php', param)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.log(error);
      })
    }
export function getHottestPosts(paramInfo) {
  let param = Object.assign({},paramInfo,{function: 'getHottestPosts'})
  return request.post('./index.php', param)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
  }
export function getPost(id) {
  return request.post('./index.php', {
    function: 'getPost',
    id
  })
}

export function getFriendPosts(user_id,friend_id) {
  return request.post('./index.php', {
    function: 'getFriendPosts',
    user_id,
    friend_id
  })
}

export function sendUserPostRequest(post_id,user_id) {
  return request.post('./index.php', {
    function: 'sendUserPostRequest',
    post_id,
    user_id
  })
}

export function getPosts(user_id,time) {
  return request.post('./index.php', {
    function: 'getPosts',
    user_id,time
  })
}

export function getMyRequests(user_id) {
  return request.post('./index.php', {
    function: 'getMyRequests',
    user_id
  })
}

export function getMyReceiveRequests(user_id) {
  return request.post('./index.php', {
    function: 'getMyReceiveRequests',
    user_id
  })
}

export function answerUserPostRequest(new_post_id,old_request_id) {
  return request.post('./index.php', {
    function: 'answerUserPostRequest',
    new_post_id,old_request_id
  })
}



export function sendPostRequestMessage(user_id,post_id,request_id,receiver_id,content) {
  return request.post('./index.php', {
    function: 'sendPostRequestMessage',
    user_id,post_id,request_id,receiver_id,content
  })
}

