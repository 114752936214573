<template>
  <div class="CardManage">
    <div style="display: flex;flex-direction: row-reverse;margin-bottom: 10px">
      <div>
        <el-button type="primary" @click="createNewEcoCard">新建环境卡</el-button>
      </div>
    </div>
    <span >环境卡列表</span>
    <el-table :data="cards" border style="margin-top: 10px">
      <el-table-column prop="sector" label="行业名称" align="center" width="200px"/>
      <el-table-column prop="name" label="环境卡名称" align="center" width="200px"/>
      <el-table-column prop="term" label="期限" align="center" width="200px"/>
      <el-table-column prop="" label="详情" align="center" width="200px">
        <template slot-scope="scope">
          <div>
            <el-button type="primary" @click="showEcoCardDetail(scope.row._id.$id)">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getEcoCards} from '../../api/VC_Arena'
export default {
  data(){
    return {
       cards:[]
    }
  },
  mounted(){
    this.fetchData()
  },
  methods: {
     fetchData(){
       getEcoCards().then((res) =>{
           this.cards = res.data.data
       })
     },
    showEcoCardDetail(id){
      this.$router.push('/game/ecocard/detail/'+id)
    },
     createNewEcoCard() {
       this.$router.push('/game/ecocard/detail/'+"create")
     }
  }
}
</script>
<style scoped>
  .CardManage {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
