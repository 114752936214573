<template>
  <div class="CardManage">
    <span >卡片详情</span>
    <div style="display: flex;flex-direction: row;margin-top: 25px;align-items: center">
      <h4>行业名称：</h4>
      <el-select v-model="cardInfo.sector"placeholder="请选择行业名称" >
        <el-option
          v-for="item in sectores"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>环境卡名称：</h4>
      <el-input v-model="cardInfo.name"  placeholder="环境卡名称"  style="width: 300px"/>
    </div>
    <div style="margin-top: 15px">
      <h4 >环境卡介绍：</h4>
      <el-input
        type="textarea"
        :rows="5"
        ref="input"
        placeholder="请输入环境卡介绍"
        :autofocus="false"
        v-model="cardInfo.intro">
      </el-input>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>期限：</h4>
      <el-select v-model="cardInfo.term" placeholder="请选择">
        <el-option
          v-for="item in termes"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="margin-top: 15px">
      <h4>影响卡片：</h4>
      <el-button type="primary" @click="dialogTableVisible = true">请选择</el-button>

      <el-dialog title="影响卡片" :visible.sync="dialogTableVisible">
        <el-checkbox-group v-model="checkbookSleectArr">
          <el-checkbox v-for="item1 in CardsBySector" :label="item1._id.$id" :key="item1._id.$id" @change="(value)=> {checkboxchangeCell(value, item1._id.$id)}"  >{{item1.name}}
            <el-input v-model="cardInfo.card_impact_array[item1._id.$id]" placeholder="请输入内容" style="width: 130px;margin-top: 5px;height=30px" type="number"  @input="(value)=> {checkInputChanged(value, item1._id.$id)}"></el-input >
            <!-- v-if="!checkbookSleectArr.indexOf(item1._id.$id)" -->
          </el-checkbox>
          
        </el-checkbox-group>
      </el-dialog>


      <!-- <el-input placeholder="请输入内容" v-model="inputImpact"  type="number" style="width: 500px" class="input-with-select" @input="handleSectorInputChanged">
        <el-select v-model="selectedSector" slot="prepend" placeholder="请选择" style="width: 200px"  @change="handleSectorChanged">
          <el-option
            v-for="item in CardsBySector"
            :key="item.id"
            :label="item.label"
            :value="item.id"
           >
          </el-option>
        </el-select>
      </el-input> -->
    </div>

    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>宏观市场增长率：</h4>
      <el-input v-model="cardInfo.market_growth" placeholder="请输入" type="number" style="width: 300px"/>
    </div>
    <div class="flex-right" style="margin-top: 40px">
      <el-button type="primary" @click="updateCardInfo">更新卡片信息</el-button>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import {getEcoCard, updateEcoCard,getCardsBySector} from '../../api/VC_Arena'
export default {
    data(){
      return {
        sectores:["New-Style Tea"],
        selectedSector:'',
        termes:["Short","Long"],
        cardInfo:{"card_impact_array":{}},
        cardId:'',
        CardsBySector:[],
        dialogTableVisible: false,
        checkbookSleectArr:[],
        inputImpact:0
        
      }
    },
    mounted(){
      var id =  this.$route.params.id
      if(id ==="create"){
        this.cardId = ""
      }else{
        this.cardId = id
      }
      this.fetchData()
    },
    methods: {
      fetchData(){
        getCardsBySector("New-Style Tea").then((res) =>{
          this.CardsBySector= res.data.data
          this.CardsBySector = this.CardsBySector.map((item, id,label) => ({ ...item, id: item._id.$id,label:item.name }))
          var  length =this.CardsBySector.length

          for (var i =0 ;i< length ;i++){
            this.cardInfo.card_impact_array[this.CardsBySector[i].id] = 0
          }
          //设置默认值
          this.selectedSector = this.CardsBySector[0].id
          // console.log(this.CardsBySector,'999')
          //设置默认值
          this.selectedSector = this.CardsBySector[0]['id']
          
          this.inputImpact=0
          this.getEcoCardInfo()
        })
      },
      getEcoCardInfo(){
        if(this.cardId !=""){
          getEcoCard(this.cardId).then((res) => {
            this.cardInfo=res.data.data
            this.inputImpact=this.cardInfo.card_impact_array[this.selectedSector]
            console.log(this.cardInfo.card_impact_array,'888')

            for (var k in res.data.data.card_impact_array){
                var v =  res.data.data.card_impact_array[k]
                if(v != 0 ){
                  this.checkbookSleectArr.push(k)
                }
            }

          })
        }else {
          this.cardInfo.card_impact_array={}
          var  length =this.CardsBySector.length
          for (var i =0 ;i< length ;i++){
            this.cardInfo.card_impact_array[this.CardsBySector[i]['id']] = 0
          }
        }
      },
      updateCardInfo(){

        console.log(this.cardInfo,'333')

        updateEcoCard(this.cardId,this.cardInfo).then((res) =>{
          this.$message('更新成功')
          this.$router.go(-1)
        })
      },
      handleSectorInputChanged(value){
        this.cardInfo.card_impact_array[this.selectedSector] = value
      },
      handleSectorChanged(value){
        this.selectedSector = value
        this.inputImpact=this.cardInfo.card_impact_array[value]
        console.log(this.cardInfo.card_impact_array,'111')
        console.log(this.inputImpact,'222')

      },
      checkInputChanged(value, row) {
        console.log(value,'111')
        console.log(row,'222')
        // if(value >= 0){
          if(value == 0){
            var index = this.checkbookSleectArr.indexOf(row); 
            if (index > -1) { 
              this.checkbookSleectArr.splice(index, 1); 
            }
          }else{

            if(this.checkbookSleectArr.indexOf(row) >= 0){

            }else{
                this.checkbookSleectArr.push(row)
            }
            
          }
          console.log(this.checkbookSleectArr,'333')

          this.cardInfo.card_impact_array[row] = value
        // }
        
      },
      checkboxchangeCell (value, row) {
        console.log(value,'333')
        console.log(row,'444')
        if(value == true){
          this.checkbookSleectArr.push(row)
        }else{
          var index = this.checkbookSleectArr.indexOf(row); 
          if (index > -1) { 
            this.checkbookSleectArr.splice(index, 1); 
          }
        }
        console.log(this.checkbookSleectArr,'555')
      
        
      }
    }
  }
</script>

<style scoped>
  .CardManage {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
