import { render, staticRenderFns } from "./comming2.vue?vue&type=template&id=17c0fafb&scoped=true&"
import script from "./comming2.vue?vue&type=script&lang=js&"
export * from "./comming2.vue?vue&type=script&lang=js&"
import style0 from "./comming2.vue?vue&type=style&index=0&id=17c0fafb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c0fafb",
  null
  
)

export default component.exports