<template>
    <div style="width:100%;">
        <div style="padding:20px">
            <p>Default Event Rooms</p>
            <el-table :data="default_event_rooms">
                <el-table-column label="讲座名称" prop="name"></el-table-column>
                <el-table-column label="学科" prop="event_name"></el-table-column>
                <el-table-column label="开始时间">
                    <template slot-scope="scope">
                        {{formateDate(scope.row.event_start_time)}}
                    </template>
                </el-table-column>
                <el-table-column label="结束时间">
                    <template slot-scope="scope">
                        {{formateDate(scope.row.event_end_time)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="toLecture(scope.row)" type="primary" size="medium">进入讲座</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <p>Event Rooms</p>
            <el-table :data="event_rooms">
                <el-table-column label="讲座名称" prop="name"></el-table-column>
                <el-table-column label="学科" prop="event_name"></el-table-column>
                <el-table-column label="开始时间">
                    <template slot-scope="scope">
                        {{formateDate(scope.row.event_start_time)}}
                    </template>
                </el-table-column>
                <el-table-column label="结束时间">
                    <template slot-scope="scope">
                        {{formateDate(scope.row.event_end_time)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="toLecture(scope.row)" type="primary" size="medium">进入讲座</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <p>Extra Event Rooms</p>
            <el-table :data="extra_event_rooms">
                <el-table-column label="讲座名称" prop="name"></el-table-column>
                <el-table-column label="学科" prop="event_name"></el-table-column>
                <el-table-column label="开始时间">
                    <template slot-scope="scope">
                        {{formateDate(scope.row.event_start_time)}}
                    </template>
                </el-table-column>
                <el-table-column label="结束时间">
                    <template slot-scope="scope">
                        {{formateDate(scope.row.event_end_time)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="toLecture(scope.row)" type="primary" size="medium">进入讲座</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {getMyZhiboRooms,checkZhiboOpen} from '../../api/keneng'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            event_rooms:[],
            default_event_rooms:[],
            extra_event_rooms:[]
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getMyZhiboRooms(getUserId()).then(res=>{
                if(res.data.code===0){
                    this.event_rooms = res.data.data.event_rooms
                    this.default_event_rooms = res.data.data.default_event_rooms
                    this.extra_event_rooms = res.data.data.extra_event_rooms
                }
            })
        },
        formateDate(time){
            let newTime = new Date(time*1000)
            return `${newTime.getFullYear()}-${newTime.getMonth()+1}-${newTime.getDate()} ${newTime.getHours()}:${newTime.getMinutes()>9?newTime.getMinutes():'0'+newTime.getMinutes()}`
        },
        toLecture(item){
            checkZhiboOpen(item.event_start_time,item.event_end_time,item.project_id,item.event_name).then(res=>{
                if(res.data.code===0){
                    let url = this.$router.resolve({
                        path:'/student/lecturescreen',
                        query:{
                            roomId:res.data.data.event_meeting_room_ids[0]
                        }
                    })
                    window.open(url.href,'_blank')
                }else{
                    this.$message(res.data.msg)
                }
            })
        }
    }
}
</script>

<style>

</style>