<template>
    <div style="width:100%">
        <div style="padding:30px">
            <p style="color:#606266">请输入用户邮箱</p>
            <el-input v-model="searchEmail" placeholder="输入学生邮箱" style="width:80%"></el-input>
            <el-button @click="findStudentByEmail" style="margin-left:10px" type="primary">搜索</el-button>
            <el-table :data="searchStudents" border>
                <el-table-column label="姓名">
                    <template slot-scope="scope">
                        {{scope.row.student_lastName+scope.row.student_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="viewFriendPost(scope.row._id.$id)" size="small" type="primary">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {findStudentByEmail} from '../../api/keneng'
export default {
    data(){
        return{
            searchEmail:'',
            searchStudents:[]
        }
    },
    mounted(){

    },
    methods:{
        findStudentByEmail(){
            findStudentByEmail(this.searchEmail).then(res=>{
                this.searchStudents = res.data
            })
        },
        viewFriendPost(id){
            this.$router.push({
                path:'/student/friendpostlist',
                query:{
                    friend_id:id
                }
            })
        }
    }
}
</script>

<style>

</style>