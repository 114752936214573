<template>
    <!-- <div>
    <div style="width:98%;color:#606266" > -->
        <!-- <el-button @click="play">播放</el-button> -->
        <!-- <div style="display:flex;justify-content:space-around;padding:18px">
            <div>
                <p>奖项公布 Award Announcement</p> -->
                <!-- <p>即将开启</p> -->
                <!-- <div class="commonBtn"> -->
                <!-- <el-button size="small" type="primary" style="" @click="voteClick">才艺投票 Talent Show Vote</el-button> -->
                <!-- </div> -->
            <!-- </div> -->
            
            <!-- <div class="block" >
                <p>才艺评比 2月8日 19:00</p>
                <p>Talent Show 19:00, February 8th </p>
            </div> -->
            <!-- <div>
                <p>颁奖典礼 2月9日 17:00</p>
                <p>Awarding Ceremony 17:00, February 9th</p>
            </div> -->
        
            <!-- 这里是投票按钮  --啥啥《XXX》 By XXX，XXX学校 --> 

            <!-- <el-dialog title="每人选5个最喜欢的表演 Vote for 5 shows you like best" :visible.sync="dialogVisible" @close="cancelCommentM()" width="55%">
                <span>截止时间 Vote Deadline: 20:18, Feb 8</span>
                <el-checkbox-group 
                    v-model="selectJiemu"
                    :max="5"
                    style="display: flex;flex-flow: column;" >
                    <el-checkbox v-for="jiemu in jiemu_list" style="margin:5px;"  :label="jiemu.type + '《'+jiemu.programName+'》' + 'By'+'   ' + jiemu.name + '   '+jiemu.school" :key="jiemu.programName" @change="(value)=> {checkboxchangeCell(value, jiemu)}">{{jiemu.type + '《'+jiemu.programName+'》' + 'By'+'   ' + jiemu.name + '   '+jiemu.school}}</el-checkbox>
                </el-checkbox-group>
                <!-- <el-input v-model.trim="commentsText" placeholder="请输入批注"></el-input> -->
                <!-- <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelCommentM()">取 消</el-button>
                    <el-button type="primary" @click="addCommentM()">确 定</el-button>
                </span>
            </el-dialog> --> 


        <!-- </div> -->
        
        
        <!-- <div style="margin:20px"><span style="margin:20px;color:red">完整获奖名单将稍后在本页面公布书面版本 The document of compelte award winner list will be posted here on this page later.</span></div> -->
        <!-- <div style="display:flex;justify-content:space-around;padding:18px"> -->
         
         <!-- <video :src="'https://mainbucket.learningfirst.cn/talent_show_award.mov'" controls="control" controlsList="nodownload" style="height:300px"></video> -->
        <!-- </div> -->
         <!-- <div class="prism-player" id="player-con"> -->

        <!-- <div style="display:flex;justify-content:space-around;padding:18px">
            <ul>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Accounting_I.pdf" target="_blank">Accounting_I</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Accounting_II.pdf"  target="_blank">Accounting_II</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Advertising.pdf"  target="_blank">Advertising</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Agribusiness.pdf"  target="_blank">Agribusiness</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Banking_&_Financial_Systems.pdf"  target="_blank">Banking & Financial_Systems</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Calculations.pdf"  target="_blank">Business_Calculations</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Communication.pdf"  target="_blank">Business_Communication</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Ethics.pdf"  target="_blank">Business_Ethics</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Etiquette.pdf"  target="_blank">Business_Etiquette</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Financial_Plan.pdf"  target="_blank">Business_Financial_Plan</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Law.pdf"  target="_blank">Business Law</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Math_&_Financial_Literacy.pdf"  target="_blank">Business Math & Financial Literacy</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Business_Plan.pdf"  target="_blank">Business_Plan</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Career_Exploration.pdf"  target="_blank">Career Exploration</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Computer_Problem_Solving.pdf"  target="_blank">Computer Problem Solving</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Critical_Thinking.pdf"  target="_blank">Critical Thinking</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Cyber_Security.pdf"  target="_blank">Cyber Security</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Digital_Citizenship.pdf"  target="_blank">Digital Citizenship</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Elevator_Speech.pdf"  target="_blank">Elevator Speech</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Emerging_Business_Issues.pdf"  target="_blank">Emerging_Business_Issues</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Entrepreneurship.pdf"  target="_blank">Entrepreneurship</a></li>
            </ul>
            <ul>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Health_Care_Administration.pdf"  target="_blank">Health Care Administration</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Hospitality_&_Event_Management.pdf"  target="_blank">Hospitality_&_Event_Management</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Insurance_&_Risk_Management.pdf"  target="_blank">Insurance_&_Risk_Management</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/International_Business.pdf"  target="_blank">International_Business</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Business_Communication.pdf"  target="_blank">Introduction_to_Business_Communication</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Business(ML).pdf"  target="_blank">Introduction_to_Business(ML)</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Business_Presentation.pdf"  target="_blank">Introduction_to_Business_Presentation</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Business_Procedures.pdf"  target="_blank">Introduction_to_Business_Procedures</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Business.pdf"  target="_blank">Introduction_to_Business</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Computer_Science_&_Coding.pdf"  target="_blank">Introduction_to_Computer_Science_&_Coding</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Financial_Math.pdf"  target="_blank">Introduction_to_Financial_Math</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Introduction_to_Information_Technology.pdf"  target="_blank">Introduction_to_Information_Technology</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Management_Decision_Making.pdf"  target="_blank">Management_Decision_Making</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Marketing.pdf"  target="_blank">Marketing</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Networking_Infrastructure.pdf"  target="_blank">Networking_Infrastructure</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Organizational_Leadership.pdf"  target="_blank">Organizational Leadership</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Personal_Finance.pdf"  target="_blank">Personal Finance</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Sales_Presentation.pdf"  target="_blank">Sales Presentation</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Securities_&_Investments.pdf"  target="_blank">Securities & Investments</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Social_Media_Strategies.pdf"  target="_blank">Social Media Strategies</a></li>
                <li><a href="http://mainbucket.learningfirst.cn/20-21FBLA_China_Final_Results/Sports_&_Entertainment_Management.pdf"  target="_blank">Sports & Entertainment Management</a></li>
            </ul>
        </div>

        </div>
        <div>
        <div style="margin:20px;display:flex;justify-content:space-around;padding:18px">
            <div>
            <div class="block" style="text-align:center;width:100%">
                <p>开幕式全程回放</p>
                <p>Opening Ceremony Complete Replay</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/FBAL_China_Theme_Song.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>加入FBLA!</p>
                <p>Connect with FBLA!</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Connect_with_FBLA.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA东区委员会欢迎辞</p>
                <p>FBLA Eastern Region Council Welcome</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Eastern_Region_Welcome.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA China学生领袖祝辞</p>
                <p>FBLA China Student Leaders Wishes</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/China_Leaders_Welcome.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA China指导老师祝辞</p>
                <p>FBLA China Adviser Wishes</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Adviser_Welcome.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA China资深顾问祝辞</p>
                <p>FBLA China Senior Advisers Wishes</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Senior_Adviser_Welcome.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>2020总决赛成绩回顾</p>
                <p>2020 NLC Review</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/2020_NLC_Review.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>选手、领袖和评委代表宣誓</p>
                <p>Oath by contestant, student leader and judge representatives</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Oath_Sessions.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>

             <div class="block" style="text-align:center;width:100%">
                <p>会议项目奖项公布</p>
                <p>Awards of Conference Events</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021_FBLA_China_Final_VC_FF_Awards.mov'" controls="control" controlsList="nodownload" style="width:100%"></video>
            </div>

            <div>
            
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA China主题曲</p>
                <p>FBLA China Theme Song</p>
            </div>
            <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/FBAL_China_Theme_Song.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA主席欢迎词</p>
                <p>FBLA President Welcome</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/President_Welcome.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA China往届主席祝辞</p>
                <p>FBLA China ex-Presidents Wishes</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/China_Ex-President_Welcome.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA China主席开幕辞</p>
                <p>FBLA China President Opening Speech</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/China_President_Speech.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>FBLA大学部祝辞</p>
                <p>FBLA Collegiate Division Wishes</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/FBLA_University.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>纽约大学斯特恩商学院教授主旨演讲</p>
                <p>Keynote Speech by NYU Stern Professor</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Keynote_Speech.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>20-21初赛回顾、杰出分会、杰出指导老师</p>
                <p>20-21 Preliminary Review, Outstanding Chapter and Adivser</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021FBLAChinaOpening/Preliminary_Review.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>

             <div class="block" style="text-align:center;width:100%">
                <p>才艺表演全程回放</p>
                <p>Talent Show Complete Replay</p>
            </div>
             <video :src="'http://mainbucket.learningfirst.cn/2020-2021_FBLAChina_TalentShow.m4v'" controls="control" controlsList="nodownload" style="width:100%"></video>
             <div class="block" style="text-align:center;width:100%">
                <p>才艺表演奖项公布</p>
                <p>Talent Show Awards</p>
            </div>
             <video :src="'https://mainbucket.learningfirst.cn/talent_show_award.mov'" controls="control" controlsList="nodownload" style="width:100%"></video>
             
             
            </div>

         </div>
        </div>
    </div> -->
        
<div class="videoContainer">
    <div class="prism-player" id="player-con"></div>
</div>


</template>

<script>

import {voteForStudent,getVoteStatus} from '../api/keneng'
import {getUserName,getUserId} from '../utils/store'
export default {
    data(){
        return{
            jiemu_list:[
                 {'name':'夏雯熙',
                 'type':'歌曲',
                 'programName':'All of me',
                 'school':'成都外国语学校'
                 },
                 {'name':'董子逸',
                 'type':'钢琴演奏',
                 'programName':'Merry Christmas Mr. Lawrence',
                 'school':'北京乐成国际学校'
                 },
                 {'name':'Esther Chen',
                 'type':'歌曲',
                 'programName':'Fly',
                 'school':'上海中学国际部'
                 },
                 {'name':'张晨',
                 'type':'舞蹈',
                 'programName':'月之花语',
                 'school':'广州番禺诺德安达学校'
                 },
                 {'name':'夏伊苓',
                 'type':'舞蹈',
                 'programName':'Blackpink-In your area',
                 'school':'杭州第十四中学'
                 },
                 {'name':'章子姮',
                 'type':'做菜+钢琴演奏',
                 'programName':'The Process of Making Pasta + Dmajor',
                 'school':'上海交大附中ib课程中心'
                 },
                 {'name':'汪靖涵',
                 'type':'弹唱',
                 'programName':'走马',
                 'school':'南京金地未来学校'
                 },
                 {'name':'李昕恬',
                 'type':'琵琶独奏',
                 'programName':'十面埋伏',
                 'school':'山东省实验中学'
                 },
                 {'name':'王若霖',
                 'type':'歌曲+钢琴演奏',
                 'programName':"Something just like this",
                 'school':'广州番禺诺德安达学校'
                 },
                 {'name':'陈旭鑫',
                 'type':'魔术',
                 'programName':'送你一朵小红花',
                 'school':'宁波外国语学校'
                 },
                 {'name':'吴雨欣',
                 'type':'钢琴演奏',
                 'programName':'彩云追月',
                 'school':'杭州外国语学校'
                 },
                 {'name':'高裕灵子(Summer)',
                 'type':'歌曲',
                 'programName':'歌曲串烧',
                 'school':'南京金地未来学校'
                 },
                 {'name':'李昊泽',
                 'type':'滑雪',
                 'programName':'雪原大竞技',
                 'school':'广州番禺诺德安达学校'
                 },
                 {'name':'余睿晗',
                 'type':'歌曲',
                 'programName':'You raise me up',
                 'school':'北京八中'
                 },
                 {'name':'欧萌',
                 'type':'舞蹈',
                 'programName':'Blackpink-Crazy over you',
                 'school':'无锡市第一中学国际部'
                 },
                 {'name':'Jacky Zhao',
                 'type':'歌曲',
                 'programName':'只要平凡',
                 'school':'THIS 清华附中国际部'
                 },
                 {'name':'周雨璋',
                 'type':'歌曲',
                 'programName':'Someone you loved',
                 'school':'江苏省天一中学'
                 },
                 {'name':'冉佳鹭',
                 'type':'钢琴演奏',
                 'programName':'Nocturne in C sharp minor升C小调夜曲',
                 'school':'广东实验中学国际AP课程'
                 },
                 {'name':'姜悠然',
                 'type':'歌曲',
                 'programName':'Supermarket flowers',
                 'school':'成都外国语学校'
                 },
                 {'name':'刘可',
                 'type':'舞蹈',
                 'programName':'爵士舞',
                 'school':'南京师范大学附属中学'
                 },
                 {'name':'林涵今',
                 'type':'歌曲',
                 'programName':"Something just like this",
                 'school':'广州番禺诺德安达学校'
                 },
                 {'name':'Zhujinxuan',
                 'type':'钢琴演奏',
                 'programName':'夜的钢琴曲',
                 'school':'南京金地未来学校'
                 },
                 
                 
            ],
            selectJiemu:[],
            dialogVisible: false,
            updateTime:""
        }
    },
    mounted(){
        var player = new Aliplayer({
            "id": "player-con",
            "source": "https://pili-live-hdl.reachable-edu.com/test-area/fbla.flv",
            "width": "100%",
            "height": "500px",
            "autoplay": true,
            "isLive": false,
            "rePlay": false,
            "playsinline": true,
            "preload": true,
            "controlBarVisibility": "hover",
            "useH5Prism": true
            }, function (player) {
                console.log("The player is created");
            }
        );

    //   this.fetchData()
       
    },
    methods:{
        fetchData() {
            let user_id = getUserId()
            getVoteStatus(user_id).then((res) =>{
                this.selectJiemu = res.data.send_ids
            })
        },
        cancelCommentM() {
            this.dialogVisible = false;
        },
        voteClick(){   
          this.dialogVisible = true;
        },
        addCommentM() {

            this.dialogVisible = false;

            let user_id = getUserId()
            voteForStudent(user_id,this.selectJiemu).then((res) =>{

                if (res.data.code == 0) {
                   this.$message({
                      message:'投票成功',
                      type:'success'
                   })
                }else{
                   this.$message(res.data.msg)
                }

                
            })
        },
        checkboxchangeCell (value, row) {

            var v1 = row.type + '《'+row.programName+'》' + 'By'+'   ' + row.name + '   '+row.school
            for(var a = 0;a<this.selectJiemu;a++){
                var v = this.selectJiemu[a]
                if (v == v1){
                    if(value == true){
                        this.selectJiemu.push[v1]
                    }else{
                        this.selectJiemu.splice(v1,a,1)
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
.videoContainer{
    width: 100%;
    overflow: hidden;
}
</style>