<template>
  <div class="game">

    <span >游戏配置信息</span>

    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="信息" name="first">
        
        <div style="display: flex;flex-direction: row;margin-top: 25px;align-items: center">
          <h4>名称：</h4>
          <el-input v-model="gameInfo.name" placeholder="请输入名称" style="width: 300px"/>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>行业：</h4>
          <el-select v-model="gameInfo.sector" placeholder="请选择">
            <el-option
              v-for="item in sectores"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>总轮数：</h4>
          <el-input v-model="gameInfo.round" @change="roundChange" placeholder="总轮数名称" type="number" style="width: 300px"/>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>当前轮数：</h4>
          <el-input v-model="gameInfo.current_round"  placeholder="当前轮数名称" type="number" style="width: 300px"/>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>每轮决策时间（分钟）：</h4>
          <el-input v-model="gameInfo.time_per_round"  placeholder="总轮数名称" type="number" style="width: 300px"/>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>开始时间：</h4>
          <el-date-picker
            v-model="selectedTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
        <div v-for="count in numPicker" style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>第{{count+1}}轮结算时间：</h4>
          <el-date-picker
            v-model="gameInfo.round_close_times[count+1]"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
        <div style="margin-top: 15px">
          <h4 >briefing：</h4>
          <el-input
            type="textarea"
            :rows="5"
            ref="input"
            placeholder="请输入briefing信息"
            :autofocus="false"
            v-model="gameInfo.briefing">
          </el-input>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <h4>开关的状态：</h4>
          <el-select v-model="gameInfo.open_status" placeholder="请选择">
            <el-option
              v-for="item in YESNO"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="flex-right" style="margin-top: 40px">
          <el-button type="primary" @click="updateGameInfo">更新游戏信息</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Team列表" name="second">
          <div style="display: flex;flex-direction: row-reverse;margin-bottom: 10px">
            <div>
              <el-button type="primary" @click="createNewTeam">新建Team</el-button>
            </div>
          </div>
          <div style="margin-top: 15px">
          <span >Team列表</span>
          <el-table :data="teames" border style="margin-top: 10px">
            <el-table-column prop="internal_code" label="Internal Code" align="center" width="200px"/>
            <el-table-column prop="" label="详情" align="center" width="200px">
              <template slot-scope="scope">
                <div>
                  <el-button type="primary" @click="showTeam(scope.row._id.$id)">查看</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="环境卡配置" name="three">
          <span >环境卡列表</span>

          <el-table :data="roundArr" border style="margin-top: 10px">
            <el-table-column prop='name' label="轮数" align="center" width="200px"/>
            </el-table-column>
            <el-table-column prop="" label="环境卡" align="center" width="400px">
              <template slot-scope="scope">
                <div>
                  <el-select v-model="scope.row.selectId" multiple slot="prepend" placeholder="请选择" style="width: 300px"  @change="(value)=> {changeCell(value, scope.row)}">
                  <el-option
                    v-for="item in ecoCards"
                    :key="item._id.$id"
                    :label="item.name"
                    :value="item._id.$id"
                  >
                  </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
          </el-table>
      </el-tab-pane>
    </el-tabs>


    
    

  </div>
</template>
<script>
  import Cookies from 'js-cookie'
  import {updateGame,getCardsBySector,getGame,getTeamsByGame, getEcoCard,getRoundEcoCards,getEcoCards,assignRoundEcoCard} from '../../api/VC_Arena'
  export default {
    data(){
      return {
        sectores:["New-Style Tea"],
        sector: 'New-Style Tea',
        YESNO:["YES","NO"],
        selectedTime:'',
        selectedRoundCloseTimes:'',
        selectedSector:'',
        gameInfo:{"name":"","sector":"","round":0,"current_round":0,"time_per_round":0,"start_time":0,"briefing":"","open_status":"","round_close_times":{}},
        CardsBySector:[],
        gameId:"",
        teames:[],
        activeName: "first",
        selectedCurrentRound:0,
        ecoCards:[],
        roundArr:[],
        numPicker:[]
      }
    },
    mounted(){
      var id =  this.$route.params.id
      if(id ==="create"){
        this.gameId = ""
      }else{
        this.gameId = id
      }
      this.fetchData()
    },
    methods: {
      fetchData(){
        if(this.gameId != ""){
          getGame(this.gameId).then((res) =>{
            this.gameInfo = res.data.data
            for (var a= 0; a<res.data.data.round ;a++){
                // getRoundEcoCards(this.gameId,a+1)
                let b = a+1;
                getRoundEcoCards(this.gameId,b).then((res) => {
                    // console.log(res,'res')
                    let result  = {}
                    if(res.data.data == null){
                        result  = {name:b,
                        selectId:'',
                        card_ids_id:''
                        }
                    }else{
                        result  = {name:b,
                        selectId:res.data.data.card_ids,
                        card_ids_id:res.data.data._id.$id
                        }
                    }
                    this.roundArr.push(result)
                })


            }
            // console.log(this.gameInfo,'11111')
            for (const key in this.gameInfo.round_close_times) {
              let vv = this.gameInfo.round_close_times[key]
              if(vv==null){
                this.gameInfo.round_close_times[key] = ''
              }else{
                let v = this.dateFormat(this.gameInfo.round_close_times[key] *1000)
                this.gameInfo.round_close_times[key] = v
              }
              
            }

            for (let a= 0;a<this.gameInfo.round;a++) {
              this.numPicker.push(a)
            }
            // console.log(this.gameInfo,'999');


            this.selectedTime= this.dateFormat(this.gameInfo.start_time *1000)
            this.doGetCardsBySector(this.gameInfo.sector)
          })
          getTeamsByGame(this.gameId).then((res) => {
            this.teames = res.data.data
          })
          
          getEcoCards().then((res) => {
            this.ecoCards = res.data.data
          })
        }
      },
      updateGameInfo(){
        this.gameInfo.start_time = new Date(this.selectedTime).getTime() /1000
        // var closeTime= {}
        for (const key in this.gameInfo.round_close_times) {
          let v =  new Date(this.gameInfo.round_close_times[key]).getTime() /1000
          this.gameInfo.round_close_times[key] = v
        }
        let newround_close_times = {}
        for(let a=0 ;a<this.gameInfo.round ;a++){
          newround_close_times[a+1] =  this.gameInfo.round_close_times[a+1]
        }
        this.gameInfo.round_close_times = newround_close_times
        // closeTime[this.gameInfo.round]=new Date(this.selectedRoundCloseTimes).getTime() /1000
        // this.gameInfo.round_close_times = closeTime
        // console.log(this.gameInfo.round_close_times,'111')
        // console.log(closeTime/1000,'111')

        updateGame(this.gameId,this.gameInfo).then((res) =>{
          this.$message('更新成功')
          this.$router.go(-1)
        })
      },
      dateFormat(time) {
        var date=new Date(time);
        var year=date.getFullYear();
        var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
        var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
        var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
        var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
        return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
      },
      showTeam(id) {
        Cookies.set("gameId",this.gameId)
        this.$router.push('/game/team/'+id)
      },
      createNewTeam() {
        Cookies.set("gameId",this.gameId)
        this.$router.push('/game/team/'+"create")
      },
 
      changeCell (value, row) {
        // console.log(value,'111')
        // console.log(row.name,'222')
        // console.log(row.card_ids_id,'3333')
        assignRoundEcoCard(row.card_ids_id,row.name,this.gameId,value).then((res) => {
            // console.log(res,'assignRoundEcoCard')
            row.card_ids_id = res.data.data._id.$id
        })
      },
      roundChange(value){
        this.numPicker = []

        for (let a=0;a<value ;a++) {
          this.numPicker.push(a)
        }
        console.log(this.gameInfo.round_close_times,'111');
        
      }
    },
    watch: {
        
    }
  }
</script>

<style scoped>
  .game {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
