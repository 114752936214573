<template>
    <div style="width:100%">
        <div style="margin-top:10px">
            <el-button type="primary"  @click="addRoom" style="margin-left:30px">新增房间</el-button>
        </div>
        <el-table :data="room_list" style="padding:30px">
            <el-table-column prop="room_id" label="房间号" width="200"></el-table-column>
            <el-table-column label="房间类型" width="200">
                <template slot-scope="scope">
                    <span v-if="scope.row.room_type==='preparing'">准备室</span>
                    <span v-if="scope.row.room_type==='meeting'">会议室</span>
                    <span v-if="scope.row.room_type==='show'">才艺汇演</span>
                </template>
            </el-table-column>
      
           <el-table-column prop="room_activity" label="房间活动" width="200"></el-table-column>
            <el-table-column label="开启状态" width="200">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.room_status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="((val)=>{changeRoomStatus(val,scope.row._id.$id)})">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" >
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="editRoom(scope.row)">修改信息</el-button>
                    <!-- <el-button type="primary" size="small" @click="viewRoomStudents(scope.row._id.$id)">查看成员</el-button> -->
                    <el-button type="primary" size="small" @click="viewUsers(scope.row._id.$id,scope.row.room_id,scope.row.room_activity)">查看成员</el-button>
                    <el-button type="primary" size="small" @click="toRoom(scope.row._id.$id,scope.row.room_id,scope.row.room_activity)">进入房间</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showActive" title="查看成员">
            <el-table :data="room_users">
                <el-table-column label="学生姓名" >
                    <template slot-scope="scope">
                        <div :class="scope.row.status=='out'? 'red':''">{{scope.row.student_name}}</div>                    
                    </template>
                </el-table-column>
                <el-table-column prop="account_name" label="学校名">
                    <template slot-scope="scope">
                        <div :class="scope.row.status=='out'? 'red':''">{{scope.row.account_name}}</div>                    
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="kick(scope.row._id.$id)">踢出</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :visible.sync="showRoom" title="修改房间信息">
            <el-form>
                <el-form-item label="房间号">
                    <el-input v-model="editForm.room_id" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="房间类型">
                    <el-select v-model="editForm.room_type">
                        <el-option value="preparing" label="准备室"></el-option>
                        <el-option value="meeting" label="会议室"></el-option>
                        <el-option value="show" label="才艺汇演"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间活动">
                    <el-select v-model="editForm.room_activity">
                        <el-option value="Exam" label="Exam 考试"></el-option>
                        <el-option value="Presentation" label="Presentation 演示"></el-option>
                    </el-select>
                </el-form-item>
                
            </el-form>
            <el-button @click="update" type="primary">确认</el-button>
        </el-dialog>
        <el-dialog :visible.sync="showUser" title="查看成员">
            <div>
                <p>成员列表</p>
                <el-tag @close="removeStudentInRoom(item._id.$id)" closable style="margin:4px" v-for="(item,index) in roommates" effect="dark" :key="index">{{item.student_lastName+item.student_givenName}}</el-tag>
            </div>
            <div style="margin-top:10px">
                <el-input v-model="searchEmail" placeholder="输入学生邮箱" style="width:80%"></el-input>
                <el-button @click="findStudentByEmail" style="margin-left:10px">搜索</el-button>
            </div>
            <el-table :data="searchStudents">
                <el-table-column label="姓名">
                    <template slot-scope="scope">
                        {{scope.row.student_lastName+scope.row.student_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="addStudentToRoom(scope.row._id.$id)" size="small" type="primary">加入</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import {getAllRooms,changeRoomStatus,listRoomUsers,kickUser,findStudentByEmail,addStudentToRoom,removeStudentInRoom,getRoomStudents,updateRoom,getApplicationsByEvents} from '../api/keneng'
import {formatDate} from '../utils/date'
export default {
    data(){
        return{
            room_list:[],
            room_users:[],
            showActive:false,
            showRoom:false,
            showUser:false,
            room_id:'',
            editForm:{

            },
            roommates:[],
            searchStudents:[],
            searchEmail:'',
            roomId:'',
            start_time:''
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getAllRooms().then(res=>{
                if(res.data.code===0){
                    this.room_list = res.data.data
                }
            })
        },
        changeRoomStatus(status,id){
            changeRoomStatus(status,id).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.fetchData()
                }
            })
        },
        viewUsers(id,roomId,room_activity){
            this.room_users = []
            this.showActive = true
            this.room_id =id
            this.roomId = roomId
           
            // this.listRoomUsers(id)
            getApplicationsByEvents(id,roomId,room_activity).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.room_users = res.data.data
                }
                console.log(res)
            })
        },
        kick(userId){
            kickUser(this.room_id,userId).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.$message({
                        message:'已踢出',
                        type:'success'
                    })
                    this.viewUsers(this.room_id,this.roomId,this.start_time)
                }
            })
        },
        listRoomUsers(id){
            listRoomUsers(id).then(res=>{
                this.room_users = res.data.users
            })
        },
        editRoom(data){
            this.showRoom=true
            this.editForm = data
        },
        update(){
            updateRoom(this.editForm._id.$id,this.editForm.room_id,this.editForm.room_type,this.editForm.room_activity).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                    this.showRoom=false
                    this.fetchData()
                }
            })
        },
        addRoom(){
            this.showRoom=true
            this.editForm = {
                _id:{
                    $id:''
                }
            }
        },
        viewRoomStudents(id){
            this.showUser = true
            this.room_id = id
            this.searchEmail = ''
            this.searchStudents=[]
            this.getRoomStudents(id)
        },
        getRoomStudents(id){
            getRoomStudents(id).then(res=>{
                if(res.data.code===0){
                    this.roommates = res.data.data
                }
            })
        },
        findStudentByEmail(){
            findStudentByEmail(this.searchEmail).then(res=>{
                console.log(res)

                this.searchStudents = res.data

            })
        },
        addStudentToRoom(id){
            addStudentToRoom(this.room_id,id).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'添加成功',
                        type:'success'
                    })
                    this.getRoomStudents(this.room_id)
                }
            })
        },
        removeStudentInRoom(id){
            removeStudentInRoom(this.room_id,id).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'删除成功',
                        type:'success'
                    })
                    this.getRoomStudents(this.room_id)
                }
            })
        },
        formatDate(time){
            let date = new Date(time*1000)
            return formatDate(date,'Y-MM-dd hh:mm:ss')
        },
        toRoom(room_id,roomId,room_activity){
            if(room_activity=='Exam'){
                this.$router.push({
                    path:'/admin/roomAdmin',
                    query:{
                        roomId:roomId,
                        room_id:room_id,
                        room_activity:room_activity
                    }
                })
            }else{
                this.$router.push({
                    path:'/admin/presentationadmin',
                    query:{
                        roomId:roomId,
                        room_id:room_id,
                        room_activity:room_activity
                    }
                })
            }
        }
    }   
}
</script>

<style>
.red{
    color:red
}
</style>
