<template>
  <div class="container">

    <span>{{tableTitle}}</span>
    <br>

    <el-row style="width = 300px">
        <el-col :span="6" v-for="(item, index) in picImgs" :key="item" :offset="index > 0 ? 0 : 0" style="width:300px">
            <el-card :body-style="{ padding: '10px'}">
            <div style="width:100%;text-align:center"><img :src="'http://' + item.card_cover_address" style="width:250px;border-radius:20px"></div>
            <div style="text-align:center;margin:20px">投入 Investment<br>{{cards_investment[item._id.$id]}}</div>
            </el-card>
        </el-col>
    </el-row>
    
  </div>
</template>

<script>
import {getOtherTeamData} from '../api/VC_Arena'

export default {
    name: "AppearCards",
    data() {
      return {

      };
    },
    props: {
      picImgs:Array,
      tableTitle:String,
      dataType:Number,
      gameId:String,
      teamId:String,
      currentRound:Number,
      cards_investment:{}
    },
    mounted() {
      // const vm = this
    },
    components: {
    
    },
    methods:{
      doChoseCard(){
        this.$emit('callback', 'doChoseCard')
      },
      showOtherTeamsInfo(other_game_id){
        getOtherTeamData(other_game_id,this.currentRound).then((res)=>{

        })
      },
      getCardsInvestment(id){
          return this.myTeamCards['cards_investment'][id]
      },
    },
    watch: {
      myTeamCards (newValue, oldValue) {
          var cards = newValue
        if(null == cards){

        }
      },
    }
  };
</script>


<style lang="scss" scoped>
.image {
    width: 100%;
    display: block;
  }
</style>
