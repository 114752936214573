<template>
    <div style="width:100%">
    <div class="top">
        <div class="item" >
            <h3>重要：必读/Important: Must Read</h3>
            <div style="margin-top:50px"><el-button type="primary" @click="viewPDF">客观考试须知 / Objective Test Instructions</el-button></div>
            

        </div>
        <div class="item">
            <h3>监考应用/Proctor APP</h3>
            <p>手机扫码并下载 Use Your Mobile to Scan and Download:</p>
            <p style="color:red;font-size:10px">请在正式考试前确保重新下载最新版本（技术问题修复）Please download APP again before formal test for the latest version with bugs fixed</p>
            <p style="color:red;font-size:10px">平板电脑不受支持 Tablets/Pads are not supported</p>
            <img :src="require('../../public/dl.png')" style="width:30%">
        </div>
        <div class="item">
            <h3>准考证号/Test ID</h3>
            <p>{{application_id}}</p>
        </div>
    </div>
        <div class="part">
           <p class="txt">所有选手统一必考项目（仅限初中） / Mandatory Events for All Contestants (Middle Level Only)</p>
            <el-table :data="default_event_rooms" border >
                    <el-table-column prop="event_name" label="项目名 Event" width="240"></el-table-column>
                    <el-table-column prop="application_id" label="准考证号 Test ID" width="240"></el-table-column>
                    <el-table-column label="开始时间 Start Time">
                        <template slot-scope="scope">
                            <!-- <div v-if="scope.row.event_start_time!=undefined">{{formatDate(scope.row.event_start_time)}}</div> -->
                            <div v-if="scope.row.event_start_time!=undefined">{{scope.row.event_start_time * 1000 | timeFormat}}</div>
                            <div v-if="scope.row.event_start_time==undefined">Not Available Yet</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="结束时间 End Time">
                        <template slot-scope="scope">
                            <!-- <div v-if="scope.row.event_end_time!=undefined">{{formatDate(scope.row.event_end_time)}}</div> -->
                            <div v-if="scope.row.event_end_time!=undefined">{{scope.row.event_end_time * 1000 | timeFormat}}</div>
                            <div v-if="scope.row.event_end_time==undefined">Not Available Yet</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型 Type" width="120">
                            <template slot-scope="scope">
                                <div v-if="scope.row.type!=undefined">{{scope.row.type}}</div>
                                <div v-if="scope.row.type==undefined">Not Available Yet</div>
                            </template>
                    </el-table-column>
                    <el-table-column label="操作 Actions">
                        <template slot-scope="scope">
                             <el-button v-if="scope.row.event_start_time!=undefined" @click="getMyRoomStatus(scope.row.event_name,scope.row.type,scope.row.event_meeting_room_ids[0])" type="primary" size="small">考试链接 Test Link</el-button>
                        </template>
                    </el-table-column>
                </el-table>
        </div>
        <div class="part">
            <p class="txt">基本项目 / Base Events</p>
            <el-table :data="event_rooms" border>
                <el-table-column prop="event_name" label="项目名 Event" width="240"></el-table-column>
                <el-table-column prop="application_id" label="准考证号 Test ID" width="240"></el-table-column>
       
                <el-table-column label="开始时间 Start Time">
                    <template slot-scope="scope">
                        <!-- <div v-if="scope.row.event_start_time!=undefined">{{formatDate(scope.row.event_start_time)}}</div> -->
                        <div v-if="scope.row.event_start_time!=undefined">{{scope.row.event_start_time *1000| timeFormat}}</div>
                        <div v-if="scope.row.event_start_time==undefined">Not Available Yet</div>
                    </template>
                </el-table-column>
                 <el-table-column label="结束时间 End Time">
                        <template slot-scope="scope">
                            <!-- <div v-if="scope.row.event_end_time!=undefined">{{formatDate(scope.row.event_end_time)}}</div> -->
                            <div v-if="scope.row.event_end_time!=undefined">{{scope.row.event_end_time * 1000 | timeFormat}}</div>
                            <div v-if="scope.row.event_end_time==undefined">Not Available Yet</div>
                        </template>
                    </el-table-column>
                <el-table-column label="类型 Type" width="120">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type!=undefined">{{scope.row.type}}</div>
                            <div v-if="scope.row.type==undefined">Not Available Yet</div>
                        </template>
                </el-table-column>
                <el-table-column label="操作 Actions">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.event_start_time!=undefined" @click="getMyRoomStatus(scope.row.event_name,scope.row.type,scope.row.event_meeting_room_ids[0])" type="primary" size="small">考试链接 Test Link</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="part">
            <p class="txt">附加项目 / Extra Events</p>
            <el-table :data="extra_event_rooms" border >
                    <el-table-column prop="event_name" label="项目名 Event" width="240"></el-table-column>
                    <el-table-column prop="application_id" label="准考证号 Test ID" width="240"></el-table-column>
                    <el-table-column label="开始时间 Start Time">
                        <template slot-scope="scope">
                            <!-- <div v-if="scope.row.event_start_time!=undefined">{{formatDate(scope.row.event_start_time)}}</div> -->
                            <div v-if="scope.row.event_start_time!=undefined">{{scope.row.event_start_time * 1000 | timeFormat}}</div>
                            <div v-if="scope.row.event_start_time==undefined">Not Available Yet</div>
                        </template>
                    </el-table-column>
                     <el-table-column label="结束时间 End Time">
                        <template slot-scope="scope">
                            <!-- <div v-if="scope.row.event_end_time!=undefined">{{formatDate(scope.row.event_end_time)}}</div> -->
                            <div v-if="scope.row.event_end_time!=undefined">{{scope.row.event_end_time * 1000 | timeFormat}}</div>
                            <div v-if="scope.row.event_end_time==undefined">Not Available Yet</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型 Type" width="120">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type!=undefined">{{scope.row.type}}</div>
                            <div v-if="scope.row.type==undefined">Not Available Yet</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作 Actions">
                        <template slot-scope="scope">
                             <el-button v-if="scope.row.event_start_time!=undefined" @click="getMyRoomStatus(scope.row.event_name,scope.row.type,scope.row.event_meeting_room_ids[0])" type="primary" size="small">考试链接 Test Link</el-button>
                        </template>
                    </el-table-column>
                </el-table>
        </div>
    </div>
</template>

<script>
import {getMyEvents,checkEventOpen,getMyRoomStatus} from '../api/keneng'
import {getUserId} from '../utils/store'
import {formatDate} from '../utils/date'
export default {
    data(){
        return{
            default_event_rooms:[],
            event_rooms:[],
            extra_event_rooms:[],
            application_id:''
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            let student_id = getUserId()
            getMyEvents(student_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.default_event_rooms=res.data.data.default_event_rooms
                    this.event_rooms=res.data.data.event_rooms
                    if(res.data.data.event_rooms.length>0){
                        this.application_id = res.data.data.event_rooms[0].application_id
                    }else{
                        this.application_id = res.data.data.default_event_rooms[0].application_id
                    }
                    
                    this.extra_event_rooms=res.data.data.extra_event_rooms
                }
            })
        },
        formatDate(time){
            let date = new Date(time*1000)
            return formatDate(date,'Y-MM-dd hh:mm:ss')
        },
        getMyRoomStatus(event_name,type,room_id){
            getMyRoomStatus(this.application_id,event_name,type,room_id).then(res=>{
                if(res.data.code===0){
                    window.open(res.data.data)
                }else if(res.data.code==-1){
                    this.$message("考试时间未到 The Test hasn't started yet")
                }else if(res.data.code==-2){
                    this.$message('未找到信息 No Information')
                }else if(res.data.code==-3){
                    this.$message("你还未登录监考应用 You haven't logged in the Procter APP")
                }
            })
        },
        checkEventOpen(project_id,event_name){
            let student_id = getUserId()
            checkEventOpen(student_id,project_id,event_name).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    window.open(res.data.data.test_link)
                }else{
                    this.$message('出错了 Error')
                }
            })
        },
        viewPDF(){
            window.open('http://mainbucket.learningfirst.cn/fbla/%E8%80%83%E8%AF%95%E9%A1%BB%E7%9F%A5Test_Instructions.pdf')
        }
    }
}
</script>

<style>
.part{
    padding:30px
}
.txt{
    color:#606266
}
.top{
    width:100%;
    height:240px;
    display:flex;
    justify-content:space-around
}
.item{
    width:26%;
    color:#606266
}
.btn{
    text-align:center
}
</style>
