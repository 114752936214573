<template>
  <div class="game">
    <div style="display: flex;flex-direction: row-reverse;margin-bottom: 10px">
      <div>
        <el-button type="primary" @click="createNewGameCard">新建游戏配置</el-button>
      </div>
    </div>
    <span >游戏配置列表</span>
    <el-table :data="games" border style="margin-top: 10px">
      <el-table-column prop="name" label="名称" align="center" width="200px"/>
      <el-table-column prop="round" label="总轮数" align="center" width="200px"/>
      <el-table-column prop="current_round" label="当前轮数" align="center" width="200px"/>
      <el-table-column prop="time_per_round" label="每轮决策时间（分钟)" align="center" width="200px"/>
      <el-table-column prop="" label="开始时间" align="center" width="200px">
        <template slot-scope="scope">
          <div>
            {{dateFormat(scope.row.start_time * 1000)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="每轮结算时间" align="center" width="200px">
        <template slot-scope="scope">
          <div>
            {{getCloseTime(scope.row)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="open_status" label="开关状态" align="center" width="200px"/>
      <el-table-column prop="" label="详情" align="center" width="200px">
        <template slot-scope="scope">
          <div>
            <el-button type="primary" @click="showGameDetail(scope.row._id.$id)">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {getGames} from '../../api/VC_Arena'
  export default {
    data(){
      return {
        games:[]
      }
    },
    mounted(){
      this.fetchData()
    },
    methods: {
      fetchData(){
        getGames().then((res) =>{
          this.games = res.data.data
        })
      },
      showGameDetail(id){
        this.$router.push('/game/detail/'+id)
      },
      createNewGameCard() {
        this.$router.push('/game/detail/'+"create")
      },
      getCloseTime(row){
        if(null ==row.round_close_times||  row.round_close_times == undefined){
            return ""
        }
        var times = row.round_close_times
        var time = ""
        for(let key in times){
          time=times[key]
        }
        return this.dateFormat(time *1000)
      },
      dateFormat(time) {
        var date=new Date(time);
        var year=date.getFullYear();
        var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
        var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
        var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
        var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
        return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
      },
    }
  }
</script>
<style scoped>
  .game {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
