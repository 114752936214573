<template>
    <div style="width:100%">
        <div>
          <!-- <div style="text-align:center;color:#606266;font-size:14px">
            <el-button @click="joinRoom('meeting')" type="primary">进入会议</el-button>
            <div v-if="meeting">
              <p>开始时间：{{formatDate(meeting.start_time)}}</p>
              <p>房间号：{{meeting.roomId}}</p>
            </div>
          </div> -->
          <!-- <div style="text-align:center;color:#606266;font-size:14px">
            <el-button @click="joinRoom('preparing')" type="primary">进入准备室</el-button>
            <div v-if="preparing">
              <p>开始时间：{{formatDate(preparing.start_time)}}</p>
              <p>房间号：{{preparing.roomId}}</p>
            </div>
          </div> -->
          <div v-if="!isInRoom" style="padding:30px">
            <p style="color:#606266">时刻表</p>
            <el-table :data="time_slot">
              <el-table-column prop="roomId" label="房间号"></el-table-column>
              <el-table-column label="房间类型">
                <template slot-scope="scope">
                  {{scope.row.room_info.room_type=='preparing'?'准备室':'会议室'}}
                </template>
              </el-table-column>
              <el-table-column label="开始时间">
                <template slot-scope="scope">
                  {{formatDate(scope.row.start_time)}}
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="joinRoom(scope.row.roomId,scope.row.start_time)">进入房间</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="isInRoom" style="margin-top:10px">
            <!-- <el-dropdown trigger="click" @visible-change="listRoomUsers" style="margin-left:10px" placement="bottom-start">
              <el-badge :value="newMessages.length" class="item" :hidden="newMessages.length==0">
                <el-button type="primary">
                  聊天<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
              </el-badge>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in userList" :key="index">
                  <el-badge :value="computeNum(item._id.$id)" class="item" :hidden="computeHidden(item._id.$id)">
                    <div style="width:80px;height:40px;line-height:40px" @click="initChat(item._id.$id)">{{item.student_lastName+item.student_givenName}}</div>
                  </el-badge>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->

            <el-button type="warning" @click="leaveRoom" style="margin-left:10px">离开房间</el-button>

            <!-- 公屏 -->
            <div style="margin:20px">
              <p style="color:#606266">聊天框</p>
              <div style="width:80%;height:300px;overflow-y:scroll">
                <div :class="(item.userId==userId?'right':'left')" v-for="(item,index) in publicMessages" :key="index" style="line-height:30px">
                  <span style="font-size:10px;color:#606266">{{item.user_name}}</span>
                  <div>
                    <span style="padding:8px 12px;background:rgba(64,158,255,0.3);border-radius:20px;font-size:14px">{{item.data}}</span>
                  </div>
                </div>
              </div>
              <div style="margin-top:20px">
                <el-input v-model="message" style="width:74%"></el-input>
                <el-button type="primary" @click="sendPublicMessage" style="margin-left:10px">发送</el-button>
              </div>
            </div>

          </div>
        </div>

        <el-dialog :visible.sync="showChat" @close="removeMessage">
          <div class="chatBlock">
            <div v-for="(item,index) in chatMessage" :key="index" style="height:50px;line-height:50px;margin-top:10px">
              <div :class="(item.userId==userId?'right':'left')">
                <span style="background:rgba(64,158,255,0.3);border-radius:20px;padding:10px 16px;">{{item.data}}</span>
              </div>
            </div>
          </div>
          <div>
            <el-input v-model="message" style="width:80%"></el-input>
            <el-button type="primary" @click="sendMessage" style="margin-left:10px">发送</el-button>
          </div>
        </el-dialog>

        <div class="users" v-if="isInRoom">
            <p style="color:#606266">本地视频</p>
            <div id="localvideo" style="width:320px"></div>
            <p style="color:#606266">会议内容</p>
            <div id="remote"></div>
        </div>
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import { listActiveRooms, createRoomToken, listRoomUsers,getPresentationInfo,getStudent } from '../api/keneng'
import {getUserId} from '../utils/store'
import {formatDate} from '../utils/date'
const myRoom = new QNRTC.StreamModeSession()

console.log('current version is', QNRTC.version)
export default {
  data () {
    return {
      audioDevice: '',
      videoDevice: '',
      audioDevices: [],
      videoDevices: [],
      roomToken: '',
      userList: [],
      currentUser: '',
      lastUser: '',
      showInfo: false,
      showToken: false,
      room: '',
      userId: '',
      userToken: '',
      room_list: [],
      type: '',
      currentRoom: '',
      message:'',
      toUserId:'',
      showChat:false,
      chatMessage:[],
      allMessages:{},
      newMessages:[],
      isInRoom:false,
      room_id:'',
      time_slot:[],
      publicMessages:[],
      showPublic:[]
    }
  },
  mounted () {
    this.listActiveRooms()  
    this.getPresentationInfo()
    if (QNRTC.deviceManager.deviceInfo) {
      this.addDeviceToSelect(QNRTC.deviceManager.deviceInfo)
    }
    QNRTC.deviceManager.on('device-update', deviceInfo => {
      this.addDeviceToSelect(deviceInfo)
    })
  },
  methods: {
    addDeviceToSelect (deviceInfo) {
      let audioDevices = []
      let videoDevices = []
      deviceInfo.forEach(info => {
        if (info.kind === 'audioinput') {
          audioDevices.push(info)
        } else if (info.kind === 'videoinput') {
          videoDevices.push(info)
        }
      })
      this.audioDevices = audioDevices
      this.videoDevices = videoDevices
    },
    async joinRoom (roomId,time) {
      // 从输入框中获取 roomToken

      this.showInfo=false

      let roomToken

      const result = await this.createRoomToken(roomId,time)
      if(result.code==0){
        roomToken = result.data.token
        this.room_id = result.data.room_id
      }else{
        this.$message(result.msg)
        return;
      }

      try {
        // 加入房间
        const users = await myRoom.joinRoomWithToken(roomToken)
        this.isInRoom = true

        // 如果加入房间后发现房间已经有人发布，就订阅他
        for (const user of users) {
          if (user.userId !== myRoom.userId && user.published) {
            this.subscribeUser(user.userId)
          }
        }
      } catch (e) {
        console.error(e)
        this.isInRoom = false
        this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
        return
      }

      // 监听房间中其他人发布的事件，自动订阅他
      myRoom.on('user-publish', (user) => {
        if(user.userId=='mobile'){
              this.subscribeUser(user.userId,'mobile')
            }else if(user.userId=='ppt'){
              this.subscribeUser(user.userId,'ppt')
            }else{
              let div = document.createElement('div');
              div.setAttribute('id',user.userId);
              div.style.width = '320px';
              let remote = document.getElementById('remote')
              remote.appendChild(div)

              this.subscribeUser(user.userId)
            }
       
      })

      myRoom.on("messages-received", data=> {
        
       
        // if(this.allMessages[data[0].userId]!==undefined){
        //   let box = this.allMessages[data[0].userId]
        //   box.push(data[0])
        //   this.allMessages[data[0].userId] = box
        // }else{
        //   this.allMessages[data[0].userId]=[]
        //   let box = this.allMessages[data[0].userId]
        //   box.push(data[0])
        //   this.allMessages[data[0].userId] = box

        // }
        // this.updateMessage()
        // if((data[0].userId==this.toUserId) && this.showChat==true){

        // }else{
        //   let box = this.newMessages
        //   box.push(data[0])
        //   this.newMessages = box
        // }
        console.log('收到了信息')
        let messages = this.publicMessages
        let message = data[0]

        getStudent(message.userId).then(res=>{
          message.user_name = res.data.student_lastName+res.data.student_givenName
          messages.push(message)
          this.publicMessages=messages
        })

      })

      myRoom.on("user-join",(user)=>{
        this.$message({
          message:user.userId+'加入了房间~',
          type:'success'
        })
      });

      // 自动发布
      await this.publish(myRoom)

    },
    updateMessage(){
      this.chatMessage = this.allMessages[this.toUserId]
    },

    async publishScreen () {
      let stream
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        stream = await QNRTC.deviceManager.getLocalStream({
          screen: {
              enabled: true,
              source: "window",
              bitrate: 600,
              height: 480,
              width: 640,
          },
          audio: {
              enabled: false,
          }
        })
      } catch (e) {
        console.error(e)
        this.$message(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      }

      // 将采集到的流静音播放
      const localVideo = document.getElementById('localvideo')
      stream.play(localVideo, true)

      try {
        // 发布采集流
        await myRoom.publish(stream)
      } catch (e) {
        console.error(e)
        this.$message(`发布失败，ErrorCode: ${e.code}`)
      }
    },
    async publish () {
      let stream
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        stream = await QNRTC.deviceManager.getLocalStream({
          video: {
            enabled: true,
            deviceId: this.videoDevice,
            bitrate: 1000,
            height:720,
            width:1280
          },
          audio: {
            enabled: true,
            deviceId: this.audioDevice
          }
        })
      } catch (e) {
        console.error(e)
        alert(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      }

      // 将采集到的流静音播放
      const localVideo = document.getElementById('localvideo')
      stream.play(localVideo, true)

      try {
        // 发布采集流
        await myRoom.publish(stream)
      } catch (e) {
        console.error(e)
        alert(`发布失败，ErrorCode: ${e.code}`)
      }
    },
    subscribeUser (userId) {
      const remoteContainer = document.getElementById('remote')
      // 订阅目标用户
      myRoom.subscribe(userId).then(stream => {
        stream.play(remoteContainer)
      })
    },
    listActiveRooms () {
      listActiveRooms().then(res => {
        this.room_list = res.data.rooms
      })
    },
    createRoomToken (roomId,time) {
      let user_id = getUserId()
      return new Promise((resolve,reject)=>{
        createRoomToken(roomId,user_id,time).then(res => {
          console.log(res)
          resolve(res.data)
        })
      })
      
    },
    listRoomUsers (e) {
      if(e){
        if(this.isInRoom==true){
          if(this.room_id!==''){
              listRoomUsers(this.room_id).then(res => {
                console.log(res)
                this.userList = res.data
              })
          }
        }
      }
    },
    changeView () {
      for (let item of this.userList) {
        if (item.userId == this.currentUser) {
          myRoom.setMergeStreamLayout(this.currentUser, {
            x: 0, y: 0, w: 1920, h: 1080, hidden: false, muted: false
          })
        } else {
           myRoom.setMergeStreamLayout(item.userId, {
            x: 0, y: 0, w: 0, h: 0, hidden: false, muted: false
          })
        }
      }
    },
    stopView(){
      myRoom.stopMergeStream();
    },
    initChat(userId){
      this.toUserId = userId
      this.message = ''
      this.showChat = true
      console.log(this.allMessages)
      if(this.allMessages[userId]){
        this.chatMessage=this.allMessages[userId]
      }else{
        this.chatMessage = []
      }
      let box = this.newMessages.filter(item=>{
        return item.userId!==this.toUserId
      })
      this.newMessages=box
    },
    sendMessage(){
      myRoom.sendCustomMessage(this.message,[this.toUserId]);
      let data={
        data:this.message,
        type:'normal',
        userId:this.userId
      }
       if(this.allMessages[this.toUserId]){
         let box = this.allMessages[this.toUserId]
          box.push(data)
          this.allMessages[this.toUserId] = box
        }else{
          this.allMessages[this.toUserId]=[]
          let box = this.allMessages[this.toUserId]
          box.push(data)
          this.allMessages[this.toUserId] = box
        }
        this.updateMessage()
    },
    sendPublicMessage(){
      myRoom.sendCustomMessage(this.message);
      let data={
        data:this.message,
        type:'normal',
        userId:this.userId
      }
      
      getStudent(getUserId()).then(res=>{
          data.user_name = res.data.student_lastName+res.data.student_givenName
          this.publicMessages.push(data)
        })
    },
    removeMessage(){
      this.chatMessage=[]
    },
    computeNum(userId){
      let array = []
      array = this.newMessages.filter(item=>{
        return item.userId==userId
      })
      return array.length
    },
    computeHidden(userId){
      let array = []
      array = this.newMessages.filter(item=>{
        return item.userId==userId
      })
      if(array.length==0){
        return true
      }else{
        return false
      }
    },
    leaveRoom(){
      this.room_id = ''
      this.isInRoom=false
      this.$message('您已离开房间')
      myRoom.leaveRoom();
      this.userList=[]
      this.chatMessage=[]
      this.allMessages={}
      this.newMessages=[]
      this.publicMessages=[]
    },
    getPresentationInfo(){
      let student_id = getUserId()
      getPresentationInfo(student_id,'presentation').then(res=>{
        if(res.data.code===0){
          this.time_slot = res.data.data
        }
      })
    },
    formatDate(time){
      let date = new Date(time*1000)
      return formatDate(date,'Y-MM-dd hh:mm:ss')
    }
  }
}
</script>

<style >
select {
  width: 300px;
}


section {
  margin-bottom: 20px;
}

.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}
#remote{
  display: flex;
  flex-wrap: wrap;
}
.chatBlock{
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}
.right{
  text-align: right;
}
.left{
  text-align: left;
}
</style>
