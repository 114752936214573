<template>
  <div class="container">
    <span >效果管理</span>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>效果名称：</h4>
      <el-input v-model="cardEffectDetail.name" placeholder="请输入" style="width: 300px"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>起效的轮数：</h4>
      <el-select v-model="cardEffectDetail.effect_round" placeholder="请选择">
        <el-option
          v-for="item in effect_roundes"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>资金占比：</h4>
      <el-input v-model="cardEffectDetail.investment_weight" placeholder="请输入" type="number" style="width: 300px"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>损益表科目：</h4>
      <el-select v-model="cardEffectDetail.PnL_account" placeholder="请选择">
        <el-option
          v-for="item in PL_accountes"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>损益表影响：</h4>
      <el-select v-model="cardEffectDetail.PnL_account_type" placeholder="请选择">
        <el-option
          v-for="item in addDec"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>现金流量表科目：</h4>
      <el-select v-model="cardEffectDetail.cash_account" placeholder="请选择">
        <el-option
          v-for="item in cash_accountes"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>现金流量表影响：</h4>
      <el-select v-model="cardEffectDetail.cash_account_type" placeholder="请选择">
        <el-option
          v-for="item in addDec"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>资产负债表科目：</h4>
      <el-select v-model="cardEffectDetail.balance_account" placeholder="请选择">
        <el-option
          v-for="item in balance_accountes"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>资产负债表影响：</h4>
      <el-select v-model="cardEffectDetail.balance_account_type" placeholder="请选择">
        <el-option
          v-for="item in addDec"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>作用变量类型：</h4>
      <el-select v-model="cardEffectDetail.parameter_type" placeholder="请选择">
        <el-option
          v-for="item in parameter_types"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div v-if="this.parameter_types[0] ===this.cardEffectDetail.parameter_type" style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>作用的变量名：</h4>
      <el-cascader
        v-model="selectedFinancials"
        :options="financials"
        @change="handleParametersChanged"/>
    </div>
    <div v-if="this.parameter_types[1] ===this.cardEffectDetail.parameter_type" style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>作用的变量名：</h4>
      <el-cascader
        v-model="selectedParamMetersAbilities"
        :options="abilities"
      @change="handleParametersChanged"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>效果对标变量：</h4>
      <el-select v-model="cardEffectDetail.benchmark_parameter" placeholder="请选择">
        <el-option
          v-for="item in benchmark_parameteres"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>对标变量最优百分比：</h4>
      <el-input v-model="cardEffectDetail.benchmark_percent" placeholder="请输入" type="number" style="width: 300px"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>效果变化值：</h4>
      <el-input v-model="cardEffectDetail.delta_volume" placeholder="请输入" type="number" style="width: 300px"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>依赖能力：</h4>
      <el-cascader
        v-model="selectedAbilities"
        :options="abilities"
        :props="{multiple: true }"
        @change="handleAbilitiesChanged"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>依赖程度：</h4>
      <el-input v-model="cardEffectDetail.dependent_amplifier" placeholder="请输入" type="number" style="width: 300px"/>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>是否引入博弈：</h4>
      <el-select v-model="cardEffectDetail.boyi_effect_switch" placeholder="请选择">
        <el-option
          v-for="item in ONOROFF"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
      <h4>反作用卡片列表：</h4>
      <el-select v-model="cardEffectDetail.boyi_anti_cards" multiple placeholder="请选择" value-key="id">
        <el-option
          v-for="item in CardsBySector"
          :key="item.id"
          :label="item.label"
          :value="item.id">
        </el-option>
      </el-select>
    </div>

    <div class="flex-right" style="margin-top: 40px">
      <el-button type="primary" @click="updateEffect">更新Effect信息</el-button>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import {getCardsBySector,getCardEffect,updateCardEffect} from '../../api/VC_Arena'
export default {
    name: "CardEffect",
    data() {
      return {
        sector:'',
        selectedParamMetersAbilities:[],
        selectedFinancials:[],
        selectedAbilities:[],
        effect_id:'',
        cardId:'',
        CardsBySector:[],
        selectedCards:[],
        ONOROFF:["on","off"],
        benchmark_parameteres:["revenues","cogs","S&M_cost","management_cost","r&d_cost"],
        financials:[
          {value:"revenue_growth_rate",label:"revenue_growth_rate"},
          {value:"cogs_percent",label:"cogs_percent"},
          {value:"s&m_cost_percent",label:"s&m_cost_percent"},
          {"value": "management_cost_percent",label:"management_cost_percent"},
          {"value":"r&d_cost_percent",label:"r&d_cost_percent"},
          {"value":"tax_rate",label:"tax_rate"}],
        abilities:[
          {value:"organization",label:"organization",children:
            [{value:"org_sustainability",label:"org_sustainability"},
             {value:"org_scalebility",label:"org_scalebility"},
             {value:"work_efficiency",label:"work_efficiency"},
             {value:"skill_level",label:"skill_level"},
             {value:"talent_attractiveness",label:"talent_attractiveness"}]},
          {value:"product",label:"product",children:
            [{value:"product_diversity",label:"product_diversity"},
             {value:"product_performance",label:"product_performance"},
             {value:"product_differentiation",label:"product_differentiation"},
             {value:"product_tiers",label:"product_tiers"}]},
          {value:"Intangible Assets",label:"Intangible Assets",children:
             [{value:"brand_value",label:"brand_value"},
              {value:"competition_barrier",label:"competition_barrier"},
              {value:"innovation_ability",label:"innovation_ability"},
              {value:"industry_influence",label:"industry_influence"}]}],
        parameter_types:["financials","abilities"],
        balance_accountes:["liquid_assets","fixed_assets","short-term_debt","long-term_debt"],
        cash_accountes:["financing_cash_flow","investing_cash_flow"],
        addDec:["加","减"],
        PL_accountes:["cogs","s&m_cost","management_cost","r&d_cost","financial_cost"],
        effect_roundes:[0,1,2,3,4,5,6,7,8,9],
        cardEffectDetail:{"name":"","effect_round":"0","investment_weight":"0","PnL_account":"",
        "PnL_account_type":"",
        "cash_account":"",
        "cash_account_type":"",
        "balance_account":"",
        "balance_account_type":"",
        "parameter_type":"",
        "parameter":"",
        "benchmark_parameter":"",
        "benchmark_percent":"",
        "delta_volume":"",
        "dependents":[],
        "dependent_amplifier":"",
        "boyi_effect_switch":"",
        "boyi_anti_cards":[]}
      };
    },
    mounted() {
      this.sector = Cookies.get("sector")
      var effect_id_ = Cookies.get("effect_id")
      if(effect_id_!=undefined && effect_id_ !=""){
        this.effect_id=effect_id_
      }
      this.cardId = this.$route.params.id
      this.fetchData()
    },
    methods: {
      fetchData() {
        if(this.effect_id !=""){
          getCardEffect(this.effect_id).then((res) =>{
            var result = res.data.data
            if(result != undefined && null != result) {
              this.cardEffectDetail= result
              this.selectedAbilities=[]
              this.selectedFinancials = []
              this.selectedParamMetersAbilities=[]
              var parameter_type = this.cardEffectDetail.parameter_type
              //有可能为空
              if(parameter_type == this.parameter_types[0]){
                 var parameter =  this.cardEffectDetail.parameter
                for(var i= 0; i<this.financials.length; i++){
                  var fina = this.financials[i]
                  if(fina.value == parameter) {
                      this.selectedFinancials.push(parameter)
                      break
                  }
                }
              }else if(parameter_type == this.parameter_types[1]){
                var parameter =  this.cardEffectDetail.parameter
                for(var m=0 ;m<this.abilities.length;m++){
                  var parent =this.abilities[m].value
                  var childAbi =this.abilities[m].children
                  for(var n=0;n<childAbi.length;n++){
                    if(childAbi[n].value == parameter){
                      this.selectedParamMetersAbilities.push(parent)
                      this.selectedParamMetersAbilities.push(parameter)
                      break
                    }
                  }
                }
              }
              var dependents = this.cardEffectDetail.dependents
              if(dependents !=undefined && dependents.length>0){
                 for(var i= 0; i<dependents.length; i++){
                   var dep = dependents[i]
                  for(var j=0 ;j<this.abilities.length;j++){
                    var parent =this.abilities[j].value
                    var childAbi =this.abilities[j].children
                    for(var k=0;k<childAbi.length;k++){
                        if(childAbi[k].value == dep){
                          var child = []
                          child.push(parent)
                          child.push(dep)
                          this.selectedAbilities.push(child)
                        }
                    }
                  }
                }
              }
            }
          })
        }
        getCardsBySector(this.sector).then((res) =>{
          this.CardsBySector= res.data.data
          this.CardsBySector = this.CardsBySector.map((item, id,label) => ({ ...item, id: item._id.$id,label:item.name }))
        })
      },
      updateEffect(){
        updateCardEffect(this.effect_id,this.cardId,this.cardEffectDetail).then((res) =>{
          this.$message('更新成功')
          this.$router.go(-1)
        })
      },
      handleAbilitiesChanged(value){
        console.log("handleAbilitiesChanged: value: "+ JSON.stringify(value))
        var length =value.length
        var temps = []
        for(var i = 0; i<length;i++){
           temps.push(value[i][1])
        }
        this.cardEffectDetail.dependents = temps
      },
      handleParametersChanged(value){
        var length = value.length
        this.cardEffectDetail.parameter = value[length-1]
      },
    },
  };
</script>
<style scoped>
  .container {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
