<template>
  <div class="game">
    <!--<div style="display: flex;flex-direction: row-reverse;margin-bottom: 10px">-->
      <!--<div>-->
        <!--<el-button type="primary" @click="createNewGameCard">新建游戏配置</el-button>-->
      <!--</div>-->
    <!--</div>-->
    <div style="margin-bottom:20px">
            <h4 style="float:left">组队提示 Teaming Reminders</h4>
            <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
            
            
            <br>
            
            <br>
            <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
                <p>欢迎来到价值创造者的游戏。商业领袖的责任在于通过商业活动为社会创造价值。作为今年的额外活动项目，价值创造者遵循自愿参与的原则，由参赛选手自愿报名组队参赛。</p>

                <p>价值创造者的每支队伍最多由5人组成，5人可自由组队，不受学校、地区等限制。由于参赛人数较多，系统默认开设三个游戏区，每个区设有20支队伍。不同游戏区之间数据分开，不相互关联，可视为独立的服务器。选手可自由选择要加入的游戏区和游戏队伍。</p>

                <p>任何只要没满5人的队伍，都可以自由加入；进入队伍的成员可以选择为自己分配公司部门的角色，一个人可同时担任多个部门的负责人，负责后续游戏中的决策。每个人都可以在自己的界面更新公司角色配置，在游戏的进行过程中也可以更新配置，将部门的决策权交给团队同事代为决策（比如某位选手需要去进行演示的比赛）。</p>

                <p>游戏将于2021年2月7日上午9点开始，共分为8轮，每轮决策时间一个小时；每个队伍需要在每一轮的决策截止时间前完成当前轮的出牌，并等待服务器在截止时间时对数据进行统一计算。具体的游戏规则将于2021年2月7日游戏开始前在系统内公布。</p>

                <p>Welcome to Value Creaters. Business leaders' responsibilities are to create social value via business activities. As an extra event this year, participation in Value Creaters is not mandatory.</p>

                <p>Each team can have 5 members at most, who can be from different schools and regions. Due to the size of attendees, the system has three game zones by default, each including 20 teams. Data and competition are independent among different zones. Contestants can choose any zone to join.</p>

                <p>You can join any team with less than 5 members. A member in the team can assign department leader roles to his/herself and to other team members. One can be responsible for more than one department and the roles can be reassigned during the game in case the decision making need to be handed over in case of other engagements.</p>

                <p>The game will start at 9am on Feb 7th, having 8 rounds in total. Each round has one hour decision making time and every team needs to finish their decision making on cards to play before the deadline. The server will do calculations at the deadline for all teams. Specific gaming rules will be announced in the morning of February 7th.</p>
            </div>
        </div>
    <span >游戏列表 Game List</span>
    <el-table :data="games" border style="margin-top: 10px">
      <el-table-column prop="name" label="区域 Zone" align="center" width="200px"/>
      <el-table-column prop="total_team" label="队伍(公司)数 Total Teams (Companies)" align="center" width="160px"/>
      <el-table-column prop="member_count" label="进入人数 Total Entries" align="center" width="150px"/>
      <!-- <el-table-column prop="round" label="总轮(年)数 Total Rounds(Years)" align="center" width="150px"/> -->
      <!-- <el-table-column prop="time_per_round" label="每轮决策时间（分钟) Decision Time per Round (mins)" align="center" width="200px"/> -->
      <el-table-column prop="" label="开始时间 Start Time" align="center" width="200px">
        <template slot-scope="scope">
          <div>
            {{parseInt(scope.row.start_time) * 1000  | timeFormat}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="详情 Details" align="center" width="200px">
        <template slot-scope="scope">
          <div>
            <el-button type="primary" @click="showGameInfo(scope.row._id.$id,scope.row.name)">查看队伍 View Teams  </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getOpenGames,getFakeOpenGames} from '../../api/VC_Arena'
export default {
    data(){
      return {
        games:[],
        showSocialTip:true,
        showSocialTipTitle:'收起 Fold'
      }
    },
    mounted(){
      this.fetchData()
    },
    methods: {
      fetchData(){
        getOpenGames().then((res) =>{
          this.games = res.data.data
        })
        // getFakeOpenGames().then((res) =>{
        //   this.games = res.data.data
        // })
      },
      showGameInfo(id,name){
        this.$router.push("/student/team/manage/myteams/"+id)
      },
      dateFormat(time) {
        var date=new Date(time);
        var year=date.getFullYear();
        var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
        var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
        var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
        var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
        return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
      },
      showSocialTipClick(){
          if(this.showSocialTip == true){
             this.showSocialTip = false;
             this.showSocialTipTitle = '展开 Unfold';
          }else{
             this.showSocialTip = true;
             this.showSocialTipTitle = '收起 Fold';
          }
        }
  }
}
</script>
<style scoped>
  .game {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
