<template>
    <div style="width:100%">
        <el-form style="padding:30px">
            <el-form-item label="信息提示 Information Hint">
                <el-input v-model="post.title" placeholder="例：我推荐一部我最喜欢的剧 E.g. I recommend my favorite drama series"></el-input>
            </el-form-item> 
            <el-form-item label="信息(仅交换后可见) Information (only visible after exchange)"
                <el-input type="textarea" :rows="5"  v-model="post.answer" placeholder="例: 这部剧是《纸牌屋》，因为.... E.g. It is Hourse of Cards, because...."></el-input>
            </el-form-item>
        </el-form>
        <el-button @click="updatePost" style="margin-left:30px" type="primary">提交 Post</el-button>
        <el-button @click="$router.back(-1)" >返回 Return</el-button>
    </div>
</template>

<script>
import {updatePost,getPost} from '../../api/yousha'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            id:'',
            post:{}
        }
    },
    mounted(){
        this.id = this.$route.query.id
        if(this.id!==''){
            this.fetchData()
        }
    },
    methods:{
        fetchData(){
            getPost(this.id).then(res=>{
                if(res.data.code==0){
                    this.post = res.data.data
                }
            })
        },
        updatePost(){


            if(this.post.title == '' || this.post.title == null){
                this.$message('提示不能为空 Hint cannot be blank')
                return
            }

            if(this.post.answer == '' || this.post.answer == null){
                this.$message('信息不能为空 Information cannot be blank')
                return
            }

            let user_id = getUserId()
            updatePost(this.id,user_id,this.post.title,this.post.answer).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>
