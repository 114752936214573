<template>
  <div class="container">

    
    <br>

      <el-form ref="form" :model="myGameInfo" label-position="left" label-width="300px" style="margin-top: 20px ;width:500px;border:2px solid lightGray;border-radius:20px;padding:30px">

<!--       //Organization
          //org_sustainability, org_scalebility, work_efficiency, skill_level, talent_attractiveness, 
        //Product
          //product_diversity, product_performance, product_differentiation, product_tiers, 
        //Intangible Assets
          //brand_value, competition_barrier, innovation_ability, industry_influence -->
        <el-form-item class="competency_cell" style="margin-left:0px;border-bottom:1px solid lightGray;font-weight:bold" label="组织竞争力 Organization Competency">
          <span>  </span>
        </el-form-item>
        <el-form-item class="competency_cell" label="组织延续性 Organization Sustainability">
          <span>{{loadData['org_sustainability']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell"  label="组织扩展性 Organization Scalebility">
          <span>{{loadData['org_scalebility']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="协作效率 Work Efficiency">
          <span>{{loadData['work_efficiency']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="技能水平 Skill Level">
          <span>{{loadData['skill_level']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="人才吸引力 Talent Attractiveness">
          <span>{{loadData['talent_attractiveness']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" style="margin-left:0px;border-bottom:1px solid lightGray;font-weight:bold" label="产品竞争力 Product Competency">
          <span>  </span>
        </el-form-item>
        <el-form-item class="competency_cell" label="产品多元化 Product Diversity">
          <span>{{loadData['product_diversity']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="产品性能 Product Performance">
          <span>{{loadData['product_performance']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="产品差异化 Product Differentiation">
          <span>{{loadData['product_differentiation']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="产品分层 Product Tiers">
          <span>{{loadData['product_tiers']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" style="margin-left:0px;border-bottom:1px solid lightGray;font-weight:bold" label="无形资产 Intangible Assets">
          <span>  </span>
        </el-form-item>
        <el-form-item class="competency_cell" label="品牌价值 Brand Value">
          <span>{{loadData['brand_value']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="竞争壁垒 Competition Barrier">
          <span>{{loadData['competition_barrier']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="创新能力 Innovation Ability">
          <span>{{loadData['innovation_ability']}}</span>
        </el-form-item>
        <el-form-item class="competency_cell" label="行业影响力 Industry Influence">
          <span>{{loadData['industry_influence']}}</span>
        </el-form-item>
      </el-form>
    
  </div>
</template>

<script>
import {getOtherTeamData} from '../api/VC_Arena'

export default {
    name: "PowerInfo",
    data() {
      return {
          tableData: [{
            contentInfo: '2016-05-02',
            name: '444',
          }, {
            contentInfo: '2016-05-04',
            name: '333',
          }, {
            contentInfo: '2016-05-01',
            name: '222',
          }, {
            contentInfo: '2016-05-03',
            name: '111',
          }]
      };
    },
    props: {
      loadData:{},
      tableTitle:String,
      dataType:Number,
      gameId:String,
      teamId:String,
      currentRound:Number
    },
    mounted() {
      // const vm = this
    },
    components: {
    
    },
    methods:{
      doChoseCard(){
        this.$emit('callback', 'doChoseCard')
      },
      showOtherTeamsInfo(other_game_id){
        getOtherTeamData(other_game_id,this.currentRound).then((res)=>{

        })
      },
      getCardsInvestment(id){
          return this.myTeamCards['cards_investment'][id]
      },
    },
    watch: {
  
      loadData: function () {
      
          console.log(loadData,'2222')
          alert(111);
      }
    }
  };
</script>


<style lang="scss" scoped>

.competency_cell{

  /*background-color:lightGray;*/
  margin:10px;
  margin-left:20px;
}

.sub{

}

</style>
