<template>
  <div class="container">
    <span v-if="dataType==0">Year 0 为初始值</span>

    <div v-if="dataType==0">




       <div v-if="myNotCurrentRoundTeamData&&null!=myNotCurrentRoundTeamData" style="margin-top: 20px">
        <div >
          <FinancialInfo :loadData="myNotCurrentRoundTeamData"></FinancialInfo>
        </div>
        <div v-if="myNotCurrentRoundTeamData.abilities &&null!=myNotCurrentRoundTeamData.abilities">
                        <PowerInfo :loadData="myNotCurrentRoundTeamData.abilities" ></PowerInfo>
                    </div>
       </div>
       <div v-if="!myNotCurrentRoundTeamData || null==myNotCurrentRoundTeamData" style="margin-top: 20px">
        <div style="text-align:center">
          财务信息暂时无法提供 Financial Information are not available temporarily
        </div>

            
       </div>

    </div>
    <span v-if="dataType==1">暂未开放 This Round is not open yet</span>
    <div v-if="dataType==2">
      
      <div style="margin:20px 0">以下为每年决策操作区域，请进一步选择子页签查看 <br>Below are decision making panel for each year. Please further click each sub-tab for more details</div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="环境信息 Environment Info" name="first">
                  <!-- <span>本年市场环境卡 Market Enviroment Cards of the Year</span> -->

                  <!-- <div v-if="myTeamCards &&null!=myTeamCards"> -->
                    <div >

                      <!-- <AppearCards :picImgs="myTeamCards.card_info"></AppearCards> -->
                      <div style="font-size:12px">本年度拥有以下新增环境因素 There are new external factors below this years</div>
                      <div v-for="(item,index) in roundEcoCards.cards_info" :key="item">
                        <div style="margin-top: 20px;">
                          <div style="width:80%;background-color:#eeeeee;padding:20px;border-radius:10px;">
                            <!-- <img :src="'http://' + item.card_cover_address" class="avatar" style="width:250px"> -->
                            
                            <div style="font-size:16px;">{{item.name}} <span style="font-size: 12px;margin-left:20px;"><i>类型 Type: {{item.term}} Term</i></span>

                              <el-button style="margin-left:30px;" type="primary" @click="showEcoCardDetails(index)">详情 Details</el-button>

                            </div>
                            
                            
                            
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  
        </el-tab-pane>

        <el-tab-pane label="本年出牌 Cards Play" name="second">
                    <div>
                      <!-- <span>公司本年出牌 Company Card Play of the Year</span> -->
                      <div v-if="myTeamCards &&null!=myTeamCards">
                        <div style="display: flex;flex-direction: row">

                          <AppearCards :picImgs="myTeamCards.card_info" :cards_investment="myTeamCards.cards_investment"></AppearCards>

                          <!-- <div v-for="(item,index) in myTeamCards.card_info">
                            <div style="margin-top: 20px;display: flex;flex-direction: row;border:1px solid gray">
                              <div style="width: 300px">
                                <img :src="'http://' + item.card_cover_address" class="avatar" style="width:250px">
                                <span>卡片名字：</span>
                                <span>{{item.name}}</span>
                                <div style="margin-left: 10px">
                                  <span>投入金额 Amount of Investment：</span>
                                  <span>{{getCardsInvestment(item['_id']['$id'])}}</span>
                                </div>  
                              </div>
                              
                            </div>
                          </div> -->
                        </div>
                        
                       
                      </div>
                      <div v-if="(myTeamCards ==null || myTeamCards ==undefined) && currentRound == playRound">
                        <div style="margin:30px;border:2px lightGray dotted;padding:30px;border-radius:30px">
                        <div style="width:100%;text-align:center">本轮贵公司还未整体出牌 Your company has not played cards this year</div>
                          <div style="text-align:center;margin:20px 0px"><el-button type="primary" @click="doChoseCard">出牌 Play Cards</el-button></div>
                        </div>
                      </div>
                    </div>
        </el-tab-pane>

        <el-tab-pane label="财务表现 Financials" name="third">
                    <!-- <span>公司本年度财务表现 Company Financial Performance of hte Year</span> -->
                    
                     <div v-if="myNotCurrentRoundTeamData&&null!=myNotCurrentRoundTeamData && myNotCurrentRoundTeamData.revenues" style="margin-top: 20px">
                      <div style="">
                        <FinancialInfo :loadData="myNotCurrentRoundTeamData"></FinancialInfo>
                      </div>
                          
                     </div>

                     <div v-if="!myNotCurrentRoundTeamData || null==myNotCurrentRoundTeamData || !myNotCurrentRoundTeamData.revenues" style="margin-top: 20px">
                      <div style="text-align:center">
                        本轮财务信息暂时无法提供 Financial Information this round are not available temporarily
                      </div>
                          
                     </div>
        </el-tab-pane>

        <el-tab-pane label="公司竞争力数据 Company Competency Data" name="fourth">
                     <!-- <span>公司本年度竞争力数据 Company Competency Data of hte Year</span> -->
                    <div v-if="myNotCurrentRoundTeamData.abilities && myNotCurrentRoundTeamData.abilities != null ">
                        <PowerInfo v-if="myNotCurrentRoundTeamData.abilities && myNotCurrentRoundTeamData.abilities != null " :loadData="myNotCurrentRoundTeamData.abilities" ></PowerInfo>
                    </div>

                    <div v-if="!myNotCurrentRoundTeamData.abilities || null==myNotCurrentRoundTeamData.abilities " style="margin-top: 20px">
                      <div style="text-align:center">
                        本轮竞争力信息暂时还无法提供 Financial Information this round are not available temporarily
                      </div>
                          
                     </div>
        </el-tab-pane>
        <el-tab-pane label="竞争对手数据 Competitive Data" name="fifth">
                <!-- <span>竞争对手列表 Competitor List</span> -->

                <el-table :data="myOtherTeams" border style="margin-top: 20px" v-if="currentRound < playRound &&myOtherTeams&&null!=myOtherTeams">
                  <el-table-column prop="internal_code" label="竞争对手编号 Team Code" align="center" width="150px"/>
                  <el-table-column prop="team_name" label="公司名称 Company Name" align="center" width="400px"/>
                  
                  <el-table-column prop="" label="操作 Actions" align="center" v-if="currentRound < playRound &&myOtherTeams&&null!=myOtherTeams">
                    <template slot-scope="scope">
                      <div >
                        <div style="display: flex;flex-direction: row;text-align:center">
                          
                           <!-- <el-button slot="reference">查看成员 View Members</el-button>
                          
                          <el-button v-if="joinedTeams.length == 0" type="primary" @click="_joinTeam(scope.row._id.$id)" margin-left="10px">加入 Join
                          </el-button> -->
                          <el-button type="primary" @click="showOtherTeamCards(scope.row._id.$id)" style="margin-left: 20px">查看出牌 View Cards Play</el-button>
                          <el-button type="primary" @click="showOtherTeasmCompetencies(scope.row._id.$id)" style="margin-left: 20px">查看竞争力 View Competencies</el-button>
                        </div>
                        
                      </div>
                    </template>
                  </el-table-column>
                </el-table>


                <!-- <div style="margin-top: 20px; border:1px solid gray" v-if="currentRound < playRound &&myOtherTeams&&null!=myOtherTeams">
                  
                  <div v-for="(item ,index) in myOtherTeams">
                    <div style="margin-top: 10px">
                      <span>竞争对手名字 Competitor Name：</span>
                      <span>{{item['team_name']}}</span>
                      
                    </div>
                  </div>
                </div> -->
                <div v-if="currentRound == playRound" style="text-align:center;padding:50px;border:2px dotted lightGray;border-radius:20px">
                  <p>本轮目前正在决策中，未有可用信息 <br>The decisions on the current round are still being made. No Information Available</p>
                </div>
        </el-tab-pane>
    </el-tabs>
      
        
      <div>
        
       

        
      </div>
       <el-dialog :visible.sync="showEcoCardDetail">
          <div style="display: flex;flex-direction: column">
            <span style="margin-bottom:20px"><b>环境设置信息 Environment Setting Info</b></span>
            <span >{{viewEcoCard.intro}}</span>
            <span style="margin-top:10px;">对应市场增长率 with Market Growth Rate: {{viewEcoCard.market_growth + '%'}}</span>
            <span style="margin-top:10px;">影响卡片数量 # of cards impacted: {{viewEcoCard.impact_card_count }}</span>
          </div>
          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" @click="hideEcoCardDetail">知道了 Got it</el-button>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="viewTeamCompetenciesSwitch">
          <div style="display: flex;flex-direction: column">
            <span style="margin-bottom:20px"><b>对手公司竞争力信息 Rival's Competency Info</b></span>
            

              <div v-if="viewTeamCompetencies.abilities && viewTeamCompetencies.abilities != null">
                        <PowerInfo :loadData="viewTeamCompetencies.abilities" ></PowerInfo>
                    </div>

                   <!--  <div >
                        <PowerInfo :loadData="loadData" ></PowerInfo>
                    </div> -->
          </div>
          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" @click="hideTeamCompetencies">知道了 Got it</el-button>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="viewTeamCardChosenSwitch">
          <div style="display: flex;flex-direction: column">
            <span style="margin-bottom:20px"><b>对手公司出牌信息信息 Rival Card Play Info</b></span>
            


                <AppearCards :picImgs="viewTeamCardChosen.card_info" :cards_investment="viewTeamCardChosen.cards_investment"></AppearCards>
              <!--   <AppearCards :picImgs="myTeamCards.card_info" :cards_investment="myTeamCards.cards_investment"></AppearCards> -->

          </div>
          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" @click="hideTeamCardChosen">知道了 Got it</el-button>
          </div>
        </el-dialog>
      
    </div>
  </div>
</template>

<script>
import {getOtherTeamData,getRoundEcoCards,getTeamCards} from '../api/VC_Arena'
import PowerInfo from '../components/PowerInfo'
import AppearCards from '../components/AppearCards'
import FinancialInfo from '../components/FinancialInfo'


export default {
    name: "GameRoundDetail",
    data() {
      return {
        loadData:{
          "111":"aaa",
          "222":"qaa",
          "333":"waa",
          "444":"raa",
        },
        viewEcoCard:{},
        showEcoCardDetail:false,
        viewTeamCardChosen:{},
        viewTeamCompetencies:{},
        viewTeamCompetenciesSwitch:false,
        viewTeamCardChosenSwitch:false,

        // ecoCards:[]
      };
    },
    components: {
      PowerInfo,
      AppearCards,
      FinancialInfo
    },
    props: {
      myOtherTeams: {
        type: Array,
        default: () => {
          return [];
        },
        required: true,
      },
      myTeamCards: {
        type: Object,
        default: () => {
          return null;
        },
        required: true,
      },
      roundEcoCards: {
        type: Object,
        default: () => {
          return null;
        },
        required: true,
      },
      myNotCurrentRoundTeamData: {
        type: Object,
        default: () => {
          return null;
        },
        required: true,
      },
      dataType:Number,
      gameId:String,
      teamId:String,
      currentRound:Number,
      playRound:Number
    },
    mounted() {
      // const vm = this
      // fetchRoundEcoCards()
    },
  methods:{
    handleClick(tab, event) {
        console.log(tab, event);
      },
    doChoseCard(){
      this.$emit('callback', 'doChoseCard')
    },
    showOtherTeamsInfo(other_game_id){
      getOtherTeamData(other_game_id,this.currentRound).then((res)=>{

      })
    },
    showEcoCardDetails(index){
      this.viewEcoCard = this.roundEcoCards.cards_info[index]

      this.showEcoCardDetail = true;
    },
    hideEcoCardDetail(){
      // this.viewEcoCard = this.roundEcoCards.cards_info[index]

      this.showEcoCardDetail = false;
    },
    fetchRoundEcoCards(){
      getRoundEcoCards(this.gameId,this.currentRound).then((res)=>{
        this.ecoCards = res.data.data
      })
    },
    getCardsInvestment(id){
        return this.myTeamCards['cards_investment'][id]
    },
    showSocialTipClick(){
      if(this.showSocialTip == true){
         this.showSocialTip = false;
         this.showSocialTipTitle = '展开 Unfold';
      }else{
         this.showSocialTip = true;
         this.showSocialTipTitle = '收起 Fold';
      }
    },
    showOtherTeamCards(id){
      getTeamCards(id,this.currentRound).then((res)=>{
        if (res.data.code == 0) {
          this.viewTeamCardChosen = res.data.data
          this.viewTeamCardChosenSwitch = true
        }else{
          this.$message('该公司本轮未出牌 No Cards Played By this Company at this round')
        }
      })
    },
    showOtherTeasmCompetencies(id){
      getOtherTeamData(id,this.currentRound).then((res)=>{

        if (res.data.code == 0) {
          this.viewTeamCompetencies = res.data.data  
          this.viewTeamCompetenciesSwitch = true
        }else{
          this.$message('因某种原因无法提供 Not available for some reason')
        }
        
      })
    },
    hideTeamCompetencies(){
      this.viewTeamCompetenciesSwitch = false;
    },
    hideTeamCardChosen(){
      this.viewTeamCardChosenSwitch = false;
    }

  },
    watch: {
      myTeamCards (newValue, oldValue) {
          var cards = newValue
        if(null == cards){

        }
      },
    }

  };
</script>
<style lang="scss" scoped>

</style>
