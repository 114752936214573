<template>
  <div class="game">
    <div style="display: flex;flex-direction: column;margin-bottom: 10px">
      <div>
        <span></span>
        <div v-if="joinedTeams.length<=0" style="margin-top: 20px">
          <span >{{error_my_team}}</span>
        </div>
        <div>
          <span >置顶：你已加入的队伍 Top: The Team You Joined</span>
          <el-table :data="joinedTeams" border style="margin-top: 20px">
          <el-table-column prop="game_info.name" label="区域 Zone" align="center"/>
            <el-table-column prop="internal_code" label="队伍编号 Team Code" align="center"/>
            <el-table-column prop="" label="成员数量 Team Size" align="center" >
              <template slot-scope="scope">
                <div>
                  <span>{{getUserIdCounts(scope.row)}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作 Actions" align="center" >
              <template slot-scope="scope">
                <div v-if="gameId == scope.row.game_id">
                  <el-button type="primary" @click="showSelectRolesDialog(scope.row._id.$id)" style="background:orange;border:orange">角色 Roles</el-button>
                  <el-button type="primary" @click="confirmQuitTeam(scope.row._id.$id)" style="background:red;border:red">退队 Quit</el-button>
                  
                </div>
                <div v-if="gameId != scope.row.game_id">
                  <span style="color:red"><strong>你的团队不在此区域! Your joined team is NOT in this zone!</strong></span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:20px;">
          <span >本游戏区所有队伍列表 Team List of this Game Zone</span>
          <el-table :data="allTeams" border style="margin-top: 20px">
            <el-table-column prop="game_name" label="所属游戏区 Game Zone" align="center"/>
            <el-table-column prop="internal_code" label="队伍编号 Team Code" align="center" width="200px"/>
            <el-table-column prop="" label="成员数量 Team Size" align="center" width="200px">
              <template slot-scope="scope">
                <div>
                  <span>{{getUserIdCounts(scope.row)}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作 Actions" align="center">
              <template slot-scope="scope">
                <div >
                  <div style="display: flex;flex-direction: row;">
                    <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      :content=showTeamMembers(scope.row)>
                      <el-button slot="reference">查看成员 View Members</el-button>
                    </el-popover>
                    <el-button v-if="joinedTeams.length == 0" type="primary" @click="_joinTeam(scope.row._id.$id,scope.row)" margin-left="10px">加入 Join</el-button>
                  </div>
                  
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogShowRoles">
      <div style="display: flex;flex-direction: column">
        <span >请为公司每个部门分配团队成员 <br>Please assign team members as department leaders of the company.</span>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <div style="width: 150px">
            <span >人力部 HR</span>
          </div>
          <el-select v-model="selectedRoles.HR"  placeholder="请选择">
            <el-option
              v-for="item in teamMemberList"
              :key="item.student_id"
              :label="item.name1"
              :value="item.student_id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <div style="width: 150px">
            <span >财务部 Finance</span>
          </div>
          <el-select v-model="selectedRoles.Finance"  placeholder="请选择">
            <el-option
              v-for="item in teamMemberList"
              :key="item.student_id"
              :label="item.name1"
              :value="item.student_id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <div style="width: 150px">
            <span >市场营销部 Marketing</span>
          </div>
          <el-select v-model="selectedRoles.Marketing"  placeholder="请选择">
            <el-option
              v-for="item in teamMemberList"
              :key="item.student_id"
              :label="item.name1"
              :value="item.student_id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <div style="width: 150px">
            <span >研发部 RnD</span>
          </div>
          <el-select v-model="selectedRoles.RnD"  placeholder="请选择">
            <el-option
              v-for="item in teamMemberList"
              :key="item.student_id"
              :label="item.name1"
              :value="item.student_id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <div style="width: 150px">
            <span >运营部 Operation</span>
          </div>
          <el-select v-model="selectedRoles.Operation"  placeholder="请选择">
            <el-option
              v-for="item in teamMemberList"
              :key="item.student_id"
              :label="item.name1"
              :value="item.student_id">
            </el-option>
          </el-select>
        </div>
        <!-- <div style="display: flex;flex-direction: row;margin-top: 15px;align-items: center">
          <div style="width: 80px">
            <span >CEO</span>
          </div>
          <el-select v-model="selectedRoles.CEO"  placeholder="请选择">
            <el-option
              v-for="item in teamMemberList"
              :key="item.student_id"
              :label="item.name1"
              :value="item.student_id">
            </el-option>
          </el-select>
        </div> -->
        <div class="flex-right" style="margin-top: 40px">
          <el-button type="primary" @click="doAssignTeamMemberRoles">更改角色分配 Update Role Assignments</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getGame,getTeamsByGame,getMyTeam,joinTeam,quitTeam,getTeamMemberList,assignTeamMemberRoles} from '../../api/VC_Arena'
import {getUserName,getUserId} from '../../utils/store'
export default {
    data(){
      return {
        selectedTeamId:'',
        selectedRoles:{"HR":"","Finance":"","Marketing":"","RnD":"","Operation":"","CEO":""},
        dialogShowRoles:false,
        joinedTeams:[],
        allTeams:[],
        gameId:"",
        error_my_team:"",
        teamMemberList:[],
        game_name:''
      }
    },
    mounted(){
      this.gameId = this.$route.params.id
      this.fetchData()
    },
    methods: {
      fetchData(){
        let user_id = getUserId()
        getGame(this.gameId).then((res) => {

          this.game_name = res.data.data.name

            getMyTeam(user_id,this.gameId).then((res) =>{
              var result  = res.data
              this.joinedTeams= []
              if(result.code ==0){
                
                if(result.data !=undefined && result.data!=null){
                  this.joinedTeams.push(result.data)
                }
              }else{
                this.error_my_team = result.msg
              }
              this.getAllTeams()
            })

        }

          )
        
      },
      getAllTeams(){
        getTeamsByGame(this.gameId).then((res) =>{
          var result  = res.data
          if(result.code ==0){
            var teams= result.data
            this.allTeams = []
            if(this.joinedTeams == undefined || null == this.joinedTeams || this.joinedTeams.length<=0){
              for(var i=0;i<teams.length;i++){
                var team = teams[i]
                team["join"] = false
                team['game_name'] = this.game_name
                this.allTeams.push(team)
              }
            }else{
              if(teams !=undefined && teams.length >0){
                for(var i=0;i<teams.length;i++){
                  var team = teams[i]
                  team["join"] = false
                  var id= team["_id"]["$id"]
                  var joinedTeam= this.joinedTeams[0]
                  if(joinedTeam["_id"]["$id"]==id){
                    team["join"] = true
                  }
                  team['game_name'] = this.game_name
                  this.allTeams.push(team)
                }
              }
            }
          }
        })
      },
      showSelectRolesDialog(id){
        this.teamMemberList=[]
        this.selectedTeamId= id
        getTeamMemberList(id).then((res) => {
          this.teamMemberList = res.data.data
          this.teamMemberList = this.teamMemberList.map((item, name1) => ({ ...item, name1: item.account_name+": "+ item.name}))
          this.dialogShowRoles=true
        })
      },


      doAssignTeamMemberRoles(){
        var newRoles= {}
        if(this.selectedRoles.HR!=""){
          newRoles["HR"]=this.selectedRoles.HR
        }
        if(this.selectedRoles.Finance!=""){
          newRoles["Finance"]=this.selectedRoles.Finance
        }
        if(this.selectedRoles.Marketing!=""){
          newRoles["Marketing"]=this.selectedRoles.Marketing
        }
        if(this.selectedRoles.RnD!=""){
          newRoles["RnD"]=this.selectedRoles.RnD
        }
        if(this.selectedRoles.Operation!=""){
          newRoles["Operation"]=this.selectedRoles.Operation
        }
        if(this.selectedRoles.CEO!=""){
          newRoles["CEO"]=this.selectedRoles.CEO
        }
        this.dialogShowRoles=false
        assignTeamMemberRoles(this.selectedTeamId,this.selectedRoles).then((res)=>{
          var result  = res.data
          if(result.status =="success"){
            this.$message({
              message:'角色分配成功 Roles assigned successfully!',
              type:'success'
            })
          }else{
            this.$message(result.msg)
          }
        })
      },
      confirmQuitTeam(id){
        quitTeam(getUserId(),this.gameId,id).then((res) => {
          var result  = res.data
          if(result.status =="success"){
            this.$message({
              message:'退队成功 Team Quit Success!',
              type:'success'
            })
            this.fetchData()
          }else{
            this.$message(result.msg)
          }
        })
      },
      _joinTeam(id,row){

        if (row.team_members.length<5) {
          joinTeam(getUserId(),this.gameId,id).then((res) => {
            var result  = res.data
            if(result.status =="success"){
              this.$message({
                message:'加入队伍成功 Join Team Success!',
                type:'success'
              })
              this.fetchData()
            }else{
              this.$message(result.msg)
            }
          })

        }else{
          this.$message({
                message:'队伍人数已满 Team already full!',
                type:'error'
              })
        }
        
      },
      showTeamMembers(row){
        var team_members = row.team_members
        if(team_members == undefined ||team_members == null || team_members.length<=0){
            return ""
        }
        var sb = ""
        var length = team_members.length
        for(var i=0;i<length;i++){
          sb= sb +team_members[i]["account_name"]+": "+team_members[i]["name"]
          if(i !=(length-1)){
              sb =sb+","
            }
        }
        return sb
      },
      getUserIdCounts(row){
        var user_ids = row.user_ids
          if(user_ids == undefined ||user_ids == null){
              return 0
          }
          var count = 0
        for(let key in user_ids){
          count++
        }
          return count + '/5';
      },
      _getTeamMemberList(id){
        getTeamMemberList(id).then((res) =>{
          var result  = res.data
          if(result.code ==0){

          }else{

          }
        })
      },
      dateFormat(time) {
        var date=new Date(time);
        var year=date.getFullYear();
        var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
        var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
        var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
        var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
        return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
      },
  }
}
</script>
<style scoped>
  .game {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
