<template>
  <div id="app" style="width: 100%; margin: 0;padding: 0">
    <header v-if="canShowHeader" class="head">
      <el-dropdown>
        <i class="el-icon-setting" style="margin-right: 15px"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span style="margin-right: 20px">{{userName}}</span>
    </header>
    <div>
      <el-container>
        <el-aside v-if="showStudentAside" width="200px" class="aside">
          <el-row class="tac">
            <el-col>
              <el-menu :router="true">
                    <el-menu-item index="1" :route="{path:'/student/zhibo'}">
                      <span>仪式 Ceremonies</span>
                    </el-menu-item>
                    <!-- <el-menu-item index="2" :route="{path:'/student/presentationlist'}">
                      <span>演示室 Prez Rooms</span>
                  </el-menu-item>
                  <el-menu-item index="5" :route="{path:'/student/exam'}">
                      <span>考试室 Test Rooms</span>
                  </el-menu-item>
                  <el-submenu index="3">
                    <template slot="title"><span>社交 Future Friends</span></template>
                    <el-menu-item index="3-1" :route="{path:'/student/posts'}">
                      <span>广场 Square</span>
                    </el-menu-item>


                    <el-menu-item v-if="userType == 'student' || userType == 'alumni'" index="3-2" :route="{path:'/student/mypost'}">
                      <span>我的信息 My Posts{{stats.my_post? ' (' + stats.my_post +')': ''}}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userType == 'student' || userType == 'alumni'"  index="3-3" :route="{path:'/student/myrequests'}">
                      <span>我的请求 My Rqsts{{stats.my_requests? ' (' + stats.my_requests +')': ''}}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userType == 'student' || userType == 'alumni'"  index="3-4" :route="{path:'/student/myreceiverequests'}">
                      <span>交换申请 Rqsts to me{{stats.receive_requests? ' (' + stats.receive_requests +')': ''}}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userType == 'student' || userType == 'alumni'"  index="3-5" :route="{path:'/student/message'}">
                      <span>消息 Messages{{stats.messages? ' (' + stats.messages +')': ''}}</span>
                    </el-menu-item>
                  </el-submenu>
                    
                  <el-submenu index="4">
                    <template slot="title"><span>价值创造者 Value Creaters</span></template>
                    <el-menu-item index="4-1" :route="{path:'/student/team/manage'}">
                      <span>组队管理 Teams</span>
                    </el-menu-item>
                    <el-menu-item index="4-2" :route="{path:'/student/operate/manage'}">
                      <span>经营管理 Operate</span>
                    </el-menu-item>
                  </el-submenu> -->
                    <!-- <el-menu-item index="5" :route="{path:'/student/lecturelist'}">
                        <span>讲座</span>
                    </el-menu-item> -->
                  
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-aside v-if="showEducatorAside" width="200px" class="aside">
          <el-row class="tac" style="background-color: #D3DCE6">
            <el-col>
              <el-menu :router="true" default-active="1">
                <el-menu-item index="1"  :route="{path:'/educator/screenlist'}">
                  <span>讲座</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-aside v-if="showEmployeeAside" width="200px" class="aside">
          <el-row class="tac" style="background-color: #D3DCE6">
            <el-col>
              <el-menu :router="true" default-active="1">
                <el-menu-item index="1"  :route="{path:'/employee/screenlist'}">
                  <span>讲座</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-aside v-if="showGameAside" width="200px" class="aside">
          <el-row class="tac" style="background-color: #D3DCE6">
            <el-col>
              <el-menu :router="true" default-active="1">
                <el-menu-item index="1"  :route="{path:'/game'}">
                  <span>游戏管理</span>
                </el-menu-item>
                <el-menu-item index="2" :route="{path:'/game/card'}">
                  <span>卡片管理</span>
                </el-menu-item>
                <el-menu-item index="3"  :route="{path:'/game/ecocard'}">
                  <span>环境卡管理</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-aside v-if="showAdminAside" width="200px" class="aside">
          <el-row class="tac" style="background-color: #D3DCE6">
            <el-col>
              <el-menu :router="true" default-active="1">
                <el-menu-item index="1" :route="{path:'/admin/roomManage'}">
                  <span>房间管理</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-main>
          <el-container>
            <keep-alive include="record">
              <router-view></router-view>
            </keep-alive>
          </el-container>
        </el-main>
      </el-container>
    </div>
  </div>
</template>
<script>
import { removeUserId, removeUserName, getUserName,getUserId,getUserType} from './utils/store'
import { getMyStats} from './api/yousha.js'
export default {
  name: 'app',
  data () {
    return {
      userId: '',
      userName: '',
      userType:'',
      type:'',
      currentMenuIndex: 0,
      menuItemList: [],
      menuItemListSong: [],
      stats:{

        'total_posts': 0,
        'my_posts': 0,
        'my_requests' : 0,
        'receive_requests':0,
        'messages':0

      }
    }
  },
  computed: {
    canShowHeader () {
      var show = this.$route.path.search('login') > 0 || this.$route.path.search('lecturescreen') > 0 || this.$route.path.search('paint') > 0
      return !show
    },
    //TODO:应该根据登录用户类型来判断处理
    showStudentAside () {
      var show = this.$route.path.search('student') > 0 && this.$route.path.search('lecturescreen') < 0
      return show
    },
    showEducatorAside () {
      var show = this.$route.path.search('educator') > 0 && this.$route.path.search('lecturescreen') < 0 && this.$route.path.search('paint') < 0
      return show
    },
    showEmployeeAside () {
      var show = this.$route.path.search('employee') > 0 && this.$route.path.search('lecturescreen') < 0 
      return show
    },
    //TODO:应该根据登录用户类型来判断处理
    showGameAside() {
      var show = this.$route.path.search('game') > 0
      return show
    },
    showAdminAside () {
      var show = this.$route.path.search('admin') > 0
      return show
    },
  },
  watch:{
    $route(){
      this.userName = getUserName()
    }
  },
  created(){

  },
  mounted () {
    this.userName = getUserName()
    this.userId = getUserId()
    this.userType = getUserType()
    console.log(this.userType)
    if(this.userId != null){
      // this.getMyStats()  
    }
    
    
  },
  methods: {
    logout () {
      removeUserId()
      removeUserName()
      location.reload()
    },
    getMyStats(){
      getMyStats(this.userId).then(res=>{
        this.stats = res.data.data          
      })
    }
  },
  
}
</script>
<style scoped>
  .head{
    position: relative;
    text-align: right;
    font-size: 12px;
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  .el-main {
    margin: 0px;
    padding: 0px;
  }
  .el-container {
    margin: 0px;
    padding: 0px;
  }
</style>
