<template>
    <div style="width:100%;color:#606266">
        <div class="users">
            <el-button type="warning" @click="leaveRoom">离开房间</el-button>
            <div id="student" class="smallWindow"></div>
        </div>

    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import { createPresentationRoomToken, getPresentationInfo,getStudent } from '../api/keneng'
import {getUserId} from '../utils/store'
import {formatDate} from '../utils/date'
const myRoom = new QNRTC.TrackModeSession()

console.log('current version is', QNRTC.version)
export default {

  data () {
    return {
      audioDevice: '',
      videoDevice: '',
      audioDevices: [],
      videoDevices: [],
      roomToken: '',
      userList: [],
      currentUser: '',
      lastUser: '',
      showInfo: false,
      showToken: false,
      room: '',
      userId: '',
      userToken: '',
      room_list: [],
      type: '',
      currentRoom: '',
      message:'',
      toUserId:'',
      showChat:false,
      chatMessage:[],
      allMessages:{},
      newMessages:[],
      isInRoom:false,
      room_id:'',
      time_slot:[],
      publicMessages:[],
      showPublic:[],
      roomId:'',
      screenTrackId:'',
      screenTrack:'',
      remoteTrackId:'',
      tracks:[],
      application_id:'',
      event_name:'',
      pageNum:1,
      pageTotalNum:0,
      url:''
    }
  },
  mounted () {
    // this.roomId = this.$route.query.roomId
    // this.getPresentationInfo()
    this.roomId =this.$route.query.roomId
    window.addEventListener( 'beforeunload', e => this.leaveRoom() );
    this.joinRoom()
  },
  destroyed(){
      myRoom.leaveRoom();
      for (const track of this.tracks) {
        track.release();
      }
  },
  methods: {
    addDeviceToSelect (deviceInfo) {
      let audioDevices = []
      let videoDevices = []
      deviceInfo.forEach(info => {
        if (info.kind === 'audioinput') {
          audioDevices.push(info)
        } else if (info.kind === 'videoinput') {
          videoDevices.push(info)
        }
      })
      this.audioDevices = audioDevices
      this.videoDevices = videoDevices
    },
    async joinRoom () {
      // 从输入框中获取 roomToken

      this.showInfo=false

      let roomToken

      const result = await this.createRoomToken()
      if(result.code==0){
        roomToken = result.data.token
        this.room_id = result.data.room_id
      }else{
        this.$message(result.msg)
        return;
      }

      try {
        // 加入房间
        await myRoom.joinRoomWithToken(roomToken)

        // 如果加入房间后发现房间已经有人发布，就订阅他
      
        this.subscribeTracks(myRoom.trackInfoList)


      } catch (e) {
        console.error(e)
        this.isInRoom = false
        this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
        return
      }


        myRoom.on("track-add", (tracks) => {
            this.subscribeTracks(tracks);       
        });

        myRoom.on("user-leave", user => {
            let thisNode=document.getElementById(user.userId);
            thisNode.parentElement.removeChild(thisNode);
        })


      


    },
    updateMessage(){
      this.chatMessage = this.allMessages[this.toUserId]
    },

    async publishScreen () {
      if(this.screenTrackId!=''){
        this.$message('请勿重复分享！')
        return;
      }
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          screen: {
              enabled: true,
              source: "window",
              bitrate: 600,
              height: 720,
              width: 1280,
              tag:'screen'
          },
          audio: {
              enabled: false,
          }
        })
      } catch (e) {
        console.error(e)
        this.$message(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      } 

      try {
        // 发布采集流
        await myRoom.publish(tracks)
      } catch (e) {
        console.error(e)
        this.$message(`发布失败，ErrorCode: ${e.code}`)
      }
      
      this.screenTrackId=tracks[0].info.trackId
      this.screenTrack = tracks[0]

      if(this.remoteTrackId!=''){
        myRoom.unsubscribe([this.remoteTrackId]);
        this.remoteTrackId=''
      }

      let ppt = document.getElementById('ppt');
      for (const track of tracks) {
            if (track.info.kind === "video") {
               
                track.play(ppt, true);
            }
        }
    },
    async publish () {
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          video: {
            enabled: true,
            deviceId: this.videoDevice,
            bitrate: 1000,
            height:720,
            width:1280
          },
          screen: {
              enabled: true,
              source: "window",
              bitrate: 600,
              height: 720,
              width: 1280,
              tag:'screen'
          },
          audio: {
            enabled: true,
            deviceId: this.audioDevice
          }
        })
      } catch (e) {
        console.error(e)
        alert(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      }

              //每个人的block
                let div = document.createElement('div');
                div.setAttribute('id',tracks[0].userId);
                div.style.width = '33%';
                let student = document.getElementById('student');
                student.appendChild(div)
                //摄像头
                let camera = document.createElement('div');
                camera.setAttribute('id','camera'+tracks[0].userId);
                camera.style.width = '100%';
                div.appendChild(camera)
                //屏幕
                let screen = document.createElement('div');
                screen.setAttribute('id','screen'+tracks[0].userId);
                screen.style.width = '100%';
                div.appendChild(screen)
  
                for (let track of tracks) {
                    if (track.info.kind === "video") {
                      if(track.info.tag=='screen'){
                        track.play(screen, true);
                      }else{
                        track.play(camera,true)
                      }
                    }
                }


      try {
        // 发布采集流
        await myRoom.publish(tracks)
        this.tracks =tracks
      } catch (e) {
        console.error(e)
        alert(`发布失败，ErrorCode: ${e.code}`)
      }


    },
    subscribeTracks (trackInfoList) {
        let student = document.getElementById('student');
      if(trackInfoList.length!==0){
        myRoom.subscribe(trackInfoList.map(t => t.trackId)).then(tracks => {
            for (const track of tracks) {
                  
                    if (track.info.kind === "video") {
  
                        track.play(student,true)
                      
                    }else{
                      track.play(student)
                    }

            }
        })
      }
                
    },
    createRoomToken () {
      return new Promise((resolve,reject)=>{
        console.log(this.roomId)
        createPresentationRoomToken(this.roomId,'admin').then(res => {
          resolve(res.data)
        })
      })
      
    },
   
    leaveRoom(){
      myRoom.leaveRoom();
      for (const track of this.tracks) {
        track.release();
      }
      this.$router.back(-1)
    }
    

  }
}
</script>

<style scoped>
select {
  width: 300px;
}
.users{
    padding:20px
}

section {
  margin-bottom: 20px;
}

.qnrtc-stream-player {
  width: 320px !important;

  background: #000;
}

.smallWindow{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>
