<template>
    <div class="container" >
       <div >
            <h2>感谢参与，价值创造者结果将在2021年2月8日才艺表演结束后公布</h2>
            <h3>Thank you for particiaption. Valu Creaters' results be announced after Talent Show on Feb 8th, 2021</h3>
            <!-- <p class="txt">请在2021年2月6日晚回来获取相关信息</p>
            <p class="txt">Please come back for more information on February 6th evening, 2020</p> -->
       </div>
    </div>
</template>

<script>

export default {
    data(){
        return{

        }
    },
    mounted(){
     
    },
    methods:{
    }
}
</script>

<style scoped>
.container{
    width:100%;
    color:#606266;
    height:54vh;
    display:flex;
    justify-content:center;
    align-items:center
}
.txt{
    font-size:90%
}
</style>