<template>
  <div class="CardManage">
    <div style="display: flex;flex-direction: row-reverse;margin-bottom: 10px">
      <div>
        <el-button type="primary" @click="showDialogForCreateNewCard = true">新建卡片</el-button>
      </div>
    </div>
    <div>
      <el-select v-model="sector" placeholder="请选择">
        <el-option
          v-for="item in sectores"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="fetchData" style="margin-left: 20px">搜索卡片</el-button>
    </div>
    <el-table :data="cards" style="margin-top: 10px">
      <el-table-column prop="" label="编号" align="center" width="50px">
        <template slot-scope="scope">
          <span>{{scope.row.index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sector" label="行业名称" align="center" width="150px">
      </el-table-column>
      <el-table-column prop="name" label="卡片名称" align="center" width="500px">
      </el-table-column>
      <el-table-column prop="intro" label="简介" align="center" />
      <el-table-column prop="" label="详情" align="center" width="100px">
        <template slot-scope="scope">
          <div>
            <el-button type="primary" @click="goCardDetail(scope.row._id.$id)">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showDialogForCreateNewCard">
      <span >创建新的卡片</span>
      <el-input v-model="cardInfo.sector"  placeholder="请输入行业名称" style="margin-top: 15px"/>
      <el-input v-model="cardInfo.name"  placeholder="请输入卡片名称" style="margin-top: 15px"/>
      <el-input
        style="margin-top: 15px"
        type="textarea"
        :rows="5"
        ref="input"
        placeholder="请输入卡片简介"
        :autofocus="false"
        v-model="cardInfo.intro">
      </el-input>
      <div class="flex-right" style="margin-top: 40px">
        <el-button type="primary" @click="createNewCard">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {updateCard, getCardsBySector} from '../../api/VC_Arena'
export default {
  data(){
    return {
      sectores:["New-Style Tea"],
      sector: 'New-Style Tea',
      showDialogForCreateNewCard:false,
      cardInfo:{"sector":"","name":"","intro":""},
      cards:[]
    }
  },
  mounted(){
    this.fetchData()
    },
  methods: {
    fetchData() {
      if(this.sector === ''){
        this.$message('请输入搜索的卡片名称!')
        return
      }
      getCardsBySector(this.sector).then((res) =>{
        this.cards= res.data.data
        this.cards = this.cards.map((item, index) => ({ ...item, index: index }))
      })
    },
    createNewCard() {
      this.showDialogForCreateNewCard = false
      updateCard('', this.cardInfo.sector, this.cardInfo.name, this.cardInfo.intro).then((res) => {
        if(this.sector !== ''){
          this.fetchData()
        }
      })
    },
    goCardDetail (id){
      this.$router.push('/game/carddetail/'+id)
    }
  }
}
</script>

<style scoped>
  .CardManage {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
