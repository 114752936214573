/**
 * Created by huzeyin on 2020/5/12.
 */
import request from '../utils/request'
//新建或者更新卡片
export function updateCard (card_id,sector,name,intro,department) {
  return request.post('/VC_Arena/index.php', {
    function: 'updateCard',
    card_id,
    sector,
    name,
    intro,
    department
  })
}

export function getCardsBySector (sector) {
  return request.post('/VC_Arena/index.php', {
    function: 'getCardsBySector',
    sector
  })
}

export function getCard (id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getCard',
    id
  })
}

export function getCardEffect (id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getCardEffect',
    id
  })
}
export function updateCardEffect (effect_id,card_id,cardEffectDetail) {
  return request.post('/VC_Arena/index.php', {
    function: 'updateCardEffect',
    effect_id:effect_id,
    card_id:card_id,
    name:cardEffectDetail.name,
    effect_round:cardEffectDetail.effect_round,
    investment_weight:cardEffectDetail.investment_weight,
    PnL_account:cardEffectDetail.PnL_account,
    PnL_account_type:cardEffectDetail.PnL_account_type,
    cash_account:cardEffectDetail.cash_account,
    cash_account_type:cardEffectDetail.cash_account_type,
    balance_account:cardEffectDetail.balance_account,
    balance_account_type:cardEffectDetail.balance_account_type,
    parameter_type:cardEffectDetail.parameter_type,
    parameter:cardEffectDetail.parameter,
    benchmark_parameter:cardEffectDetail.benchmark_parameter,
    benchmark_percent:cardEffectDetail.benchmark_percent,
    delta_volume:cardEffectDetail.delta_volume,
    dependents:cardEffectDetail.dependents,
    dependent_amplifier:cardEffectDetail.dependent_amplifier,
    boyi_effect_switch:cardEffectDetail.boyi_effect_switch,
    boyi_anti_cards:cardEffectDetail.boyi_anti_cards,
  })
}

export function getEcoCards () {
  return request.post('/VC_Arena/index.php', {
    function: 'getEcoCards'
  })
}

export function getEcoCard (id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getEcoCard',
    id
  })
}
export function assignRoundEcoCard (id,round,game_id,card_ids) {
  return request.post('/VC_Arena/index.php', {
    function: 'assignRoundEcoCard',
    id,
    round,
    game_id,
    card_ids
  })
}
export function updateEcoCard (card_id,cardInfo) {
  return request.post('/VC_Arena/index.php', {
    function: 'updateEcoCard',
    card_id,
    sector:cardInfo.sector,
    name:cardInfo.name,
    intro:cardInfo.intro,
    term:cardInfo.term,
    card_impact_array:cardInfo.card_impact_array,
    market_growth:cardInfo.market_growth,
  })
}
export function getGames () {
  return request.post('/VC_Arena/index.php', {
    function: 'getGames'
  })
}
export function getGame (id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getGame',
    id
  })
}
export function updateGame (id,gameInfo) {
  return request.post('/VC_Arena/index.php', {
    function: 'updateGame',
    id,
    name:gameInfo.name,
    sector:gameInfo.sector,
    round:gameInfo.round,
    current_round:gameInfo.current_round,
    time_per_round:gameInfo.time_per_round,
    start_time:gameInfo.start_time,
    briefing:gameInfo.briefing,
    open_status:gameInfo.open_status,
    round_close_times:gameInfo.round_close_times
  })
}

export function updateGameDefaultData(id, default_data_array){
   return request.post('/VC_Arena/index.php', {
      function: 'updateGameDefaultData',
      id,default_data_array
    })
}
export function updateTeam (id,game_id,internal_code) {
  return request.post('/VC_Arena/index.php', {
    function: 'updateTeam',
    id,
    game_id,
    internal_code
  })
}
export function getTeam (id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getTeam',
    id,
  })
}
export function getTeamsByGame (game_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getTeamsByGame',
    game_id,
  })
}
export function getOpenGames () {
  return request.post('/VC_Arena/index.php', {
    function: 'getOpenGames',
  })
}

export function getFakeOpenGames () {
  return request.post('/VC_Arena/index.php', {
    function: 'getFakeOpenGames',
  })
}
export function getMyTeam (user_id,game_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getMyTeam',
    user_id,
    game_id
  })
}
export function getMyTeamV1 (user_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getMyTeam',
    user_id
  })
}
export function joinTeam (user_id,game_id,team_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'joinExistTeam',
    user_id,
    game_id,
    team_id
  })
}
export function quitTeam (user_id,game_id,team_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'quitTeam',
    user_id,
    game_id,
    team_id
  })
}
export function getTeamMemberList (team_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getMyTeamMemberList',
    team_id
  })
}
export function assignTeamMemberRoles (team_id,roles) {
  return request.post('/VC_Arena/index.php', {
    function: 'assignTeamMemberRoles',
    team_id,
    roles
  })
}
export function getMyTeamData (team_id,round) {
  return request.post('/VC_Arena/index.php', {
    function: 'getMyTeamData',
    team_id,
    round
  })
}

export function getOtherTeamData (team_id,round) {
  return request.post('/VC_Arena/index.php', {
    function: 'getOtherTeamData',
    team_id,
    round
  })
}

export function getRoundEcoCards (game_id,round) {
  return request.post('/VC_Arena/index.php', {
    function: 'getRoundEcoCards',
    game_id,
    round
  })
}

export function uploadCardPic (card_id,file) {
  const data = new FormData()
  data.append('function', 'uploadCardPic')
  data.append('card_id', card_id)
  data.append('file', file)
  return request.post('/VC_Arena/index.php', data)
}
export function getMyNewCards (user_id,team_id,round,role,sector,card_no) {
  return request.post('/VC_Arena/index.php', {
    function: 'getMyNewCards',
    user_id,
    team_id,
    round,
    role,
    sector,
    card_no
  })
}
export function confirmMyNewCards (user_id,team_id,role,new_cards,round) {
  return request.post('/VC_Arena/index.php', {
    function: 'confirmMyNewCards',
    user_id,
    team_id,
    role,
    new_cards,
    round
  })
}
export function getTeamCards (team_id,round) {
  return request.post('/VC_Arena/index.php', {
    function: 'getTeamCards',
    team_id,
    round
  })
}
export function confirmTeamCards (team_id,round,cards_chosen,cards_investment) {
  return request.post('/VC_Arena/index.php', {
    function: 'confirmTeamCards',
    team_id,
    round,
    cards_chosen,
    cards_investment
  })
}
export function updateTeamName (team_id,team_name) {
  return request.post('/VC_Arena/index.php', {
    function: 'updateTeamName',
    team_id,
    team_name
  })
}
export function getOtherTeamsByGame (game_id,team_id) {
  return request.post('/VC_Arena/index.php', {
    function: 'getOtherTeamsByGame',
    game_id,
    team_id
  })
}
