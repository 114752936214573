<template>
  <div class="game">
    <div style="display: flex;flex-direction: column;margin-bottom: 10px">
      <div>
        <div>
          <div style="display: flex;flex-direction: row;margin-top: 25px;align-items: center">
            <h4>公司名称 Company Name：</h4>
            <h2>{{myTeamInfo.team_name}}</h2>
            <!-- <el-input v-model="myTeamInfo.team_name" placeholder="请输入公司名称" style="width: 200px"/> -->
           <!--  <el-button type="primary" @click="handleUpdateTeamName" style="margin-left: 20px">更新名字 Change Name</el-button> -->
           <el-button type="primary" @click="showNameChange()" style="background:lightGray;border:lightGray;margin-left: 20px">更新公司名字 Change Company Name</el-button>
          </div>
        </div>

        <h4 style="float:left">经营提示 Operation Reminders</h4>
            <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>



            <br>
            <div v-if="showSocialTip" style="margin:20px 0px;color:grey;font-size:14px;padding:10px;background-color:lightGray;float:none">
                <p>欢迎来到价值创造者！现在你可以看到公司的经营界面。在你开始动手以前，这里有一些重要的信息，你需要提前了解：</p>

              <p>价值创造者是一个基于卡牌的经营活动，所有的经营举措就会以一张张卡牌的形式呈现。你的队伍需要在每一轮完成出牌，来达到公司经营的目的</p>
              <p>每一轮的卡牌抽取共分成三个步骤：第一步，抽牌；每个部门的负责人每一轮都可以随机从部门的卡牌库中抽取三张新牌；第二步，选牌；每个部门的负责人集合自己手中已有的旧牌，加上新抽取的牌，进行牌的取舍。在进行第三步之前，每个部门手中的牌不能保留超过三张。你可以选择用新牌更换旧牌，也可以选择不换；第三步，出牌；当每个部门的三张可出牌经过抽牌、选牌的过程后，所有五个部门的牌会合在一起，整个公司可以从中挑选不超过六张，作为本轮的出牌。</p>

              <p>这里有两点需要注意：1. 在抽牌、选牌阶段（第一、第二步），每个部门都只能看到自己部门的牌；看不到其他部门的牌，如果需要协作配合，团队之间就需要作私下沟通；2. 出牌阶段，公司除了需要讨论出什么牌，还要决定在每张牌上投入多少钱；投入的钱会直接从财务报表中扣除，但也会给后面的经营带来效果；你可以在选牌、出牌界面点击每张牌面详细了解每张卡的特性；</p>

              <p>公司的名字、公司的成员甚至你所服务的队伍都可以在途中更换。正如现实商业世界中公司会更名、职员会流动、你也会跳槽，只要你有充分的理由这么做；选手也可以选择中途轮数才加入游戏，正如有些公司可以起步较晚，只不过在已经过去的轮数中，这些没有启动的公司会基本原地踏步、保守发展。</p>


              <p>公司的经营数据共分成两大部分，财务数据和竞争力数据。财务数据代表了公司发展的过去，竞争力数据代表了公司发展的未来。两者之间当然可以相互影响，这本是平衡长期与短期的关系。公司现在花钱可以增强竞争力，竞争力在未来可以反哺公司的经营业绩。</p>

              <p>在最后一轮结束后，你的经营成绩，即公司的价值，也会通过这两部分来评估，最终的财务数据代表了你经营的过去；当然，竞争力代表公司未来创造价值的能力（正如股市的估值一样）。最后计算的方式为：基于财务数据的过去业绩占比100分，以每家公司的实际每轮复合股本回报率（即股东权益的增长率）作为标准，最高的第一名得100分，第二名按照两者的比例获得相应分数；基于未来的过去业绩占100分，以公司所有竞争力维度（0-100分）的平均值为最终得分。两张相加即为你公司的总分</p>


              <p>请理解，你的队伍最终的成绩是基于你每一轮的出牌一次次叠加结算得出的。由于系统复杂，计算逻辑可能会有未发现的Bug，工程师将在今天及时修复（若有），并可按照你们的出牌重新复原和修正所有数据。</p>

              <p>若有任何疑问，欢迎及时向我们联系。工程师也会时刻在界面中根据你们的比赛反馈添加提示信息。</p>


              <p>注：昨天晚上误入Zone D和Zone E二次元空间的伙计们，系统已自动帮你们退队，请重新加入到Zone A, B ,C。</p>
            <br>
            </div>

        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="基本信息 Basic Information" name="first">
            <div style="display: flex;flex-direction: row;margin-top: 25px;">

              <div style="display: flex;flex-direction: column;padding:20px;border:1px solid lightGray;border-radius:20px;padding:30px">
                <span style="margin:0 auto">游戏信息 Game Information</span>
                <el-form ref="form" :model="myGameInfo" label-width="200px" style="margin-top: 20px">
                  <el-form-item label="市场 Market:">
                    <span>{{myGameInfo.name}}</span>
                  </el-form-item>
                  <el-form-item label="行业 Sector：">
                    <span>{{myGameInfo.sector}}</span>
                  </el-form-item>
                  <el-form-item label="目前年份 Current Year：">
                    <span>{{myGameInfo.showRound}}</span>
                  </el-form-item>
                  <el-form-item label="运行中 Operating：">
                    <span>{{myGameInfo.open_status}}</span>
                  </el-form-item>
                  <el-form-item label="本年决策截止时间 Close Time for Current Year">
                    <span>{{myGameInfo.round_close_times[(myGameInfo.current_round + '')] * 1000 |timeFormat}}</span>
                  </el-form-item>
                </el-form>
              </div>
              <div style="display: flex;flex-direction: column;margin-left: 50px;padding:20px;border:1px solid lightGray;border-radius:20px;padding:30px">
                <span style="margin:0 auto">公司团队信息 Company Team Information</span>
                <el-form ref="form" :model="myTeamInfo" label-width="200px" style="margin-top: 20px">
                  <el-form-item v-if="myTeamInfo.team_roles_info.HR&&myTeamInfo.team_roles_info.HR.student_lastName"  label="人力资源部 HR：">
                    <span>{{myTeamInfo.team_roles_info.HR.student_lastName+myTeamInfo.team_roles_info.HR.student_givenName+ ', ' + myTeamInfo.team_roles_info.HR.account_nameEN+ ', ' + myTeamInfo.team_roles_info.HR.student_mobile}}</span>
                  </el-form-item>
                  <el-form-item v-if="myTeamInfo.team_roles_info.Finance&&myTeamInfo.team_roles_info.Finance.student_lastName" label="财务部 Finance：">
                    <span>{{myTeamInfo.team_roles_info.Finance.student_lastName+myTeamInfo.team_roles_info.Finance.student_givenName+ ', ' + myTeamInfo.team_roles_info.Finance.account_nameEN+ ', ' + myTeamInfo.team_roles_info.Finance.student_mobile}}</span>
                  </el-form-item>
                  <el-form-item  v-if="myTeamInfo.team_roles_info.Marketing&&myTeamInfo.team_roles_info.Marketing.student_lastName"  label="市场营销部 Marketing：">
                    <span>{{myTeamInfo.team_roles_info.Marketing.student_lastName+myTeamInfo.team_roles_info.Marketing.student_givenName+ ', ' + myTeamInfo.team_roles_info.Marketing.account_nameEN+ ', ' + myTeamInfo.team_roles_info.Marketing.student_mobile}}</span>
                  </el-form-item>
                  <el-form-item  v-if="myTeamInfo.team_roles_info.RnD&&myTeamInfo.team_roles_info.RnD.student_lastName" label="研发部 RnD：">
                    <span>{{myTeamInfo.team_roles_info.RnD.student_lastName+myTeamInfo.team_roles_info.RnD.student_givenName + ', ' + myTeamInfo.team_roles_info.RnD.account_nameEN+ ', ' + myTeamInfo.team_roles_info.RnD.student_mobile}}</span>
                  </el-form-item>
                  <el-form-item  v-if="myTeamInfo.team_roles_info.Operation&&myTeamInfo.team_roles_info.Operation.student_lastName" label="运营部 Operation：">
                    <span>{{myTeamInfo.team_roles_info.Operation.student_lastName+myTeamInfo.team_roles_info.Operation.student_givenName+ ', ' + myTeamInfo.team_roles_info.Operation.account_nameEN+ ', ' + myTeamInfo.team_roles_info.Operation.student_mobile}}</span>
                  </el-form-item>
                  <el-form-item>
                 <div style="text-align:center;font-size:10px;color:gray;margin:10px;padding:10px">未罗列部门为负责人缺失<br>因此团队将无法打出这些部门的卡<br>Departments not listed here do not have leader assigned<br>Thus team cannot play the cards of those departments</div>
                 <div style="text-align:center;font-size:10px;color:gray;margin:10px;padding:10px">如果想要更改角色配置，请至组队界面更改<br>Please go to the 'Teams' page for role updates </div>

                  </el-form-item>
                  
                </el-form>
              </div>


            </div>
        </el-tab-pane>
        <el-tab-pane label="团队持卡信息 Team Cards Information" name="second">
            <div style="">
            <span  style="margin:0 auto">以下为目前你具有管理决策权的部门<br>Below are the departments that you have management decision making power</span>

            <div v-for="(item,index) in departments" style="margin-top: 20px">
              <div v-if="myTeamInfo.team_roles[item] == userId" style="align-items: left;padding:10px;">
                <div style="padding:10px 0px;margin:20px 0;border-bottom:2px solid lightGray;"><span style="font-size:20px;background-color:lightGray;padding:10px;">{{dpt_names[item] + ' ' +item + ' Department'}}</span></div>
                <div style="display: flex;flex-direction: row;">

                  <div style="margin-left: 10px;width:700px;border-right:2px solid lightGray">
                    <div style="font-size:14px;margin:10px;">最近抽卡年份 Latest Draft Year: <span style="padding:2px 6px;border-radius:4px;background-color:orange;color:white">{{myTeamInfo.new_cards_round[item]? 'Year ' + myTeamInfo.new_cards_round[item]:'从未抽过 Never drafted'}}</span>

                      <span v-if="!myTeamInfo.new_cards_round[item] || myTeamInfo.new_cards_round[item] < myGameInfo.current_round" style="margin-left:10px"><i>本轮还需抽卡 Need draft this round</i></span>
                      <span v-if="myTeamInfo.new_cards_round[item] && myTeamInfo.new_cards_round[item] == myGameInfo.current_round" style="margin-left:10px"><i>已完成选卡 Draft Complete</i></span>
                    </div>
                    <div style="font-size:14px;margin:10px;">最近选卡年份 Latest Selection Year: <span style="padding:2px 6px;border-radius:4px;background-color:green;color:white">{{myTeamInfo.team_cards_round[item]? 'Year ' + myTeamInfo.team_cards_round[item]:'从未选过 Never Selected'}}</span>

                      <span v-if="!myTeamInfo.team_cards_round[item] || myTeamInfo.team_cards_round[item] < myGameInfo.current_round"style="margin-left:10px"><i>本轮还需选卡 Need select this round</i></span>
                      <span v-if="myTeamInfo.team_cards_round[item] && myTeamInfo.team_cards_round[item] == myGameInfo.current_round"style="margin-left:10px"><i>已完成选卡 Selection Complete</i></span>
                    </div>

                  </div>
                  <div v-if="(!myTeamInfo.new_cards_round[item] || myTeamInfo.new_cards_round[item]<myGameInfo.current_round )  &&isDepartmentRole" style="margin-left:20px;">
                    <el-button style="background:orange;border:orange" type="primary" @click="doGetMyNewCards(item,myGameInfo.current_round)">先抽卡 Draft Cards First</el-button>
                  </div>
                  <div v-if="(myTeamInfo.team_cards_round[item]<myGameInfo.current_round || !myTeamInfo.team_cards_round[item]) &&isDepartmentRole && myTeamInfo.new_cards_round[item]== myGameInfo.current_round" style="margin-left:20px">
                  <!-- <div v-if="isShowSelectCard(myTeamInfo.team_cards_round[item]) &&myTeamInfo.new_cards&&myTeamInfo.new_cards[item]" style="margin-left: 20px"> -->
                    <el-button style="background:green;border:green" type="primary" @click="showConfirmMyNewCardsDialog(item,myGameInfo.current_round,index)">再选卡 Select Cards then</el-button>
                  </div>
                </div>
                <div style="border: 2px dotted lightGray;border-radius:40px;margin:30px auto;width:100%">

                <div style="color:gray;text-align:center;margin:20px;font-size:12px;">你部门（截止最近一轮/年抽牌）抽到的牌卡 Cards drafted update to the latest draft round/year in your department</div>
                <div style="display: flex;flex-direction: row;align-items: left;margin:0 auto">

                  <div v-if="!myTeamInfo.new_cards_info[item] || myTeamInfo.new_cards_info[item].size === 0" style="margin:50px 30px;text-align:center;width:100%">本部门手中现在未有任何抽出的卡片 No Drafted Cards Available in this Department currently</div>
                  <div v-for="(card,cardIndex) in myTeamInfo.new_cards_info[item]" style="margin: 20px">
                    <img  @click="showCardDetails(card._id.$id)" :src="'http://' + card.card_cover_address" class="avatar" style="width:250px;border-radius:20px">
                    <div style="margin-right: 20px;">
                      <!--  <span>{{card['name']}}</span> -->
                    </div>
                  </div>
                </div>
                <div style="color:gray;text-align:center;margin:20px;font-size:12px;">你部门（截止最近一轮/年选派）选出的牌卡 Cards selected for team update to the latest selection round/year in your department</div>
                <div style="display: flex;flex-direction: row;align-items: left;margin:0 auto">

                  <div v-if="!myTeamInfo.team_cards_info[item] || myTeamInfo.team_cards_info[item].size === 0" style="margin:50px 30px;text-align:center;width:100%">你部门手中现在未有任何选出的卡片 No Selected Cards Available in this Department currently</div>
                  <div v-for="(card,cardIndex) in myTeamInfo.team_cards_info[item]" style="margin: 20px">
                    <img @click="showCardDetails(card._id.$id)" :src="'http://' + card.card_cover_address" class="avatar" style="width:250px;border-radius:20px">
                    <div style="margin-right: 20px;">
                      <!--  <span>{{card['name']}}</span> -->
                    </div>
                  </div>
                </div>

              </div>

              </div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="每年经营信息 Yearly Operation Information" name="third">
                <div style="margin-top: 20px;">



                <span  style="margin:0 auto">每年经营 Operation for Every Year：</span>
                <el-tabs v-model="selectedTabName" type="card"  @tab-click="handleTabSelected" style="margin-top: 20px">
                  <el-tab-pane
                    :key="item.name"
                    v-for="(item) in tabs"
                    :label="'Year ' + item.name"
                    :name="item.name">
                    <GameRoundDetail :roundEcoCards = 'roundEcoCards':playRound='myGameInfo.current_round' :currentRound ='selectedCurrentRound' :gameId="gameId" :teamId="teamId" :myOtherTeams="myOtherTeams" :myTeamCards="myTeamCards":dataType="dataType" :myNotCurrentRoundTeamData="myNotCurrentRoundTeamData"  :myOtherTeamData="myOtherTeamData" @callback="doShowChoseCardDialog"></GameRoundDetail>
                  </el-tab-pane>
                </el-tabs>
              </div>
        </el-tab-pane>
    </el-tabs>




        <!--<el-dialog :visible.sync="showGetMyNewCardsDialog">-->
          <!--<div style="display: flex;flex-direction: column">-->
            <!--<span >我的卡片信息</span>-->
          <!--</div>-->
          <!--<div class="flex-right" style="margin-top: 40px">-->
            <!--<el-button type="primary" @click="showGetMyNewCardsDialog=false">确认</el-button>-->
          <!--</div>-->
        <!--</el-dialog>-->

        <el-dialog :visible.sync="showNameChangeStatus">
          <div style="display: flex;flex-direction: column">
              <span style="margin-bottom:20px">更改公司名字 Change Company Name</span>
              <el-input v-model="myTeamInfo.team_name" placeholder="请输入公司名称 Please enter your company name" style="width: 400px"/>
          </div>
          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" style="background:lightGray;border:lightGray" @click="showNameChangeStatus=false">取消 Cancel</el-button>
            <el-button type="primary" @click="handleUpdateTeamName">更改 Change</el-button>

          </div>
        </el-dialog>

        <el-dialog :visible.sync="showConfirmCardsDialog">
          <div style="display: flex;flex-direction: column">
            <span >选择你部门此轮保留的卡片，最多3张</span>
            <span >Select cards to keep in your department this round, 3 cards at most</span>
            <br>
            <span >如果你的浏览器选中框不起作用/变化，请以弹出提示信息中的卡片名字选择为准</span>
            <span >If your browser is not having your checkboxes working, please refer to the names of your selected cards on prompt message reminders</span>

          </div>
          <!--新的卡片-->
          <div style="display: flex;flex-direction: row;margin-top: 10px" v-for="(item,index) in allCanSelectCards">
            <!-- <el-checkbox  :label="index" :key="item.name" v-model="item.selected" ><div class="check-box-item">{{item.name}}</div></el-checkbox> -->
            <!-- <div> -->
              <el-checkbox v-model="allCanSelectCards[index]['selected']" @change="handleCanSelectCardsSelected(index)" >{{item['card']['name']}}</el-checkbox>
            <!-- </div> -->
          </div>
          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" @click="doConfirmMyNewCards()">确认选择 Confirm Selection</el-button>
          </div>
        </el-dialog>
        <!--出牌-->
        <el-dialog :visible.sync="showChoseCardDialog">
          <div style="display: flex;flex-direction: column">
            <span >请选择最多6张牌 Please select 6 cards at most </span>
            <span >投资额不能超过{{myLastRoundTeamData.liquid_assets}}</span>
          </div>
          <!--新的卡片-->
          <div style="display: flex;flex-direction: row;margin-top: 10px" v-for="(item,index) in newCardsForAllDepartment">
            <div>
              <el-checkbox v-model="selectChoseCardInfos[index]['select']" @change="handleChoseCardChecked(index)">{{item['name'] + ' (' + item['department'] + ')'}}</el-checkbox>
              <el-input type="number" v-model="selectChoseCardInfos[index]['input_investment']" placeholder="在此卡牌举措上的投入金额 Investment on this card" ></el-input>
            </div>
          </div>
          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" @click="doConfirmTeamCardsChose()">确认 Confirm</el-button>
          </div>
        </el-dialog>
        <!--Card Detail-->
        <el-dialog :visible.sync="showCardDetailsSwitch">
          <div style="display: flex;flex-direction: column">
            <span >卡片详情 Card Details </span>

          </div>
          <!-- {{this.card_detail._id.$id? this.card_detail._id.$id : ''}} -->
          <StandardCard :card_info='card_detail'></StandardCard>


          <div class="flex-right" style="margin-top: 40px">
            <el-button type="primary" @click="hideCardDetails()">知道了 Got it</el-button>
          </div>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script>
  import GameRoundDetail from '@/components/GameRoundDetail.vue'
  import StandardCard from '@/components/StandardCard.vue'
  import {updateTeamName,getMyTeamV1,getGame,getRoundEcoCards,getMyNewCards,confirmMyNewCards,getTeamCards,confirmTeamCards,getMyTeamData,getOtherTeamsByGame,getCard} from '../../api/VC_Arena'
  import {getUserId} from '../../utils/store'
  export default {
    components: {
      GameRoundDetail,
      StandardCard
    },
    data(){
      return {
        activeName:'first',
        departments:['HR','Finance','Marketing','RnD','Operation'],
        showNameChangeStatus:false,
        team_name:'',
        selectedTabName:"",
        gameId:"",
        teamId:'',
        selectedCurrentRound:0,
        tabs: [],
        myTeamInfo:{},
        myGameInfo:{},
        myRoundEcoCards:{},
        team_cards_info:[],
        showGetMyNewCardsDialog:false,
        myNewCardsInfo:undefined,
        showConfirmCardsDialog:false,
        allCanSelectCards:[],
        selectCardsArray:{},
        confirmMyNewCardsInfo:{"role":"","round":""},
        dataType:0,
        myTeamCards:{},
        newCardsForAllDepartment:[],
        showChoseCardDialog:false,
        selectChoseCardInfos:[],
        myNotCurrentRoundTeamData:{},
        myLastRoundTeamData:{},
        myOtherTeamData:{},
        myOtherTeams:[],
        roundEcoCards:[],
        userId:'',
        showSocialTip:true,
        showSocialTipTitle:'收起 Fold',
        dpt_names:{


          'HR': '人力资源部',
          'Finance': '财务部',
          'RnD': '研发部',
          'Operation': '运营部',
          'Marketing': '市场营销部',
        },
        card_detail:{},
        showCardDetailsSwitch:false
      }
    },
    mounted(){
      this.team_cards_info = []
      this.fetchData()
    },
    methods: {

      handleClick(tab, event) {
        console.log(tab, event);
      },

      showNameChange(){

        this.showNameChangeStatus = true;

      },
      handleUpdateTeamName(){
        updateTeamName(this.myTeamInfo['_id']['$id'],this.myTeamInfo.team_name).then((res) => {
            this.showNameChangeStatus = false;
        })
      },
      handleChoseCardChecked(position){
        var selected =  this.selectChoseCardInfos[position]["select"]
        if(selected){
          var selectedCount = 0
          for(var i=0;i<this.selectChoseCardInfos.length;i++){
            if(this.selectChoseCardInfos[i]["select"]){
              selectedCount++
            }
          }
          if(selectedCount>6){
            this.selectChoseCardInfos[position]["select"]=false
            this.$message("最多不能超过6个 No more than 6 cards")
            return
          }
        }
      },
      fetchData(){
        let user_id = getUserId()
        this.userId = getUserId()
        getMyTeamV1(user_id).then((res) =>{
          var result  = res.data
          if(result.code ==0){
            this.myTeamInfo = result.data
            this.teamId = this.myTeamInfo['_id']['$id']
            this.gameId = this.myTeamInfo.game_id

            // this.team_cards_info = this.myTeamInfo.team_cards_info

            this.team_cards_info = []

            for(let key in this.myTeamInfo.team_cards_info){
              var cards  =this.myTeamInfo.team_cards_info[key]
              var round = 0
              if(this.myTeamInfo.team_cards_round&&this.myTeamInfo.team_cards_round[key]){
                round =  this.myTeamInfo.team_cards_round[key]
              }
              var cardInfo = {"department":key,"cards":cards,"round":round}
              this.team_cards_info.push(cardInfo)
            }
            this.doGetGame()
            this.doGetMyOtherTeams()
          }else{
            this.$message(result.msg)
          }
        })
      },
      showCardDetails(id){


        getCard(id).then((res) =>{
          this.card_detail = res.data.data
          this.showCardDetailsSwitch = true
        })
      },
      hideCardDetails(){
        this.showCardDetailsSwitch = false
      },
      doGetMyOtherTeams(){
        if(this.myOtherTeams.length>0){
            return
        }
        getOtherTeamsByGame(this.gameId,this.teamId).then((res) => {
          this.myOtherTeams = res.data.data
        })
      },
      //如果选的卡的round小于比赛的round
      isShowSelectCard(round){
        if(this.myGameInfo.current_round==round){
          return true
        }
        return false
      },
      showConfirmMyNewCardsDialog(role,round,index){
        this.allCanSelectCards = []
        this.selectCardsArray = {}
        var new_cards_round=0
        if(this.myTeamInfo.new_cards_round&&this.myTeamInfo.new_cards_round[role]){
          new_cards_round =this.myTeamInfo.new_cards_round[role]
        }
        var team_cards_round=0
          if(this.myTeamInfo.team_cards_round&&this.myTeamInfo.team_cards_round[role]){
            team_cards_round = this.myTeamInfo.team_cards_round[role]
          }
          //new_cards_round >team_cards_round
        if(new_cards_round >team_cards_round){
          // var cards=this.team_cards_info[role]

          if(this.myTeamInfo.team_cards_info[role]){
            var cards = this.myTeamInfo.team_cards_info[role]

            for(var i =0;i<cards.length;i++){

              var card ={"card": cards[i],"selected":false}

//              card["selected"] = false
              // this.selectCardsArray[card._id.$id] = false
              // card["info"] = cards[i]
              this.allCanSelectCards.push(card)
            }
          }

          // var newCards=this.myTeamInfo.new_cards_info[role]

          if(this.myTeamInfo.new_cards_info[role]){
            var newCards = this.myTeamInfo.new_cards_info[role]
            for(var i =0;i<newCards.length;i++){
//              var card = newCards[i]
//              card["selected"] = false

              var card ={"card": newCards[i],"selected":false}
              // this.selectCardsArray[card._id.$id] = false
              // card["info"] = newCards[i]
              this.allCanSelectCards.push(card)
            }
          }

          this.confirmMyNewCardsInfo.round = round
          this.confirmMyNewCardsInfo.role = role
          this.showConfirmCardsDialog =true
        }else{
          //TODO:不可以选择，给出toast
        }
      },
      doConfirmMyNewCards(){
        var new_cards= []
        for(var i = 0;i <this.allCanSelectCards.length;i++){
          if(this.allCanSelectCards[i]["selected"]){
            new_cards.push(this.allCanSelectCards[i]['card']['_id']['$id'])
          }
        }
        this.showConfirmCardsDialog=false
        confirmMyNewCards(getUserId(),this.myTeamInfo._id.$id,this.confirmMyNewCardsInfo.role,new_cards,this.confirmMyNewCardsInfo.round).then((res) =>{
          var result  = res.data
          if(result.code ==0){
            if(result.data !=null){
              this.fetchData()
            }
          }else{
            this.$message(result.msg)
          }
        })
      },
      isDepartmentRole(){
        var user_id =  getUserId()
        var team_roles = this.myTeamInfo.team_roles
        for (let key in team_roles){
          var role_id = team_roles[key]
          if(role_id == user_id){
            return true
          }
        }
        return false
      },
      doGetGame(){
          //请求一次之后，不要再次请求了，
        if(this.myGameInfo&&this.myGameInfo['_id']){
            return
        }
        getGame(this.myTeamInfo.game_id).then((res) => {
          var result  = res.data
          if(result.code ==0){
            this.myGameInfo = result.data
            var round = this.myGameInfo.round
            var current_round = this.myGameInfo.current_round
            this.tabs = []
            for(var i = 0;i<=round;i++){
              var tab = {"index":i,"name":""+i}
              this.tabs.push(tab)
            }
            this.selectedTabName = this.tabs[current_round].name
            this.selectedCurrentRound = this.tabs[current_round].index
            this.myGameInfo.showRound = "Year "+this.myGameInfo.current_round+" / Total "+this.myGameInfo.round + " Years"
            this.handleTabSelected(this.tabs[current_round])


            this.doGetMyTeamLastRoundData(this.myGameInfo.current_round -1)
          }else{
            this.$message(result.msg)
          }
        })
      },
      handleTabSelected(value){
        this.myNotCurrentRoundTeamData= {}
        this.myTeamCards=null
        this.myOtherTeamData=null
        this.selectedCurrentRound=Number(value.index)

        if(this.selectedCurrentRound<this.myGameInfo.current_round){
          this.doGetMyTeamData()
        }
        if(this.selectedCurrentRound==0){
            //显示其他的信息，暂时不处理
          this.dataType=0
            return
        }
        if(this.selectedCurrentRound>this.myGameInfo.current_round){
          this.dataType=1
        }else{
          this.dataType=2

          this.doGetTeamCards()
          this.doGetEcoCards()
        }
      },
      doGetMyTeamData(){
        getMyTeamData(this.myTeamInfo._id.$id,this.selectedCurrentRound).then((res)=>{
            this.myNotCurrentRoundTeamData= res.data.data
        })
      },
      doGetMyTeamLastRoundData(round){
        console.log('doGetMyTeamLastRoundData')
        getMyTeamData(this.myTeamInfo._id.$id,round).then((res)=>{
            this.myLastRoundTeamData= res.data.data
        })
      },
      doGetMyOtherTeamData(){
        getOtherTeamData(this.myTeamInfo._id.$id,this.selectedCurrentRound).then((res)=>{
          this.myOtherTeamData= res.data.data
        })
      },
      doGetTeamCards(){
        getTeamCards(this.myTeamInfo._id.$id,this.selectedCurrentRound).then((res) => {
          //获取团队下面的卡，如果没有，就要抽卡
          this.myTeamCards = res.data.data
        })
      },
      doGetEcoCards(){
        getRoundEcoCards(this.gameId,this.selectedCurrentRound).then((res) => {
          //环境卡
          this.roundEcoCards = res.data.data
        })
      },
      //抽卡
      doGetMyNewCards(role,round){
        getMyNewCards(getUserId(),this.myTeamInfo._id.$id,round,role,this.myGameInfo.sector,3).then((res) => {
          var result  = res.data
          if(result.code ==0){
            if(result.data !=null){
              this.fetchData()
            }
//            this.showGetMyNewCardsDialog= true
          }else{
            this.$message(result.msg)
          }
        })
      },
      handleCanSelectCardsSelected(position){

        // console.log(this.allCanSelectCards[position]["selected"])
        // 如果已经选择的个数超过3个就不能选择，提示
        var selected =  this.allCanSelectCards[position]["selected"]
        if(selected){
          var selectedCount = 0
          for(var i=0;i<this.allCanSelectCards.length;i++){
            if(this.allCanSelectCards[i]["selected"]){
              selectedCount++
            }
          }
          if(selectedCount>3){
            this.allCanSelectCards[position]["selected"]=false
            this.$message("最多不能超过3张 Three cards at most")
            return
          }


        }
        var tip = '';
          for(var i=0;i<this.allCanSelectCards.length;i++){
            if(this.allCanSelectCards[i]["selected"]){
              tip = tip + this.allCanSelectCards[i]['card']["name"] + ", "
            }
          }

          var sentence = '你已选择：' + tip
          this.$message(sentence)

      },
      doShowChoseCardDialog(){
        //15个选择
        console.log(this.myTeamInfo.new_cards_info)
        this.selectChoseCardInfos=[]
        var new_cards= this.myTeamInfo.new_cards_info
        for(let key in new_cards){
            var cards =new_cards[key]
          if(cards&&cards!=""&&cards.length>0){
              for(var j=0;j<cards.length;j++){
                 var card =  cards[j]
                var card_id = card['_id']['$id']
                this.selectChoseCardInfos.push({'select':false,'card_id':card_id,'input_investment':''})
                this.newCardsForAllDepartment.push(card)
              }
          }
          this.showChoseCardDialog = true
        }
      },
      doConfirmTeamCardsChose(){
        var input_investment={}
        var cards_chosen=[]
        var total_investment = 0
        var cash_reserve = this.myLastRoundTeamData.liquid_assets//Update here
        for(var i = 0; i<this.selectChoseCardInfos.length; i++){
            if(this.selectChoseCardInfos[i]['select']){
              var card_id =  this.selectChoseCardInfos[i]['card_id']
              
              if(!this.selectChoseCardInfos[i]['input_investment'] || this.selectChoseCardInfos[i]['input_investment'] == null || this.selectChoseCardInfos[i]['input_investment'] == ''){

                input_investment[card_id] = 0

              }else{

                var invest_amount = this.selectChoseCardInfos[i]['input_investment']
                if (invest_amount < 0) {

                  this.$message("投资额不能为负数 Investment can't be negative!")

                  return
                }
                input_investment[card_id]=this.selectChoseCardInfos[i]['input_investment']
              }
              
              total_investment = total_investment +  parseInt(this.selectChoseCardInfos[i]['input_investment'])
              cards_chosen.push(card_id)

            }
        }

        if (total_investment > cash_reserve) {

            this.$message("投资额不能超过现金储备 Investment can't exceed your cash reserve!")

            return 
        };
        confirmTeamCards(this.myTeamInfo._id.$id,this.selectedCurrentRound,cards_chosen,input_investment).then((res)=>{
          this.doGetTeamCards()
          this.showChoseCardDialog= false
        })
      },
      showSocialTipClick(){
          if(this.showSocialTip == true){
             this.showSocialTip = false;
             this.showSocialTipTitle = '展开 Unfold';
          }else{
             this.showSocialTip = true;
             this.showSocialTipTitle = '收起 Fold';
          }
        }
    }
  }
</script>
<style scoped>
  .card_img{
    border-radius:20px;
  }
  .game {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
  .check-box-item{
  /* word-wrap:break-word;
word-break:break-all;  */
word-break:normal;
white-space: pre-wrap;
margin-bottom: 10px;
}
</style>
