<template>
  <div class="l-c">
    <img :src="require('../../public/back.png')" style="width:100%;height:100%;display:position">
    <img :src="require('../../public/logo.png')" style="position:absolute;left:5vw;top:3vh;width:12vw">
    <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-position="right" label-width="90px"
      class="demo-ruleForm login-container" status-icon >
      <div style="text-align:center;margin-bottom:30px">
      <img :src="require('../../public/txt.png')" style="width:80%;"></div>
      <el-form-item prop="user_email" label="账号:">
        <el-input v-model="ruleForm2.user_email" type="text" auto-complete="off" placeholder="账号 Account"/>
      </el-form-item>
      <el-form-item prop="user_pwd" label="密码:">
        <el-input v-model="ruleForm2.user_pwd" auto-complete="off" placeholder="密码 Password" type="password"/>
      </el-form-item>
      <el-form-item prop="user_type" label="类型:">
        <el-select v-model="ruleForm2.user_type">
          <el-option value="student" label="学生 Student"></el-option>
          <!-- <el-option value="adviser" label="老师 Adviser"></el-option>
          <el-option value="alumni" label="校友 Alumni"></el-option> -->
          <el-option value="educator" label="评委/嘉宾 Judge/Guest"></el-option>
          <el-option value="employee" label="助教"></el-option>
          <!-- <el-option value="admin" label="管理员 Admin"></el-option>
          <el-option value="game_admin" label="游戏管理员 Game Admin"></el-option> -->
        </el-select>
      </el-form-item>
      <!-- <el-form-item prop="employee_type" label="登录类型:">
        <el-select v-model="ruleForm2.reviewer_type">
          <el-option value="reviewer" label="reviewer"></el-option>
          <el-option value="publisher" label="publisher"></el-option>
        </el-select>
      </el-form-item> -->
      <span v-if="!!error_text" style="color: red" class="error_text">{{ error_text }}</span>
      <el-form-item style="width:100%;">
        <el-button :loading="logining" type="primary" style="width:100%;" @click="submit">登录 Login</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {setUserId,setUserName,setUserType} from '../utils/store'
import {loginByStudent,loginByEducator,loginByContact,loginByAlumni,loginByEmployee} from '../api/keneng'
export default {
  name: 'Login',
  data () {
    return {
      error_text: '',
      logining: false,
      ruleForm2: {
        user_email: '',
        user_pwd: '',
        user_type:''
      },
      rules2: {
        user_email: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        user_pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        user_type:[
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submit () {
      this.logining = false
      if(this.ruleForm2.user_type==='student'){
        loginByStudent(this.ruleForm2.user_email,this.ruleForm2.user_pwd).then(res=>{
          console.log(res)
          if(res.data.code==0){
            let result = res.data.data
            setUserId(result._id.$id)
            setUserType(this.ruleForm2.user_type)
            setUserName(result.student_lastName+result.student_givenName)
            // this.$router.push('/student/lecturelist')
            this.$router.push('/student/zhibo')
            this.$message({
              message:'登录成功',
              type:'success'
            })
          }else{
            this.error_text=res.data.msg
          }
        })

      }else if(this.ruleForm2.user_type==='alumni'){
        loginByAlumni(this.ruleForm2.user_email,this.ruleForm2.user_pwd).then(res=>{
          if(res.data.code==0){
            let result = res.data.data
            setUserId(result._id.$id)
            setUserType(this.ruleForm2.user_type)
            setUserName(result.student_lastName+result.student_givenName)
            this.$router.push('/student/zhibo')
            this.$message({
              message:'登录成功',
              type:'success'
            })
          }else{
            this.error_text=res.data.msg
          }
        })
      }else if(this.ruleForm2.user_type==='adviser'){
        loginByContact(this.ruleForm2.user_email,this.ruleForm2.user_pwd).then(res=>{
          if(res.data.code==0){
            let result = res.data.data
            setUserId(result._id.$id)
            setUserType(this.ruleForm2.user_type)
            setUserName(result.contact_lastName+result.contact_givenName)
            this.$router.push('/student/zhibo')
            this.$message({
              message:'登录成功',
              type:'success'
            })
          }else{
            this.error_text=res.data.msg
          }
        })
      }else if(this.ruleForm2.user_type==='educator'){
        loginByEducator(this.ruleForm2.user_email,this.ruleForm2.user_pwd).then(res=>{
          if(res.data.code==0){
            let result = res.data.data
            setUserId(result._id.$id)
            setUserType(this.ruleForm2.user_type)
            setUserName(result.educator_last_name+result.educator_first_name)
            this.$router.push('/educator/lecturelist')
            this.$message({
              message:'登录成功',
              type:'success'
            })
          }else{
            this.error_text=res.data.msg
          }
        })
      }else if(this.ruleForm2.user_type==='game_admin'){
        setUserId("admin")
        setUserType(this.ruleForm2.user_type)
        setUserName("admin")
        this.$router.push('/game/')
        this.$message({
          message:'登录成功',
          type:'success'
        })
      }else if(this.ruleForm2.user_type==='employee'){
          loginByEmployee(this.ruleForm2.user_email,this.ruleForm2.user_pwd).then(res=>{
              if(res.data.code==0){
                let result = res.data.data
                setUserId(result._id.$id)
                setUserType(this.ruleForm2.user_type)
                setUserName(result.user_lastName+result.user_givenName)
                this.$router.push('/employee/lecturelist')
                this.$message({
                    message:'登录成功',
                    type:'success'
                })
            }else{
                this.error_text=res.data.msg
            }
          })
      }else{
        if(this.ruleForm2.user_email === 'admin' && this.ruleForm2.user_pwd === 'yiquanTodo'){
          setUserId('admin')
          setUserType(this.ruleForm2.user_type)
          setUserName('admin')
          this.$router.push('/admin/roomManage')
        }else{
          this.error_text='密码不正确'
        }
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .l-c{
    width: 100%;
    height: 100vh;
    position: relative;
    // background: url('http://guomu-content.reachable-edu.com/%E8%83%8C%E6%99%AF%E7%94%B5%E8%84%91%403x.png');
    background-position: 0 0;
    .login-container {
      /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
      -webkit-border-radius: 5px;
      border-radius: 5px;
      -moz-border-radius: 5px;
      background-clip: padding-box;
      position: absolute;
      top:43%;
      left:50%;
      transform: translate(-50%,-50%);
      width: 350px;
      padding: 35px 35px 15px 35px;
      background: #fff;
      // border: 1px solid #eaeaea;
      // box-shadow: 0 0 25px #cac6c6;
      .title {
        margin: 0px auto 10px auto;
        text-align: center;
        color: #505458;
      }
      .remember {
        margin: 0px 0px 35px 0px;
      }
      .el-radio {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
  .error_text{
    color: red;
    // margin-bottom: 10px;
  }
</style>
