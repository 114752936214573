<template>
    <div style="width:100%">
     
        <div style="padding:20px">

        <h4 style="float:left">社交提示 Social Tips</h4>

        <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
        <br>
        <br>

        <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
            <p>如果你对某条别人发布的信息感兴趣，则可以向他这条信息提出交换申请。</p>
            <p>在信息提供者收到你的交换申请后，他可以前往查看你自己提供的信息提示列表，来判断你提供的信息中是否也有他感兴趣的部分。</p>
            <p>如果他顺利选择了其中一条你提供的信息，那么你们就可以实现信息交换，看到对方提供的具体“信息”部分。</p>


            <p>If you are interested in information posted by others, you can request information exchange with them</p>
            <p>After one receives your exchange request, he/she can view the list of your own posted information and see if any hint will interest him/her for an exchange</p>
            <p>If he/she pick up one of your posted information to view, then your information exchange will be successful and you can view each other's information.</p>
        </div>
            <p>我申请的 My Requests</p>
            <el-row>
              <el-col v-for = "data in request_list" :key="data" >
                <div class="grid-content">
                  <div style="padding: 30px">
                    <span style="color:#1C6AC5">{{data.post.title}}</span>

                    <div class="bottom clearfix">
                        <div  style="font-size:10px">
                        <!-- {{data.student_name?(data.student_name + data.account_name) :"" }}  -->
                        {{data.creater.student_lastName+data.creater.student_givenName}}
                        <!-- {{ (parseInt(data.create_time) * 1000) | timeFormat }} -->
                        </div>
                    </div>
                    <div class="tworightM">
                        <el-button type="primary" @click="view(data.post_id)" v-if="data.status!='pending'" :disabled="data.status=='pending'" style="width:120px" size="small" >查看 View</el-button>
                        <el-button type="primary" @click="view(data.post_id)" v-if="data.status=='pending'" :disabled="data.status=='pending'" style="width:120px" size="small" >待交换 Pending</el-button>
                    </div>
                    <div class="rightM">
                        <el-button
                        @click="viewMessage(data._id.$id,data.creater_id,data.post_id)"
                        size="small"
                        type="warning"
                        >消息 Messages</el-button
                    >
                    </div>

                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- <el-table :data="request_list">
                <el-table-column prop="post.title" label="标题"></el-table-column>
                <el-table-column label="发布者">
                    <template slot-scope="scope">
                        {{scope.row.creater.student_lastName+scope.row.creater.student_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        {{scope.row.status=='pending'?'申请中':'已交换'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="view(scope.row.post_id)" :disabled="scope.row.status=='pending'" size="small">查看</el-button>
                        <el-button type="warning" @click="viewMessage(scope.row._id.$id,scope.row.creater_id,scope.row.post_id)" size="small">消息</el-button>
                    </template>
                </el-table-column>
            </el-table> -->
        </div>
        <el-dialog :visible.sync="showAnswer" title="信息 Information">
            <div>
                {{answer}}
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showMessage" title="消息列表 Message List">
            <el-button @click="initMessage" size="small" type="primary">发送消息 Send a message</el-button>
            <div style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:14px;" v-if="messageList.length==0">{{'暂无消息 No Messages'}}</div>
            <el-row>
                <el-col v-for = "data in messageList" :key="data"  >
                    <div class="content">
                    <!-- <div style="padding: 15px"> -->
                      <div v-if="data.sender._id.$id != user_id" style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                      <div v-if="data.sender._id.$id == user_id" style="text-align:right;color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                      <div v-if="data.sender._id.$id != user_id"style="color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">{{data.sender.student_lastName+data.sender.student_givenName + ', ' + data.sender_account_name}} {{(parseInt(data.create_time) * 1000) | timeFormat}} </div>
                      <div v-if="data.sender._id.$id == user_id" style="text-align:right;color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">我 {{(parseInt(data.create_time) * 1000) | timeFormat}} </div>
                    </div>
                <!-- </div> -->
                </el-col>
            </el-row>
<!-- 
            <el-table :data="messageList">
                <el-table-column prop="content" label="内容"></el-table-column>
                <el-table-column label="发送人">
                    <template slot-scope="scope">
                        {{scope.row.sender.student_lastName+scope.row.sender.student_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="接收人">
                    <template slot-scope="scope">
                        {{scope.row.receiver.student_lastName+scope.row.receiver.student_givenName}}
                    </template>
                </el-table-column>
            </el-table> -->
        </el-dialog>
        <el-dialog :visible.sync="showinput" title="发送消息 Send a message">
            <div>
                <el-input v-model="message" style="width:80%"></el-input>
                <el-button @click="sendPostRequestMessage" type="primary" >发送 Send</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getMyRequests,getPost,getPostMessages,sendPostRequestMessage} from '../../api/yousha'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            user_id:'',
            request_list:[],
            showAnswer:false,
            showMessage:false,
            showinput:false,
            answer:'',
            messageList:[],
            request_id:'',
            message:'',
            creater_id:'',
            post_id:'',
            showSocialTip:true,
            showSocialTipTitle:'收起 Fold'

        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            let user_id = getUserId()
            this.user_id = user_id
            getMyRequests(user_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.request_list=res.data.data
                }
            })
        },
        view(id){
            this.showAnswer=true
             getPost(id).then(res=>{
                if(res.data.code===0){
                    this.answer = res.data.data.answer
                }
            })
        },
        viewMessage(id,creater_id,post_id){
            this.showMessage=true
            this.request_id = id
            this.post_id=post_id
            this.creater_id = creater_id
            getPostMessages({request_id:this.request_id}).then(res=>{
                if(res.code===0){
                    this.messageList = res.data
                }
            })
        },
        initMessage(){
            this.showinput=true
            this.message=''
        },
        sendPostRequestMessage(){
            let user_id=getUserId()
            sendPostRequestMessage(user_id,this.post_id,this.request_id,this.creater_id,this.message).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'发送成功',
                        type:'success'
                    })
                    this.showinput=false;
                    getPostMessages({request_id:this.request_id}).then(res=>{
                        if(res.code===0){
                            this.messageList = res.data
                        }
                    })
                }
            })
        },
        showSocialTipClick(){
            if(this.showSocialTip == true){
                this.showSocialTip = false;
                this.showSocialTipTitle = '展开 Unfold';
            }else{
                this.showSocialTip = true;
                this.showSocialTipTitle = '收起 Fold';
            }
        }
    }
}
</script>

<style>
.grid-content {
  height: 101px;
  background: #EFF3FB;
  opacity: 1;
  border-radius: 20px;
  margin-top:10px;
  width: 100%;
}
.title-content{
    margin-top:10px;
}
.bottom {
    margin-top: 13px;
    line-height: 12px;
    width: 60%;
}
.rightM{
  margin-right:25px;
  margin-top: -35px;
  margin-left: 90%;
  margin-bottom: 0px;
  height: 40px;
}

.tworightM{
  margin-right:25px;
  margin-top: -35px;
  margin-left: 75%;
  margin-bottom: 0px;
  height: 35px;
  width: 120px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #4D4D4D;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.content {
  background: #EFF3FB;
  opacity: 1;
  border-radius: 20px;
  margin-top:10px;
  overflow: hidden;
  padding:15px;
}
</style>
