import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import zhibo from '../views/zhibo.vue'
import gameHome from '../views/game/GameHome.vue'
import cardManage from '../views/game/CardManage.vue'
import carDetail from '../views/game/CardDetail.vue'
import cardEffect from '../views/game/CardEffect.vue'
import envCardManage from '../views/game/EnvCardManage.vue'
import ecoCardDetail from '../views/game/EcoCardDetail.vue'
import presentation from '../views/presentation.vue'
import roomManage from '../views/roomManage.vue'
import mypost from '../views/yousha/mypost.vue'
import friendpost from '../views/yousha/friendpost.vue'
import posts from '../views/yousha/posts.vue'
import friendpostlist from '../views/yousha/friendpostlist.vue'
import myrequests from '../views/yousha/myrequests.vue'
import myreceiverequests from '../views/yousha/myreceiverequests.vue'
import postinfo from '../views/yousha/postinfo.vue'
import gameDetail from '../views/game/GameDetail.vue'
import teamInfo from '../views/game/TeamInfo.vue'
import valueCreaters from '../views/student/ValueCreaters.vue'
import myTeams from '../views/student/MyTeams.vue'
import message from '../views/yousha/message.vue'
import exam from '../views/exam.vue'
import operateManage from '../views/student/OperateManage.vue'
import roomAdmin from '../views/roomAdmin.vue'
import comming1 from '../views/comming/comming1.vue'
import comming2 from '../views/comming/comming2.vue'
import comming3 from '../views/comming/comming3.vue'
import presentationList from '../views/presentation/presentationList.vue'
import meeting from '../views/presentation/meeting.vue'
import discussing from '../views/presentation/discussing'
import audioRoom from '../views/game/AudioRoom'
import presentationadmin from '../views/presentationadmin'
import lectureList from '../views/student/LectureList'
import lecture from '../views/student/Lecture'

import { getUserId } from '../utils/store'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: { name: 'Login' }
    },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path:'/student/zhibo',
    name:'zhibo',
    component:zhibo,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game',
    name:'gameHome',
    component:gameHome,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/detail/:id',
    name:'gameDetail',
    component:gameDetail,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/card',
    name:'cardManage',
    component:cardManage,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/ecocard',
    name:'envCardManage',
    component:envCardManage,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/ecocard/detail/:id',
    name:'ecoCardDetail',
    component:ecoCardDetail,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/card/effect/:id',
    name:'cardEffect',
    component:cardEffect,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/carddetail/:id',
    name:'carDetail',
    component:carDetail,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/team/:id',
    name:'teamInfo',
    component:teamInfo,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/game/audioroom',
    name:'audioRoom',
    component:audioRoom,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/presentation',
    name:'presentation',
    component:presentation,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/presentationlist',
    name:'presentationList',
    component:presentationList,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/mypost',
    name:'mypost',
    component:mypost,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/commingz',
    name:'commingz',
    component:comming1,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/commingx',
    name:'commingx',
    component:comming2,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/commingc',
    name:'commingc',
    component:comming3,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/postinfo',
    name:'postinfo',
    component:postinfo,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/discussing',
    name:'discussing',
    component:discussing,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/friendpost',
    name:'friendpost',
    component:friendpost,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/friendpostlist',
    name:'friendpostlist',
    component:friendpostlist,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/team/manage',
    name:'valueCreaters',
    component:valueCreaters,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/operate/manage',
    name:'operateManage',
    component:operateManage,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/posts',
    name:'posts',
    component:posts,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/myrequests',
    name:'myrequests',
    component:myrequests,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/myreceiverequests',
    name:'myreceiverequests',
    component:myreceiverequests,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/message',
    name:'message',
    component:message,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/exam',
    name:'exam',
    component:exam,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/meeting',
    name:'meeting',
    component:meeting,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/team/manage/myteams/:id',
    name:'myTeams',
    component:myTeams,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/lecturelist',
    name:'lectureList',
    component:lectureList,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/student/lecturescreen',
    name:'lecture',
    component:lecture,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/educator/lecturelist',
    name:'educatorlecturelist',
    component:()=>import('../views/educator/lecturelist'),
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/educator/lecturescreen',
    name:'educatorlecture',
    component:()=>import('../views/educator/lecturescreen'),
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/employee/lecturelist',
    name:'employeelecturelist',
    component:()=>import('../views/employee/lecturelist'),
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/employee/lecturescreen',
    name:'employeelecture',
    component:()=>import('../views/employee/lecturescreen'),
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/educator/paint',
    name:'Paint',
    component: () => import('../views/Paint'),
    meta:{
        requiresAuth:true
    }
},
  {
    path:'/admin/roomManage',
    name:'roomManage',
    component:roomManage,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/admin/presentationadmin',
    name:'presentationadmin',
    component:presentationadmin,
    meta:{
      requiresAuth:true
    }
  },
  {
    path:'/admin/roomAdmin',
    name:'roomAdmin',
    component:roomAdmin,
    meta:{
      requiresAuth:true
    }
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    var userId = getUserId()
    if ( !userId) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})
export default router
