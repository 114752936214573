<template>
  <article class="squareArticle">
         <div style="width:100%">
    
         </div>

        <h4 style="float:left">社交提示 Social Tips</h4>
        <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
        <br>
        <br>
        <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
        <p>如果你成功和别人实现了信息交换并看到了TA的某条信息，则可以进一步与TA在这条信息下进行私信（即消息）交流</p>
        <p>所有别人发给你的消息列表会在消息页面显示，你也可以在某一条交换的信息下面看到双方的消息往来</p>
        <p>从隐私保护的角度出发，系统不提供任何人的联系方式信息。但若双方愿意，你们可在消息往来种交换联系方式</p>


        <p>If you successfully exchanged information with others and see a certain information post, you can further exchange private messages under that information post</p>
        <p>All messages (under different posts) sent to you will be consolidated on the Message page. You can also view the message thread under a specific information post.</p>
        <p>For privacy protection, the system does not disclose any contact information. But based on mutual consent, you can exchange contact information via messages</p>
        </div>
        <el-row>
        <el-col v-for = "data in messageList" :key="data"  >
          <div class="content" @click="getPostMessages(data)" >
            <!-- <div style="padding: 15px"> -->
            <div style="display: flex;flex-direction: row;">
            <div style="width:90%">
              <div style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:10px;">{{data.post_title}}</div>
              <div style="color:#61657E;padding: 0px 5px 10px 15px;font-size:14px;">{{data.content}}</div>
              <div style="color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">{{data.user_name + ', ' +data.account_name}} {{(parseInt(data.create_time) * 1000) | timeFormat}}</div>
            </div>

              <div style="margin-top:15px">
                    <el-button
                    @click="viewMessage(data.request_id,data.post_id,data.user_id)"
                    size="small"
                    type="warning"
                    >查看回复 View/Reply</el-button>
                </div>
            </div>
              <!-- <div style="color:#61657E;padding: 0px 5px 10px 15px;font-size:14px;"></div> -->
            </div>
          <!-- </div> -->
        </el-col>
        </el-row>


        <!-- <el-table :data="messageList">
          <el-table-column prop="post_title" label="标题"></el-table-column>
          <el-table-column prop="user_name" label="发送人"></el-table-column>
          <el-table-column prop="content" label="消息内容"></el-table-column>
          <el-table-column prop="create_time" label="发送时间">
            <template slot-scope="scope">
              <div>
                {{ (parseInt(scope.row.create_time) * 1000) | timeFormat }}
              </div>
            </template>
          </el-table-column>
          
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="getPostMessages(scope.row)"
                size="small"
                type="primary"
              
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table> -->


    <el-dialog :visible.sync="showMessage" title="消息列表 Message List">
        <el-button @click="initMessage" size="small" type="primary">发送消息 Send a message</el-button>
        <div style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:14px;" v-if="messageList.length==0">{{'暂无消息 No Messages'}}</div>
        <el-row>
            <el-col v-for = "data in postMessageList" :key="data"  >
                <div class="content">
                <!-- <div style="padding: 15px"> -->
                  <div v-if="data.sender._id.$id != user_id" style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                  <div v-if="data.sender._id.$id == user_id" style="text-align:right;color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                  <div v-if="data.sender._id.$id != user_id"style="color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">{{data.sender.student_lastName+data.sender.student_givenName + ', ' + data.sender_account_name}} {{(parseInt(data.create_time) * 1000) | timeFormat}} </div>
                  <div v-if="data.sender._id.$id == user_id" style="text-align:right;color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">我 {{(parseInt(data.create_time) * 1000) | timeFormat}} </div>
                </div>
                
            <!-- </div> -->
            </el-col>
        </el-row>

    </el-dialog>
    <el-dialog :visible.sync="showinput" title="发送消息 Send a message">
        <div>
            <el-input v-model="message" style="width:80%"></el-input>
            <el-button @click="sendPostRequestMessage" type="primary" >发送 Send</el-button>
        </div>
    </el-dialog>


    <!-- <el-dialog :visible.sync="isShow" title="查看 View">
            <div style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:14px;" v-if="messageCheckList.length==0">{{'暂无消息 No Messsages'}}</div>
            <el-row>
                <el-col v-for = "data in messageCheckList" :key="data"  >
                    <div class="content">
                   
                    <div style="color:#1C6AC5;padding: 10px 5px 10px 15px;font-size:10px;">{{data.post_title}}</div>
                    <div style="color:#61657E;padding: 15px 5px 10px 15px;font-size:12px;">{{data.content}}</div>
                    <div style="color:#9698A2;padding: 0px 5px 10px 15px;font-size:10px;">{{data.user_name}} {{(parseInt(data.create_time) * 1000) | timeFormat}}</div>
                    </div>
                
                </el-col>
            </el-row>



    </el-dialog> -->
  </article>
</template>

<script>
import {
  getMessagesToMe,getPostMessages,sendPostRequestMessage
} from "../../api/yousha";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      user_id:'',
      postList: [],
      activeName: "first",
      isShow: false,
      showMessage:false,
      showinput:false,
      request_id:'',
      message:'',
      receiver_id:'',
      post_id:'',
      squareForm: {
        squareType: "",
      },
      messageList: [],
      postMessageList:[],
      messageCheckList: [],
      answer: "",
      time: 100000000000000,
      showSocialTip:true,
      showSocialTipTitle:'收起 Fold'
    };
  },
  mounted() {
    this.fetchData();
  
  },
  methods: {
   
    fetchData() {
      let user_id = getUserId();
      this.user_id = user_id;
      getMessagesToMe({user_id:user_id}).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.messageList = res.data;
        }
      });
    },
    viewMessage(request_id,post_id,user_id){
        this.showMessage=true
        this.request_id = request_id
        this.post_id = post_id
        this.receiver_id = user_id
        
        getPostMessages({request_id:this.request_id}).then(res=>{
            if(res.code===0){
                this.postMessageList = res.data

            }
        })
    },
    sendPostRequestMessage(){
        let user_id=getUserId()
        sendPostRequestMessage(user_id,this.post_id,this.request_id,this.receiver_id,this.message).then(res=>{
            if(res.data.code===0){
                this.$message({
                    message:'发送成功',
                    type:'success'
                })
                this.showinput=false;
                getPostMessages({request_id:this.request_id}).then(res=>{
                    if(res.code===0){
                        this.postMessageList = res.data
                    }
                })
            }
        })
    },
    initMessage(){
        this.showinput=true
        this.message=''
    },
    
    getPostMessages(row) {
      let user_id = getUserId();
      
      getPostMessages({request_id:row.request_id}).then((res) => {
        console.log(res);
        if (res.code === 0) {
           
         this.messageCheckList = res.data;
          this.isShow=true;
        }
      });
    },
    showSocialTipClick(){
        if(this.showSocialTip == true){
            this.showSocialTip = false;
            this.showSocialTipTitle = '展开 Unfold';
        }else{
            this.showSocialTip = true;
            this.showSocialTipTitle = '收起 Fold';
        }
    }

  },
};
</script>

<style scoped lang="scss">
.squareArticle {
  padding: 20px;
  width: 100%;
  position: relative;
}
/deep/ .el-form.el-form--inline {
  background-color: #f8f8f8;
  margin-bottom: 10px;
  padding: 8px;
  overflow: hidden;
}
/deep/ .el-form.el-form--inline .el-form-item.el-form-item--medium {
  margin-right: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.searchHandle {
  margin-left: 30px;
}
.rightM{
  margin-right:25px;
  margin-top: -40px;
  margin-left: 90%;
  margin-bottom: 0px;
  height: 40px;
}
.content {
  // height: 100px;
  // min-height:100px;
  background: #EFF3FB;
  opacity: 1;
  border-radius: 20px;
  margin-top:10px;
  overflow: hidden;
  padding:5px 15px;

  // width: 100%;
}
// .content{
//       overflow: hidden;
//       margin:auto /* 看自己需要设置，不影响div高度变化*/
// }
</style>
