<template>
    <div style="width:100%">
    
     
    
        <div style="padding:20px">

            <h4 style="float:left">社交提示 Social Tips</h4>
            <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
            <br>
            <br>
            <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
                <p>首先，你需要为自己建立有价值的信息。每一条信息由“提示”和“信息”两部分组成。在“提示”信息中你可告诉别人这条信息是关于什么主题和内容，以方便他人判断是否对你提供的信息感兴趣；在“信息”部分中，你则需要把具体的有价值的信息在里面提供。</p>
                <p>在实现交换之前，其他人只能在广场看到你发布信息的提示部分（你也只能看到其他人发布信息的提示部分）。</p>


                <p>First, you need to build useful information of your own. Every information post has a "hint" and the "information" itself. In the "hint", you can tell others what your information is about, which will help them judge its value and interesting level; in the "information"part, you then need to provide specific useful information.</p>
                <p>Before successful exchanges, others can only see the hints of your posted information on the square page (and you can only see others' hints either)</p>
            </div>
            <br>


            <el-button type="primary" @click="add">添加信息 Add a Post</el-button>
            <div class="titleP">我的信息 My Posts <p class="totalSpan"> 交换总数 Total Exchanges: <span class="colorGreen">{{totalAccount}}</span> </p></div>

            <el-row>
                <el-col v-for = "data in postLists" :key="data" >
                <div class="grid-content">
                    <div style="padding: 30px">
                    <span style="color:#1C6AC5">{{data.title}}</span>

                    <div class="bottom clearfix">
                        <div  style="font-size:10px">
                        <!-- {{data.title}}  -->
                        {{ (parseInt(data.create_time) * 1000) | timeFormat }}
                        </div>
                    </div>
                    
                    <div class="rightM">
                       
                    <el-button
                        @click="update(data._id.$id)"
                        size="small"
                        type="primary"
                        style="width: 80px"
                        >修改 Edit</el-button
                    >
                    </div>
                    


                    </div>
                </div>
                </el-col>
            </el-row>

            <!-- <el-table :data="postLists"  border>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="title" label="发送时间">
                 <template slot-scope="scope">
                    <div>
                        {{ (parseInt(scope.row.create_time) * 1000) | timeFormat }}
                    </div>
                    </template>
                </el-table-column>
                  <el-table-column prop="request_count" label="Exchanges" align="center">
          </el-table-column>
                <el-table-column prop="answer" label="答案" ></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="update(scope.row._id.$id)" type="primary" size="small">修改</el-button>
                    </template>
                </el-table-column>
            </el-table> -->
        </div>
    </div>
</template>

<script>
import {getMyPosts} from '../../api/yousha'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            postLists:[],
            totalAccount:0,
            showSocialTip:true,
            showSocialTipTitle:'收起 Fold'
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            let user_id = getUserId()
            getMyPosts(user_id).then(res=>{
                if(res.data.code===0){
                    this.postLists = res.data.data;
                    this.postLists.forEach(f=>{
                        if(f.request_count){
                            this.totalAccount+=f.request_count;   
                        }
                         
                        
                    })
                }
            })
        },
        add(){
            this.$router.push({
                path:'/student/postinfo',
                query:{
                    id:''
                }
            })
        },
        update(id){
            this.$router.push({
                path:'/student/postinfo',
                query:{
                    id:id
                }
            })
        },
        showSocialTipClick(){
            if(this.showSocialTip == true){
                this.showSocialTip = false;
                this.showSocialTipTitle = '展开 Unfold';
            }else{
                this.showSocialTip = true;
                this.showSocialTipTitle = '收起 Fold';
            }
        }
    }
}
</script>

<style scoped lang="scss">
.titleP{
 color:#606266;
 position: relative;
 margin-bottom: 20px;
 margin-top: 20px;
 .totalSpan{
    position: absolute;
    right: 10px;
    top: 0;
    margin: 0;
    padding: 0;
}

 }
 
.colorGreen{
color: #67C23A;
font-weight: bold;
}

</style>
