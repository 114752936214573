import Cookies from 'js-cookie'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const USER_ID_YAODIAN_REVUEW = 'user_id'
const USER_NAM_YAODIAN_REVUEWE = 'user_name'
const USER_TYPE_YAODIAN_REVUEW = 'user_type'

export function setUserId (user_id) {
  Cookies.set(USER_ID_YAODIAN_REVUEW, user_id)
}
export function getUserId () {
  return Cookies.get(USER_ID_YAODIAN_REVUEW)
}

export function setUserType(type){
  Cookies.set(USER_TYPE_YAODIAN_REVUEW, type)

}

export function getUserType(){
   return Cookies.get(USER_TYPE_YAODIAN_REVUEW)

}
export function removeUserId () {
  Cookies.remove(USER_ID_YAODIAN_REVUEW)
}
export function setUserName (user_name) {
  Cookies.set(USER_NAM_YAODIAN_REVUEWE, user_name)
}
export function getUserName () {
  return Cookies.get(USER_NAM_YAODIAN_REVUEWE)
}
export function removeUserName () {
  Cookies.remove(USER_NAM_YAODIAN_REVUEWE)
}


export default new Vuex.Store({
  state:{
    type:''
  },
  mutations:{
    setType(state,data){
      state.type=data
    }
  }
})
