<template>
    <div style="width:100%;color:#606266">
        <div id="student">
            <!-- <el-button type="warning" @click="leaveRoom">离开房间</el-button> -->
            <p>当前房间内有：</p>
            <span v-for="(item,index) in users" :key="index" style="margin-left:10px">{{item.student_lastName+item.student_givenName}}</span>
            
        </div>
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import { createAudioRoomToken, listAudioRoomUsers } from '../../api/keneng'
import {getUserId} from '../../utils/store'
import {formatDate} from '../../utils/date'
const myRoom = new QNRTC.TrackModeSession()

console.log('current version is', QNRTC.version)
export default {
  data () {
    return {
      audioDevice: '',
      videoDevice: '',
      audioDevices: [],
      videoDevices: [],
      roomToken: '',
      userList: [],
      currentUser: '',
      lastUser: '',
      showInfo: false,
      showToken: false,
      room: '',
      userId: '',
      userToken: '',
      room_list: [],
      type: '',
      currentRoom: '',
      message:'',
      toUserId:'',
      showChat:false,
      chatMessage:[],
      allMessages:{},
      newMessages:[],
      isInRoom:false,
      room_id:'',
      time_slot:[],
      publicMessages:[],
      showPublic:[],
      roomId:'',
      screenTrackId:'',
      screenTrack:'',
      remoteTrackId:'',
      tracks:[],
      application_id:'',
      users:[]
    }
  },
  mounted () {
    this.roomId = this.$route.query.roomId
    this.application_id = this.$route.query.application_id
    // this.getPresentationInfo()
    window.addEventListener( 'beforeunload', e => this.leaveRoom() );
    this.joinRoom()
  },
  destroyed(){
      myRoom.leaveRoom();
      for (const track of this.tracks) {
        track.release();
      }
  },
  methods: {
    addDeviceToSelect (deviceInfo) {
      let audioDevices = []
      let videoDevices = []
      deviceInfo.forEach(info => {
        if (info.kind === 'audioinput') {
          audioDevices.push(info)
        } else if (info.kind === 'videoinput') {
          videoDevices.push(info)
        }
      })
      this.audioDevices = audioDevices
      this.videoDevices = videoDevices
    },
    async joinRoom () {
      // 从输入框中获取 roomToken

      this.showInfo=false

      let roomToken 
      let result = await this.createRoomToken()
      if(result.code==0){
        roomToken=result.data
      }

      try {
        // 加入房间
        await myRoom.joinRoomWithToken(roomToken)

        this.subscribeTracks(myRoom.trackInfoList)
        listAudioRoomUsers(this.roomId).then(res=>{
          this.users=res.data
        })


      } catch (e) {
        console.error(e)
        this.isInRoom = false
        this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
        return
      }


        myRoom.on("track-add", (tracks) => {
            this.subscribeTracks(tracks);  
            listAudioRoomUsers(this.roomId).then(res=>{
              this.users=res.data
            })     
        });



      await this.publish()


    },
 
    
    async publish () {
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          audio: {
            enabled: true,
            deviceId: this.audioDevice
          }
        })
      } catch (e) {
        console.error(e)
        alert(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      }


      try {
        // 发布采集流
        await myRoom.publish(tracks)
        this.tracks =tracks
      } catch (e) {
        console.error(e)
        alert(`发布失败，ErrorCode: ${e.code}`)
      }


    },
    subscribeTracks (trackInfoList) {
      let student = document.getElementById('student')
      myRoom.subscribe(trackInfoList.map(t => t.trackId)).then(tracks => {
          for (let track of tracks) {
            track.play(student)
          }
      })
    },
    createRoomToken () {
      return new Promise((resolve,reject)=>{
        createAudioRoomToken(this.roomId,this.application_id).then(res => {
          resolve(res.data)
        })
      })
      
    },
    listRoomUsers (e) {
      if(e){
        if(this.isInRoom==true){
          if(this.room_id!==''){
              listRoomUsers(this.room_id).then(res => {
                console.log(res)
                this.userList = res.data
              })
          }
        }
      }
    },
    changeView () {
      for (let item of this.userList) {
        if (item.userId == this.currentUser) {
          myRoom.setMergeStreamLayout(this.currentUser, {
            x: 0, y: 0, w: 1920, h: 1080, hidden: false, muted: false
          })
        } else {
           myRoom.setMergeStreamLayout(item.userId, {
            x: 0, y: 0, w: 0, h: 0, hidden: false, muted: false
          })
        }
      }
    },
   
    leaveRoom(){
      myRoom.leaveRoom();
      for (const track of this.tracks) {
        track.release();
      }
      this.$router.back(-1)
    },
    getPresentationInfo(){
      let student_id = getUserId()
      getPresentationInfo(student_id,'presentation').then(res=>{
        if(res.data.code===0){
          this.time_slot = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    color:#606266
}
select {
  width: 300px;
}
.users{
    padding:20px
}

section {
  margin-bottom: 20px;
}

.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}

.smallWindow{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>
