<template>
    <div class="container" >
       <div >
            <h2>即将开启</h2>
            <h3>Coming Soon</h3>
            <p class="txt">请在2021年2月6日回来获取相关信息</p>
            <p class="txt">Please come back for more information on February 6th, 2020</p>
       </div>
    </div>
</template>

<script>

export default {
    data(){
        return{

        }
    },
    mounted(){
     
    },
    methods:{
    }
}
</script>

<style scoped>
.container{
    width:100%;
    color:#606266;
    height:54vh;
    display:flex;
    justify-content:center;
    align-items:center
}
.txt{
    font-size:90%
}
</style>