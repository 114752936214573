<template>
  <div class="game">
    <span >Team信息</span>
    <div style="display: flex;flex-direction: row;margin-top: 25px;align-items: center">
      <h4>内部编码：</h4>
      <el-input v-model="teamInfo.internal_code" placeholder="请输入名称" style="width: 300px"/>
    </div>
    <div class="flex-right" style="margin-top: 40px">
      <el-button type="primary" @click="updateTeamInfo">更新Team信息</el-button>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import {getTeam,updateTeam} from '../../api/VC_Arena'
export default {
  data(){
      return {
        teamInfo:{"internal_code":""},
        teamId:"",
        gameId:""
      }
    },
  mounted(){
    this.gameId =  Cookies.get("gameId")
    var id =  this.$route.params.id
    if(id ==="create"){
      this.teamId = ""
    }else{
      this.teamId = id
    }
    this.fetchData()
  },
  methods: {
    fetchData(){
      if(this.teamId != ""){
        getTeam(this.teamId).then((res) => {
           this.teamInfo = res.data.data
        })
      }
    },
    updateTeamInfo(){
      updateTeam(this.teamId,this.gameId,this.teamInfo.internal_code).then((res)=>{
        this.$message('更新成功')
        this.$router.go(-1)
      })
    }
  }
}
</script>
<style scoped>
  .game {
    width: 100%;
    margin: 20px;
    padding: 0px;
  }
</style>
